import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client';
// import { getTrace } from '@ampeersenergy/ampeers-node-logger';

import { clearSession, getSession, setSession } from './services/session';

export const errorMiddleware = onError(
  ({ graphQLErrors /* , networkError */ }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        switch (extensions?.code) {
          case 'UNAUTHENTICATED':
            clearSession();
            break;

          default:
            console.warn(message, locations, path, extensions);
            break;
        }
      });

    // if (networkError) console.log(`[Network error]: ${networkError}`);
  },
);

export const setHeaderMiddleware = new ApolloLink((operation, forward: any) => {
  const { token, clientId, appKey, userId } = getSession();

  operation.setContext({
    headers: {
      'x-ampeers-app-verify': appKey,
      ...(token && { 'x-ampeers-user-token': token }),
      ...(clientId && { 'x-ampeers-client-id': clientId }),
      ...(userId && { 'x-ampeers-user-id': userId }),
      // 'x-ampeers-trace-id': getTrace(),
    },
  });

  return forward(operation);
});

export const receiveSessionHeaderMiddleware = new ApolloLink(
  (operation, forward: any) => {
    return forward(operation).map((response: any) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;
      if (headers) {
        const session = {};
        let set;

        if (headers.get('x-ampeers-user-token')) {
          set = true;
          (session as any).token = headers.get('x-ampeers-user-token');
        }

        if (headers.get('x-ampeers-client-id')) {
          set = true;
          (session as any).clientId = headers.get('x-ampeers-client-id');
        }

        if (headers.get('x-ampeers-user-id')) {
          set = true;
          (session as any).userId = headers.get('x-ampeers-user-id');
        }

        if (set) {
          setSession(session);
        }
      }

      return response;
    });
  },
);
