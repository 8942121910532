import React from 'react';
import styled from 'styled-components/macro';
import { connect, useField } from 'formik';
import { Icons } from '@ampeersenergy/ampeers-ui-components';

const Dropdown = styled.div`
  width: 100%;
  background: ${(props) => props.theme.palette.background};
  position: relative;

  .dropdown-menu {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: ${(props) => props.theme.palette.background};
    box-shadow: 0px 0px 10px ${(props) => props.theme.palette.borderEmphasis};
    border-radius: 5px;
  }

  .dropdown-input {
    cursor: pointer;
  }
`;

const DropdownItem = styled.div<{ $isActive?: boolean; $isHovered?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  box-sizing: content-box;
  background: ${(props) =>
    props.$isActive ? props.theme.secondaryColor : null};
  font-size: 14px;
  cursor: pointer;

  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }

  &:first-child:last-child {
    border-radius: 5px;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }

  &:hover {
    background: ${(props) => props.theme.palette.backgroundMuted};
  }

  & p {
    margin: 4px 0;
  }

  .dropdown-item-bar {
    position: absolute;
    top: 14px;
    bottom: 14px;
    left: 0;
    width: 4px;
    background-color: ${(props) =>
      props.$isActive
        ? props.theme.primaryColor
        : props.$isHovered
        ? props.theme.palette.borderEmphasis
        : props.theme.palette.border};
  }

  .dropdown-item-title {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .checkmark {
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 8px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 20%;
      width: 7px;
      height: 12px;
      border: solid transparent;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translateY(-50%);
      border-color: ${(props) => props.theme.primaryColor};
    }
  }
`;

export interface ExtendedDropdownProps {
  labels: { title: string; description: string }[];
  values: string[];
  onChange: (e: any) => void;
  name: string;
}

const ExtendedDropdown = connect<ExtendedDropdownProps>(
  ({ labels, values, onChange, name }) => {
    const [field] = useField(name);
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    const [selectedValue, setSelectedValue] = React.useState<string | null>(
      null,
    );
    const [hoveredElement, setHoveredElement] = React.useState('');

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const handleOptionSelect = (value: string) => {
      setSelectedValue(value);
      setIsMenuOpen(false);
      onChange({ target: { value } });
    };

    const indexValue = values.indexOf(selectedValue || '');

    React.useEffect(() => {
      const handleClickOutside = (e: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
          setIsMenuOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    React.useEffect(() => {
      setSelectedValue(field.value);
    }, [field.value]);

    return (
      <Dropdown ref={dropdownRef}>
        <Icons.ChevronDown
          size={25}
          css={`
            position: absolute;
            right: 10px;
            top: 6px;
            pointer-events: none;
          `}
        />
        <input
          type="text"
          id={name}
          data-testid={name}
          name={name}
          className="dropdown-input"
          defaultValue={labels[indexValue]?.title || ''}
          placeholder="Bitte Wählen"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        {isMenuOpen && (
          <div className="dropdown-menu" data-testid="dropdown-menu">
            {values.map((value: string, index: number) => {
              const isActiveValue = value === selectedValue;
              return (
                <DropdownItem
                  key={value}
                  onClick={() => handleOptionSelect(value)}
                  onKeyDown={() => handleOptionSelect(value)}
                  data-testid={`dropdown-item-${value}`}
                  role="presentation"
                  $isActive={isActiveValue}
                  $isHovered={hoveredElement === value}
                  onMouseEnter={() => setHoveredElement(value)}
                  onMouseLeave={() => setHoveredElement('')}
                >
                  <div className="dropdown-item-bar" />
                  <div className="dropdown-item-content">
                    <p
                      className="dropdown-item-title"
                      data-testid="dropdown-item-title"
                    >
                      {labels[index].title}
                    </p>
                    <p
                      className="dropdown-item-description"
                      data-testid="dropdown-item-description"
                    >
                      {labels[index].description}
                    </p>
                  </div>
                  <span>
                    {isActiveValue ? <div className="checkmark" /> : null}
                  </span>
                </DropdownItem>
              );
            })}
          </div>
        )}
      </Dropdown>
    );
  },
);

export default ExtendedDropdown;
