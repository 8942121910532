import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  BaseIcon,
  IconProps,
  PageTitle,
} from '@ampeersenergy/ampeers-ui-components';

import useToParentCallback from '../useToParentCallback';
import { FlexRow, Flex } from '../layout';

interface PageTitleLayoutProps {
  isLoading?: boolean;
  levelsUpToParent?: number;
  title: string;
  actions?: React.ReactNode;
  className?: string;
  children: ReactNode;
}

const Wrapper = styled.div`
  display: flex;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 20px;
`;

const IconWrapper = styled.div`
  background: ${(props) => props.theme.palette.background};
  padding: 4px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  align-self: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.secondaryColor};

    svg {
      fill: ${(props) => props.theme.primaryColor};
    }
  }
`;

const ActionRow = styled(Flex)`
  align-self: center;
`;

const PullRight = styled(FlexRow)`
  justify-content: flex-end;
`;
const Back = (props: IconProps) => (
  <BaseIcon {...props}>
    <path d="M15.1323847,7.29793124 C15.3745615,7.05129493 15.7485861,6.94659862 16.102915,7.02626205 C16.457244,7.10592548 16.7331245,7.35673928 16.8187782,7.6770814 C16.9044319,7.99742352 16.7856561,8.33417651 16.5105752,8.55089841 L16.5105752,8.55089841 L10.492,13.982 L22.1,13.9821429 C22.5970563,13.9821429 23,14.3850866 23,14.8821429 L23,14.9035714 C23,15.4006277 22.5970563,15.8035714 22.1,15.8035714 L10.254,15.803 L16.5105752,21.4491016 C16.7550916,21.6417433 16.8761076,21.9292244 16.839824,22.2157172 L16.8187782,22.3229186 C16.7331245,22.6432607 16.457244,22.8940745 16.102915,22.973738 C15.7485861,23.0534014 15.3745615,22.9487051 15.1323847,22.7020688 L15.1323847,22.7020688 L7.29189973,15.6264777 C7.13336988,15.4857643 7.03256955,15.3005874 7.00604943,15.1013518 C6.97275709,14.8320315 7.0781017,14.5637997 7.29189973,14.3735105 L7.29189973,14.3735105 Z" />
  </BaseIcon>
);
export const PageTitleLayout: React.FC<PageTitleLayoutProps> = ({
  children,
  title,
  isLoading,
  levelsUpToParent,
  actions,
  className,
}) => {
  const theme = useTheme();
  const toParent = useToParentCallback(levelsUpToParent || 1);
  const navigate = useNavigate();

  const handleIconClick = () => {
    if (levelsUpToParent === undefined) {
      navigate(-1); // Go back in history
    } else {
      toParent();
    }
  };

  return (
    <ContentWrapper className={className}>
      <Wrapper>
        <IconWrapper onClick={handleIconClick}>
          <Back size={25} color={theme.palette.warning.color} />
        </IconWrapper>
        <PageTitle>{isLoading ? 'Wird geladen…' : title}</PageTitle>
        <ActionRow>
          <PullRight>{actions}</PullRight>
        </ActionRow>
      </Wrapper>
      {children}
    </ContentWrapper>
  );
};
