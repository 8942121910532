import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Segment,
  Headline as HeadlineComponent,
} from '@ampeersenergy/ampeers-ui-components';

import {
  Bold,
  ProgressRing,
  KpiNumber,
  ComponentLabel,
} from '../../components';

import { HRStyle } from './dashboardStyles';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 3fr 1fr;
  grid-column-gap: 1em;
  grid-auto-rows: minmax(35px, auto);
  justify-items: center;
`;

const Nested = styled.div`
  display: grid;
  grid-auto-rows: minmax(15px, auto);
  align-items: center;
  justify-items: center;
`;

const Subheader = styled(Bold)`
  font-size: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const Headline = styled(HeadlineComponent)`
  text-transform: none !important;
  letter-spacing: unset !important;
`;

// interface DashboardKPIProps {
//   data: {
//     numberOfPlants: number;
//     numberOfTenants: number;
//     sumOfUnits: number;
//     sumOfEmpty: number;
//     sumOfThirdSupplied: number;
//     amountOfUncheckedInvoice: number;
//     amountOfConfirmedInvoice: number;
//     isLoading: boolean;
//     isOdooLoading: boolean;
//   };
// }

function DashboardKPI({ data }: any) {
  const {
    numberOfPlants,
    numberOfTenants,
    sumOfUnits,
    sumOfEmpty,
    sumOfThirdSupplied,
    amountOfUncheckedInvoice,
    amountOfConfirmedInvoice,
    isLoading,
    isOdooLoading,
  } = data;

  const progress1 = parseInt(
    Math.floor((numberOfTenants / sumOfUnits) * 100).toFixed(0),
    10,
  );

  const progress2 = parseInt(
    Math.floor((sumOfThirdSupplied / sumOfUnits) * 100).toFixed(0),
    10,
  );
  const progress3 = parseInt(
    Math.floor((sumOfEmpty / sumOfUnits) * 100).toFixed(0),
    10,
  );

  const progressPaid = parseInt(
    Math.floor(
      (amountOfConfirmedInvoice /
        (amountOfUncheckedInvoice + amountOfConfirmedInvoice)) *
        100,
    ).toFixed(0),
    10,
  );

  return (
    <Section>
      <Segment>
        {/* @ts-expect-error old Header */}
        <Headline title underlined>
          Key Performance Indicators
        </Headline>
        <Subheader>Projekte</Subheader>
        <Wrapper>
          <Nested>
            <KpiNumber value={numberOfPlants} isLoading={isLoading} />
          </Nested>
          <Nested>
            <KpiNumber value={numberOfTenants} isLoading={isLoading} />
          </Nested>
          <Nested>
            <ProgressRing
              isLoading={isLoading}
              radius={30}
              stroke={8}
              progress={[progress1, progress2, progress3]}
              colors={['#1848ff', '#14dce8', '#d8d8d8']}
              legendItems={[
                { legend: 'Teilnehmer', color: '#1848ff' },
                { legend: 'Dritte', color: '#14dce8' },
                { legend: 'Leer', color: '#d8d8d8' },
              ]}
              textColor="#1848ff"
            />
          </Nested>
          <ComponentLabel secondary>Kundenanlagen</ComponentLabel>
          <ComponentLabel secondary>Teilnehmer</ComponentLabel>
          <ComponentLabel secondary>Durchdringungsquote</ComponentLabel>
        </Wrapper>
        <HRStyle />
        <Subheader>Rechnungen</Subheader>
        <Wrapper>
          <Nested>
            <KpiNumber
              value={amountOfUncheckedInvoice}
              isLoading={isOdooLoading}
              color="red"
            />
          </Nested>
          <Nested>
            <KpiNumber
              value={amountOfConfirmedInvoice}
              isLoading={isOdooLoading}
              color="green"
            />
          </Nested>
          <Nested>
            <ProgressRing
              isLoading={isOdooLoading}
              radius={30}
              stroke={8}
              progress={[progressPaid, 100 - progressPaid]}
              colors={['#6ba727', 'white']}
              legendItems={[{ legend: 'Freigegeben', color: '#6ba727' }]}
              textColor="#6ba727"
            />
          </Nested>
          <ComponentLabel secondary>Ungeprüft</ComponentLabel>
          <ComponentLabel secondary>Freigegeben</ComponentLabel>
          <ComponentLabel secondary>Ratio</ComponentLabel>
        </Wrapper>
        {/* <HRStyle />
      <Bold>CO2-EINSPARUNG</Bold>
      <WrapperCO2>
        <Nested>
          <CubeBlue>{95}</CubeBlue>
        </Nested>
        <Nested></Nested>
        <Caption>Tonnen seit Jahresbeginn</Caption>
      </WrapperCO2> */}
      </Segment>
    </Section>
  );
}

export default DashboardKPI;
