import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { withSession } from '../services/session';

function AuthorizedRoute({ hasSession }: any) {
  return hasSession ? (
    <Outlet /> // Render child routes here
  ) : (
    <Navigate to="/login" replace />
  );
}

export default withSession(AuthorizedRoute);
