import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface LegendItemProps {
  color: string;
  className?: string;
  secondary?: boolean;
  small?: boolean;
  children: ReactNode;
}

export const Legend = styled.div`
  display: inline-flex;
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;

  color: #000000;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;

  .square {
    margin-right: 5px;
  }
`;

export const LegendItem: React.FC<LegendItemProps> = ({
  className,
  color,
  secondary,
  small,
  children,
}) => (
  <ItemWrap
    className={`${className || ''}`}
    style={{
      ...(secondary ? { color: '#909090' } : {}),
      ...(small ? { fontSize: 10 } : {}),
    }}
  >
    <div
      className="square"
      style={{ width: 10, height: 10, background: color }}
    />
    {children}
  </ItemWrap>
);
