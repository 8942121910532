import React from 'react';
import {
  library,
  IconLookup,
  IconDefinition,
  findIconDefinition,
  IconName,
} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTheme, ThemeProps } from 'styled-components';
import type { Theme } from '@ampeersenergy/ampeers-ui-components';

library.add(fas as unknown as IconDefinition);

const getFaIconFromString = (iconName: IconName): IconDefinition => {
  const lookup: IconLookup = { prefix: 'fas', iconName };
  const iconDefinition: IconDefinition = findIconDefinition(lookup);
  return iconDefinition;
};

interface ThemedFontAwesomeIconProps {
  icon: string;
}

export const ThemedFontAwesomeIcon = withTheme(
  ({ theme, icon }: ThemeProps<Theme> & ThemedFontAwesomeIconProps) => (
    <FontAwesomeIcon
      icon={getFaIconFromString(icon as IconName)}
      color={theme.primaryColor}
    />
  ),
);

const renderFaIconContainer = (icon: string, color: string) => (
  <FontAwesomeIcon icon={getFaIconFromString(icon as IconName)} color={color} />
);

export default renderFaIconContainer;
