import {
  AlertRetryable,
  CookieBanner,
  modalSetAppElement,
  ToastProvider,
} from '@ampeersenergy/ampeers-ui-components';
import '@ampeersenergy/ampeers-ui-components/src/global-style.css';
import { datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import { Content, Sidebar, Wrapper } from './App.style';
import { NothingFound } from './components';
import AuthorizedRoute from './components/authorizedRoute';
import { DocTitle } from './components/docTitle';
import Navigation from './components/navigation';
import { TaskDetail } from './components/task-manager/TaskDetail';
import { TasksStatus } from './components/task-manager/TasksStatus';
import ThemeProvider from './components/themeProvider';
import { useHasRole } from './components/useHasRole';
import './helpers/yupErrorMsg';
import './queries/fragments';
import { withSession } from './services/session';
// pages
import AccountingPage from './pages/accounting/accounting.page';
import WorkflowDetailOld from './pages/accounting/oldWorkflow/index';
import CrmPage from './pages/crm';
import Dashboard from './pages/dashboard/dashboardPage';
import Login from './pages/login/loginPage';
import ResetPasswordPage from './pages/login/resetPasswordPage';
import { TwoFactorAuthPage } from './pages/login/twoFactorAuth.page';
import PlantDetailPage from './pages/plant';
import PlantsListPage from './pages/project/plantsListPage';
import ProjectListPage from './pages/project/projectsListPage';
import ReportsPage from './pages/report';
import Settings from './pages/settings/settingsPage';
import { SupplierDetails } from './pages/supplier/details';
import SupplierPage from './pages/supplier/index';
import TariffDetailPage from './pages/tariff/tariffDetailPage';
import TariffListPage from './pages/tariff/tariffListPage';
import { AccountingRunDetailsPage } from './pages/accounting/details';
import {
  getOptionalCookiesAccepted,
  LOCAL_STORAGE_IDENTIFIER,
} from './helpers/storageUtils';
import {
  loadMSClarityScript,
  loadSegmentScript,
} from './helpers/analyticsHelpers';
import TaskManagerProvider from './components/task-manager/TaskManagerProvider';
import WorkflowTable from './pages/accounting/workflowTable';

if (process.env.NODE_ENV !== 'test') {
  modalSetAppElement('#root');
}
if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'development') {
  datadogLogs.init({
    clientToken: 'pub2e223cc59bf971d57cf4b9a94581ad9f',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: `ae-ls-${process.env.NODE_ENV}-frontend`,
  });
}

const PageNotLoadedErrorWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageNotLoadedError = ({
  refetch,
}: {
  refetch: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<any>>;
}) => {
  return (
    <PageNotLoadedErrorWrapper>
      <AlertRetryable
        message="Dieser Inhalt konnte nicht geladen werden."
        onRetry={() => refetch()}
      />
    </PageNotLoadedErrorWrapper>
  );
};

const App: React.FC = () => {
  const location = useLocation();

  const { hasRole: crmEnabled } = useHasRole('feature_crm');
  const { hasRole: accountingEnabled } = useHasRole('feature_accounting');
  const {
    hasRole: supplierChangeEnabled,
    error: supplierChangeEnabledError,
    refetch: supplierChangeEnabledRefetch,
  } = useHasRole('feature_supplier_change');

  const optionalCookiesAccepted = getOptionalCookiesAccepted();
  React.useEffect(() => {
    loadMSClarityScript(optionalCookiesAccepted);
    loadSegmentScript(optionalCookiesAccepted);
  }, [optionalCookiesAccepted]);

  return (
    <ThemeProvider>
      <HelmetProvider>
        <ToastProvider>
          <TaskManagerProvider>
            <DocTitle />
            <Wrapper>
              <CookieBanner
                cookiesIdentifier="cookies"
                localStorageIdentifier={LOCAL_STORAGE_IDENTIFIER}
                onClose={() => {
                  loadMSClarityScript(optionalCookiesAccepted);
                  loadSegmentScript(optionalCookiesAccepted);
                }}
              />
              {location.pathname !== '/login' && (
                <Sidebar>
                  <Navigation />
                </Sidebar>
              )}
              <Content>
                <Routes>
                  <Route path="/login" element={<Login />} />

                  <Route path="/login/2fa" element={<TwoFactorAuthPage />} />
                  <Route
                    path="/reset-password"
                    element={<ResetPasswordPage />}
                  />
                  <Route element={<AuthorizedRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/project" element={<ProjectListPage />} />
                    <Route path="/tariff" element={<TariffListPage />} />

                    <Route
                      path="/accounting/workflows/:id/:name"
                      element={<WorkflowDetailOld />}
                    />
                    <Route
                      path="/accounting"
                      element={
                        accountingEnabled ? (
                          <AccountingPage />
                        ) : (
                          <Navigate to="/" replace />
                        )
                      }
                    />
                    <Route
                      path="/accounting/*"
                      element={
                        accountingEnabled ? (
                          <AccountingPage />
                        ) : (
                          <Navigate to="/" replace />
                        )
                      }
                    />
                    <Route path="/reports" element={<ReportsPage />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/settings/*" element={<Settings />} />
                    <Route path="/tasks" element={<TasksStatus />} />
                    <Route path="/tasks/:id" element={<TaskDetail />} />
                    <Route
                      path="/project/:projectId-:projectNameDeprecated"
                      element={<PlantsListPage />}
                    />
                    <Route
                      path="/project/:projectId"
                      element={<PlantsListPage />}
                    />
                    <Route
                      path="/project/:projectId-:projectNameDeprecated/plant/:plantId-:plantNameDeprecated"
                      element={<PlantDetailPage />}
                    />
                    <Route
                      path="/project/:projectId/plant/:plantId-:plantNameDeprecated"
                      element={<PlantDetailPage />}
                    />
                    <Route
                      path="/project/:projectId-:projectNameDeprecated/plant/:plantId"
                      element={<PlantDetailPage />}
                    />
                    <Route
                      path="/project/:projectId/plant/:plantId/*"
                      element={<PlantDetailPage />}
                    />

                    <Route
                      path="/tariff/:tariffId-:tariffNameDeprecated"
                      element={<TariffDetailPage />}
                    />
                    <Route
                      path="/tariff/:tariffId"
                      element={<TariffDetailPage />}
                    />

                    <Route
                      path="/accounting/runs/:id"
                      element={<AccountingRunDetailsPage />}
                    />

                    <Route
                      path="/supplier/workflow/:id"
                      element={
                        supplierChangeEnabled ? (
                          <SupplierDetails />
                        ) : supplierChangeEnabledError ? (
                          <PageNotLoadedError
                            refetch={supplierChangeEnabledRefetch}
                          />
                        ) : (
                          <Navigate to="/" replace />
                        )
                      }
                    />
                    <Route
                      path="/supplier"
                      element={
                        supplierChangeEnabled ? (
                          <SupplierPage />
                        ) : supplierChangeEnabledError ? (
                          <PageNotLoadedError
                            refetch={supplierChangeEnabledRefetch}
                          />
                        ) : (
                          <Navigate to="/" replace />
                        )
                      }
                    />

                    <Route
                      path="/crm"
                      element={
                        crmEnabled ? <CrmPage /> : <Navigate to="/" replace />
                      }
                    />
                  </Route>
                  <Route element={<NothingFound />} />
                </Routes>
              </Content>
            </Wrapper>
          </TaskManagerProvider>
        </ToastProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default withSession(App);
