import React from 'react';
import styled from 'styled-components';
import {
  FormikSubmit,
  FormWrapper as BaseFormWrapper,
  Select,
} from '@ampeersenergy/ampeers-ui-components';
import { Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';

import { ExportType } from './types';

const FormWrapper = styled(BaseFormWrapper)``;

const Submit = styled(FormikSubmit)`
  margin-top: 18px;
  align-self: flex-end;
  width: unset !important;
`;

export type StepState = 'export' | 'processing' | 'done' | 'error';

export interface ExportStepProps {
  types: ExportType[];
  selectedType: ExportType | undefined;
  setSelectedType: (t: ExportType) => void;
  onGenerate: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => Promise<void> | undefined;
  InnerComponent?: React.ElementType;
  formProps: Omit<FormikConfig<FormikValues>, 'onSubmit'>;
}

export const ExportStep: React.FC<ExportStepProps> = ({
  types,
  selectedType,
  setSelectedType,
  onGenerate,
  InnerComponent,
  formProps,
}) => {
  const validationSchema = types.find(
    (t) => t.id === selectedType?.id,
  )?.validationSchema;
  return (
    <Formik
      {...formProps}
      validationSchema={validationSchema}
      onSubmit={onGenerate}
    >
      {() => (
        <>
          <Select
            id="export-type"
            label="Welcher Bericht soll erstellt werden?"
            value={selectedType?.id || ''}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              const target = types.find((t) => t.id === evt.target.value);
              setSelectedType(target!);
            }}
          >
            <option disabled value="" key="-">
              Bitte wählen
            </option>
            {types.map((t) => {
              if (t.disabled) return null;

              return (
                <option value={t.id} key={t.id}>
                  {t.label}
                </option>
              );
            })}
          </Select>
          {selectedType && (
            /* @ts-expect-error old FormWrapper */
            <FormWrapper>
              {InnerComponent && <InnerComponent />}
              <Submit type="submit">
                {selectedType.submitButtonName ?? 'Bericht erstellen'}
              </Submit>
            </FormWrapper>
          )}
        </>
      )}
    </Formik>
  );
};
