/* eslint-disable no-restricted-syntax, guard-for-in */
import React from 'react';
import { DateTime } from 'luxon';

import {
  formatDate,
  formatTariff,
  formatLoadProfiles,
  formatDateTime,
  formatPlant,
  formatString,
  formatMeter,
  formatBoolean,
  formatDecimal,
  EMPTY_STR,
  formatWorkspace,
} from '../../helpers/formatStrings';
import { TariffIcon, MeterIcon } from '../icons';
import Relation from '../relation';

export const getInputTypeForType = (type: string) => {
  switch (type) {
    case 'String':
    case 'CountryScalar':
    case 'Radio':
      return 'text';
    case 'Int':
    case 'Float':
    case 'ID':
      return 'number';
    case 'Date':
      return 'date';
    case 'DateTime':
      return 'datetime-local';
    case 'Boolean':
      return 'checkbox';
    case 'Enum':
      return 'select';
    default:
      console.warn(`Unknown type: '${type}'. Couldn't translate to input type`);
      return 'text';
  }
};

export const formatValueOfType = (type: string, value: any, unit?: string) => {
  const formatValue = () => {
    switch (type) {
      case 'Date':
        return formatDate(value);

      case 'DateTime':
        return formatDateTime(value);

      case 'Tariff':
        return <Relation icon={TariffIcon} title={formatTariff(value)} />;

      case 'LoadProfile':
        return formatLoadProfiles(value);

      case 'Meter':
        return <Relation icon={MeterIcon} title={formatMeter(value)} />;

      case 'Plant':
        return formatPlant(value);

      case 'Workspace':
        return formatWorkspace(value);

      case 'Float':
        return formatDecimal(value);

      case 'String':
      case 'CountryScalar':
      case 'Radio':
      case 'ID':
      case 'Int':
        return formatString(value);

      case 'Boolean':
        return formatBoolean(value);

      default:
        throw new Error(`Can't format value: '${value}' of type: '${type}'`);
    }
  };

  const formattedValue = formatValue();
  if (formattedValue === EMPTY_STR || !unit) {
    return formattedValue;
  }

  return `${formattedValue} ${unit}`;
};

export const castValueOfType = (type: string, value: any) => {
  switch (type) {
    case 'String':
    case 'CountryScalar':
    case 'Radio':
      return String(value);
    case 'Int':
    case 'ID':
      return parseInt(value, 10);
    case 'Float':
      return parseFloat(value);
    case 'Date':
      return DateTime.fromISO(value, { zone: 'utc' }).toISODate();
    case 'DateTime':
      return DateTime.fromISO(value, { zone: 'utc' }).toISO();
    case 'Boolean':
      return String(value).toLowerCase() === 'true';
    default:
      return value;
  }
};

const { toString } = Object.prototype;

export const castValuesRecursive = (
  fieldProps: Map<string, any>,
  obj: any,
  path: string[] = [],
) => {
  switch (toString.call(obj)) {
    case '[object Object]': {
      const casted: { [key: string]: any } = {};
      for (const key in obj) {
        casted[key] = castValuesRecursive(fieldProps, obj[key], [...path, key]);
      }
      return casted;
    }
    case '[object Array]':
      return obj.map((item: any) =>
        castValuesRecursive(fieldProps, item, [...path, '[0]']),
      );

    case '[object String]':
    case '[object Number]':
    case '[object Boolean]': {
      const fieldName = path.join('.');

      if (!fieldProps.has(fieldName)) {
        /**
         * detect if the last `path` item is like `[0]`
         */
        const isArray = /\[(.+)\]/g.test(path[path.length - 1]);
        const parentField = path.slice(0, -1).join('.');
        if (isArray && fieldProps.has(parentField)) {
          /**
           * cast the array values according to the array dataType
           */
          return castValueOfType(fieldProps.get(parentField).dataType, obj);
        }

        throw new Error(`Unknown Field ${fieldName}`);
      }

      return castValueOfType(fieldProps.get(fieldName).dataType, obj);
    }
    case '[object Undefined]':
    case '[object Null]':
      return obj;

    default:
      throw new Error(`Unknown DataType: '${toString.call(obj)}`);
  }
};
