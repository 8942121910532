import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ComponentLabelProps {
  secondary?: boolean;
  children: ReactNode;
}

interface WithComponentLabelProps extends ComponentLabelProps {
  label: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComponentLabelWrapper = styled.div`
  color: #000000;
  font-size: 14px;
  margin-top: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;

  &.secondary {
    margin-top: 6px;
    font-size: 12px;
    color: #909090;
  }
`;

export const ComponentLabel: React.FC<ComponentLabelProps> = ({
  secondary,
  children,
}) => (
  <ComponentLabelWrapper className={`${secondary ? 'secondary' : ''}`}>
    {children}
  </ComponentLabelWrapper>
);

export function withComponentLabel<T>(
  Component: React.ComponentType<T>,
): React.FC<T & WithComponentLabelProps> {
  return function fn(props) {
    return (
      <Wrapper>
        <Component {...props} />
        <ComponentLabel {...props}>{props.label}</ComponentLabel>
      </Wrapper>
    );
  };
}
