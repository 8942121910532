import React, { ReactNode } from 'react';
import { ThemeProvider } from '@ampeersenergy/ampeers-ui-components';
import type { Theme } from '@ampeersenergy/ampeers-ui-components';

export type { Theme };

export interface Themeprops {
  theme: Theme;
}

const App = ({ children }: { children: ReactNode }) => (
  <ThemeProvider>{children}</ThemeProvider>
);

export default App;
