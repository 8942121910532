import React, { useMemo, useState } from 'react';
import * as yup from 'yup';

import { EditContainerProps } from '../../../../components/createFlow';
import GraphqlForm from '../../../../components/graphql-form';
import { useHasRole } from '../../../../components/useHasRole';
import {
  PriceSheet,
  ReadTariffDocument,
  ReadTariffsDocument,
} from '../../../../graphql-types';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';

import { CreatePricesheetForm } from './createPricesheetForm';

const readDocumentsTariff = `
  tariff {
    id
  }
`;
const readDocumentsContract = `
    contracts { 
      id
      label
      startDate
      signDate
      tariffs {
        nameExternal
        priceGuarantee
        priceGuaranteeReference
      }
      contractMeter {
        meters {
          meterNumber
        }
      }
      customer { 
        label
        person { 
          name
        }
        addressBilling {
          name
          additionalName
          streetName
          streetNumber
          zipCode
          city
          co
        }
      }
      workspace {
        code
      }
    }`;

export default function CreatePricesheetContainer({
  onSuccess,
  onAbort,
  variables,
}: EditContainerProps) {
  const { hasRole: pricesheetChangeLetterRole } = useHasRole(
    'feature_pricesheet_change_letter',
  );
  const [isEnergyPriceMixed, setEnergyPriceMixed] = useState(true);

  const invalidNames = useMemo(() => {
    if (!variables) return [];

    return variables.tariff.priceSheets.map((p: PriceSheet) => p.name);
  }, [variables]);
  const invalidDates = useMemo(() => {
    if (!variables) return [];

    return variables.tariff.priceSheets.map((p: PriceSheet) =>
      new Date(p.startDate).getTime(),
    );
  }, [variables]);

  const readDocumentFields = pricesheetChangeLetterRole
    ? [readDocumentsTariff, readDocumentsContract]
    : [readDocumentsTariff];

  return (
    <GraphqlForm
      mutation="createPriceSheet"
      readDocumentFields={readDocumentFields}
      startInEdit
      isLoading={variables.contractsLoading}
      onSuccess={(data, values) => {
        const { contracts } = data.createPriceSheet;
        if (pricesheetChangeLetterRole && contracts && contracts.length > 0) {
          variables.setAffectedContracts(data.createPriceSheet.contracts);
          if (onAbort) onAbort();
        } else if (onSuccess) onSuccess(data, values);
      }}
      onAbort={onAbort}
      refetchQueries={[
        {
          query: ReadTariffDocument,
          variables: { tariffId: [variables.tariff.id] },
        },
        {
          query: ReadTariffsDocument,
        },
      ]}
      variables={{
        tariffId: variables.tariff.id,
        includeContracts: pricesheetChangeLetterRole,
      }}
      validation={{
        name: yup
          .mixed()
          .notOneOf(
            invalidNames,
            'Es gibt bereits ein Preisblatt mit diesem Namen',
          )
          .required(),
        startDate: yupUTCDate
          .test('startDate', 'Pflichtfeld', (value: any) => {
            return !Number.isNaN(new Date(value).getTime());
          })
          .test(
            'startDate',
            'Darf nicht vor Beginn der Tarif Gültigkeit liegen',
            (value: any) => {
              return (
                new Date(variables.tariff.validityStartDate).getTime() <=
                new Date(value).getTime()
              );
            },
          )
          .test(
            'startDate',
            'Darf nicht nach Ablauf der Tarif Gültigkeit liegen',
            (value: any) => {
              return (
                new Date(value).getTime() <
                new Date(variables.tariff.validityEndDate).getTime()
              );
            },
          )
          .test(
            'startDate',
            'Es gibt bereits ein Preisblatt mit diesem Datum',
            (value: any) => {
              if (!value) {
                return false;
              }

              return !invalidDates.includes(value.getTime());
            },
          )
          .required(),
        ...(isEnergyPriceMixed && {
          energyPrice: yup.number().min(0).required(),
        }),
        ...(!isEnergyPriceMixed && {
          energyPriceLocal: yup.number().min(0).required(),
        }),
        ...(!isEnergyPriceMixed && {
          energyPriceResidual: yup.number().min(0).required(),
        }),
      }}
    >
      <CreatePricesheetForm
        setEnergyPriceMixed={setEnergyPriceMixed}
        isEnergyPriceMixed={isEnergyPriceMixed}
        setNewPriceSheet={variables.setNewPriceSheet}
        newPriceSheet={variables.newPriceSheet}
      />
    </GraphqlForm>
  );
}
