import { Button } from '@ampeersenergy/ampeers-ui-components';
import { useApolloClient } from '@apollo/client';
import { useField, useFormikContext } from 'formik';
import { merge } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Flex, FlexRow, SubTitle } from '../../../components';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import {
  GraphqlFormArray,
  GraphqlFormField,
  GraphqlFormInputGroup,
  GraphqlFormSelect,
} from '../../../components/graphql-form/render';
import { Entry } from '../../../components/layout';
import {
  BalancingAreaAccount,
  BalancingAreaGroupAccount,
  CreateMeterInput,
  MeterType,
  MsoType,
} from '../../../graphql-types';
import { formatString } from '../../../helpers/formatStrings';
import readBalancingAreaAccounts from '../../../queries/readBalancingAreaAccounts';

import MKAT1Icon from './icons/mk_at_1';
import MKD1Icon from './icons/mkd1';
import MKD2Icon from './icons/mkd2';
import MKD3Icon from './icons/mkd3';
import { MeasurementMeter } from './measurementConcepts/measurementMeter';

interface BooleanState {
  value: boolean;
  setValue: (value: boolean) => void;
}

const StyledLabel = styled.label`
  margin-left: 5px;
  font-size: 14px;
`;

const ProjectSelection = styled.div`
  margin-bottom: 15px;
`;

interface ProjectFormProps {
  newProjectState?: [BooleanState['value'], BooleanState['setValue']];
}

export function ProjectForm({ newProjectState }: ProjectFormProps) {
  const localState = useState(false);

  const [useNewProject, _setUseNewProject] = newProjectState ?? localState;
  const { values, setValues } = useFormikContext();

  const setUseNewProject = (newProject: boolean) => {
    if (newProject) {
      setValues(
        merge({}, values, {
          existingProject: '',
        }),
      );
    } else {
      setValues(
        merge({}, values, {
          newProject: { name: '' },
        }),
      );
    }

    _setUseNewProject(newProject);
  };

  return (
    <>
      <SubTitle>Projekt</SubTitle>
      <ProjectSelection>
        <input
          type="radio"
          id="plant-existing-project"
          checked={!useNewProject}
          onClick={() => setUseNewProject(false)}
          readOnly
        />
        <StyledLabel htmlFor="plant-existing-project">
          Einem bestehenden Projekt zuweisen
        </StyledLabel>
        {!useNewProject && <GraphqlFormField name="existingProject.id" />}
      </ProjectSelection>
      <ProjectSelection>
        <input
          type="radio"
          id="plant-new-project"
          checked={useNewProject}
          onClick={() => setUseNewProject(true)}
          readOnly
        />
        <StyledLabel htmlFor="plant-new-project">
          Neues Projekt anlegen
        </StyledLabel>
        <br />
        {useNewProject && (
          <FlexRow>
            <Flex>
              <GraphqlFormField name="newProject.name" />
            </Flex>
          </FlexRow>
        )}
      </ProjectSelection>
    </>
  );
}

/**
 * Measurement
 */

const MeasurementConceptIcons = styled.div`
  width: 400px;
  align-self: center;
`;

const MeasurementWrapper = styled(FlexRow)`
  margin-top: 25px;
`;

const Spacing = styled.div`
  margin-top: 15px;
`;

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function MeasurementConceptForm() {
  const [{ value: measurementConcept }] = useField('measurementConcept');
  const { setFieldValue, getFieldProps } = useFormikContext();
  const prevMeasurementConcept = usePrevious(measurementConcept);

  useEffect(() => {
    /**
     * set default values when measurement concept changes
     */
    if (measurementConcept !== prevMeasurementConcept) {
      // eslint-disable-next-line default-case
      switch (measurementConcept) {
        case 'MKD1-SLP':
          setFieldValue('meters', [
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoH,
              msoType: MsoType.Ha,
              meterPlace: 'Summenzähler',
            },
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoE,
              msoType: MsoType.E1,
            },
          ]);
          break;
        case 'MKD3-RLM':
          setFieldValue('meters', [
            {
              converterFactor: 1,
              metering: 'RLM',
              meterType: MeterType.MsoH,
              msoType: MsoType.Ha,
              meterPlace: 'Summenzähler',
            },
            {
              converterFactor: 1,
              metering: 'RLM',
              meterType: MeterType.MsoE,
              msoType: MsoType.E1,
            },
            {
              converterFactor: 1,
              metering: 'RLM',
              meterType: MeterType.MsoE,
              msoType: MsoType.E2,
            },
          ]);
          break;
        case 'MKD2-SLP':
          setFieldValue('meters', [
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoH,
              msoType: MsoType.Ha,
              meterPlace: 'Summenzähler',
            },
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoE,
              msoType: MsoType.E1,
            },
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoE,
              msoType: MsoType.E2,
            },
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoK,
              msoType: MsoType.K,
            },
          ]);
          break;
        case 'MK_AT_1':
          setFieldValue('meters', [
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoH,
              msoType: MsoType.Ha,
              meterPlace: 'Summenzähler',
            },
            {
              converterFactor: 1,
              metering: '',
              meterType: MeterType.MsoE,
              msoType: MsoType.E1,
            },
          ]);
          break;
      }
    }
  }, [
    measurementConcept,
    setFieldValue,
    getFieldProps,
    prevMeasurementConcept,
  ]);

  const addMeter = useCallback(() => {
    setFieldValue('meters', [
      ...getFieldProps('meters').value,
      {
        converterFactor: 1,
        metering: '',
        meterType: MeterType.MsoE,
        msoType: MsoType.E1,
      },
    ]);
  }, [setFieldValue, getFieldProps]);

  const removeMeter = useCallback(
    (index: number) => {
      const meters = getFieldProps('meters').value;
      const nextMeters = [...meters];
      nextMeters.splice(index, 1);
      setFieldValue('meters', nextMeters);
    },
    [setFieldValue, getFieldProps],
  );

  const meters: CreateMeterInput[] = measurementConcept
    ? getFieldProps('meters').value
    : [];

  return (
    <>
      <GraphqlFormSelect
        name="measurementConcept"
        id="measurementConcept"
        label="Summenzählermodell"
      >
        <option disabled hidden value="">
          Bitte wählen
        </option>
        <option value="MKD1-SLP">eine Anlage</option>
        <option value="MKD3-RLM">zwei Anlagen (RLM)</option>
        <option value="MKD2-SLP">zwei Anlagen + Kaskade</option>
      </GraphqlFormSelect>
      <MeasurementWrapper>
        <MeasurementConceptIcons>
          {measurementConcept === 'MKD1-SLP' && <MKD1Icon />}
          {measurementConcept === 'MKD2-SLP' && <MKD2Icon />}
          {measurementConcept === 'MKD3-RLM' && <MKD3Icon />}
          {measurementConcept === 'MK_AT_1' && <MKAT1Icon />}
        </MeasurementConceptIcons>
        <Flex>
          {meters.map((m, index) => (
            <MeasurementMeter
              key={`${m.meterType}${m.msoType}`}
              name={`meters.[${index}].`}
              meter={m}
              deleteable={measurementConcept === 'MK_AT_1' && index > 1}
              meteringDisabled={measurementConcept === 'MKD3-RLM'}
              onDelete={() => removeMeter(index)}
              prefixGeneration={measurementConcept !== 'MK_AT_1'}
            />
          ))}
          {measurementConcept === 'MK_AT_1' && (
            <Spacing>
              <Button secondary onClick={addMeter}>
                weiteren Zähler hinzufügen
              </Button>
            </Spacing>
          )}
        </Flex>
      </MeasurementWrapper>
    </>
  );
}

/**
 * Address
 */

const FieldGroup = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const FixedWidthWhileEdit = styled.div`
  .is-editing & {
    display: flex;
    width: initial !important;
  }
`;

const Spacer = styled.div`
  width: 5px;
`;

interface AddressFormProps {
  showActionButtons?: boolean;
  maxItems?: number;
  subTitle?: (selected: number) => string;
  sidebarTitle?: () => string;
  isLoading?: boolean;
  hintLimitReached: string;
}

const FORM_NAME = 'addresses';
export function AddressForm(props: AddressFormProps) {
  const { isEditing } = useGraphqlForm();
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [address, setAddress] = useState({
    city: '',
    zipCode: '',
  });
  const [currentItem, setCurrentItem] = useState(0);

  useEffect(() => {
    if (currentItem) {
      setFieldValue(`${FORM_NAME}.[${currentItem}].zipCode`, address.zipCode);
      setFieldValue(`${FORM_NAME}.[${currentItem}].city`, address.city);
    }
  }, [currentItem, address.city, address.zipCode, setFieldValue]);

  return (
    <GraphqlFormArray
      {...props}
      kind="Adresse"
      pathPrefixName={FORM_NAME}
      maxItems={5}
      setCurrentItem={setCurrentItem}
      renderItem={(item, index, maxItems) => {
        if (item.streetName) {
          return `${item.streetName} ${item.streetNumber ?? ''}`;
        }
        if (maxItems && index - 1 < maxItems) {
          return 'Neue Adresse';
        }
        return null;
      }}
      renderForm={(pathPrefixName) => {
        return isEditing ? (
          <FieldGroup>
            <GraphqlFormInputGroup>
              <GraphqlFormField
                name={`${pathPrefixName}streetName`}
                label={null}
              />
              <FixedWidthWhileEdit>
                <GraphqlFormField
                  name={`${pathPrefixName}streetNumber`}
                  label={null}
                />
              </FixedWidthWhileEdit>
            </GraphqlFormInputGroup>
            <GraphqlFormInputGroup>
              <GraphqlFormField
                name={`${pathPrefixName}zipCode`}
                label={null}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  setAddress((state) => ({
                    ...state,
                    zipCode: value,
                  }));
                }}
              />
              <GraphqlFormField
                name={`${pathPrefixName}city`}
                label={null}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  setAddress((state) => ({
                    ...state,
                    city: value,
                  }));
                }}
              />
              <GraphqlFormField
                name={`${pathPrefixName}country`}
                label={null}
              />
            </GraphqlFormInputGroup>
          </FieldGroup>
        ) : (
          <FieldGroup>
            <FlexRow>
              <Entry title={null} growContent={false}>
                {formatString(
                  getFieldProps(`${pathPrefixName}streetName`).value,
                )}
              </Entry>
              <Spacer />
              <Entry title={null} growContent={false}>
                {formatString(
                  getFieldProps(`${pathPrefixName}streetNumber`).value,
                )}
              </Entry>
            </FlexRow>
            <FlexRow>
              <Entry title={null} growContent={false}>
                {formatString(getFieldProps(`${pathPrefixName}zipCode`).value)}
              </Entry>
              <Spacer />
              <Entry title={null} growContent={false}>
                {formatString(getFieldProps(`${pathPrefixName}city`).value)}
              </Entry>
              <Spacer />
              <Entry title={null} growContent={false}>
                {formatString(getFieldProps(`${pathPrefixName}country`).value)}
              </Entry>
            </FlexRow>
          </FieldGroup>
        );
      }}
    />
  );
}

/**
 * Grid
 */
export function GridForm() {
  const { values } = useFormikContext();
  const client = useApolloClient();

  const filterBalancingAreaGroupAccount = useCallback(
    (items: BalancingAreaGroupAccount[]) => {
      if (!(values as any).balancingAreaAccount.id) {
        return items;
      }

      // read from cache
      const result = client.readQuery({
        query: readBalancingAreaAccounts,
      });

      const { id } = (values as any).balancingAreaAccount;
      const { transmissionSystemOperator } =
        result.readBalancingAreaAccounts.find(
          (aera: BalancingAreaAccount) => aera.id === id,
        );

      if (!transmissionSystemOperator) {
        throw new Error(`No BalancingAreaAccount found with id: ${id}'`);
      }

      const tsoId = parseInt(transmissionSystemOperator.id, 10);

      return items.filter(
        (item) => item.transmissionSystemOperatorId === tsoId,
      );
    },
    [values, client],
  );

  // TODO: Enable when we know how to handle suppliers on BGA
  // const filterSupplierAccount = useCallback(
  //   (suppliers: Supplier[]) => {
  //     if (!(values as any).balancingAreaGroupAccount.id) {
  //       return suppliers;
  //     }

  //     // read from cache
  //     const result = client.readQuery({
  //       query: readBalancingAreaGroupAccounts,
  //     });

  //     const { id } = (values as any).balancingAreaGroupAccount;

  //     const group: BalancingAreaGroupAccount | null =
  //       result.readBalancingAreaGroupAccounts.find(
  //         (balancingGroup: BalancingAreaGroupAccount) =>
  //           balancingGroup.id === id,
  //       );

  //     if (!group) {
  //       throw new Error(`No BalancingAreaGroupAccount found with id: ${id}'`);
  //     }

  //     const groupSupplierIds = group.suppliers.map((s) => s.id);

  //     return suppliers.filter((supplier) =>
  //       groupSupplierIds.includes(supplier.id),
  //     );
  //   },
  //   [values, client],
  // );

  return (
    <>
      <GraphqlFormField name="balancingAreaAccount.id" />
      <GraphqlFormField
        name="balancingAreaGroupAccount.id"
        filter={filterBalancingAreaGroupAccount}
      />
      <GraphqlFormField
        name="supplierAccount.id"
        // filter={filterSupplierAccount}
      />
      <GraphqlFormField name="meteringPointOperator.id" />
    </>
  );
}
/**
 * BaseData
 */
export function BaseDataForm() {
  return (
    <>
      <GraphqlFormField name="name" label="Name" placeholder="Name" />
      <GraphqlFormField
        name="units"
        label="Anzahl Verbrauchseinheiten"
        placeholder="Anzahl Verbrauchseinheiten"
      />
    </>
  );
}
