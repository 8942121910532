import * as React from 'react';
import {
  DialogProvider,
  Tabs,
  Tab,
} from '@ampeersenergy/ampeers-ui-components';
import 'styled-components/macro';
import * as yup from 'yup';

import {
  yupAddress,
  yupBicValidation,
  yupIbanValidation,
} from '../../../helpers/validators';
import {
  ClientDetailsInput,
  useReadClientDetailsQuery,
  useReadWorkspacesQuery,
} from '../../../graphql-types';
import { transformNullToUndefined } from '../../../helpers/utils';
import { useHasRole } from '../../../components/useHasRole';

import { ContractDraftsSettingsForm } from './ContractDraftSettingsForm';
import { AddressSettingsForm } from './AddressSettingsForm';
import { WorkspaceSettingsForm } from './WorkspaceSettingsForm';
import { DocumentsSettingsFormWrapper } from './DocumentsSettingsFormWrapper';

const baseValidation = (
  required: boolean,
  supplierChangeEnabled?: boolean,
) => ({
  companyName: yup.string().isRequired(required),
  address: yupAddress(required),
  email: yup.string().email().isRequired(supplierChangeEnabled),
  banking: yup.object().shape({
    iban: yupIbanValidation(required),
    bic: yupBicValidation(required),
  }),
  contact: yup.object().shape({
    serviceHotline: yup.string().isRequired(required),
    serviceEmail: yup.string().email().isRequired(required),
    serviceHours: yup.string().isRequired(required),
    footer1: yup.string().isRequired(required),
    footer2: yup.string().isRequired(required),
    footer3: yup.string(),
    footer4: yup.string(),
  }),
});

export function DocumentsSettings() {
  const { data, loading } = useReadClientDetailsQuery();

  const { data: workspaces, loading: workspacesLoading } =
    useReadWorkspacesQuery();

  const { hasRole: supplierChangeEnabled } = useHasRole(
    'feature_supplier_change',
  );

  const contactValidation = baseValidation(
    true,
    supplierChangeEnabled ?? false,
  );

  const initialValues = React.useMemo(() => {
    if (
      data?.readClientDetails &&
      Object.keys(data?.readClientDetails).length > 0
    ) {
      return transformNullToUndefined(data.readClientDetails);
    }
  }, [data]);

  const onBeforeSubmitWorkspaces = async (values: ClientDetailsInput) => ({
    ...values,
    workspaces:
      values?.workspaces
        ?.map((workspace: any) => {
          if (workspace.name) {
            return workspace;
          }
          return undefined;
        })
        .filter((workspace: any) => !!workspace) ?? [],
  });

  const isLoading = loading || workspacesLoading;

  return (
    <DialogProvider>
      <div css="width: 100%;">
        <div css="display: block; max-width: 1024px; margin-bottom: 30px;">
          <Tabs>
            {/* @ts-expect-error old Tab */}
            <Tab title="Adress- und Kontaktdaten">
              <DocumentsSettingsFormWrapper
                initialValues={initialValues}
                isLoading={isLoading}
                validation={contactValidation}
              >
                <AddressSettingsForm />
              </DocumentsSettingsFormWrapper>
            </Tab>
            {workspaces?.readWorkspaces &&
            workspaces?.readWorkspaces?.length > 1 ? (
              <>
                {/* @ts-expect-error old Tab */}
                <Tab title="Vertragstypen">
                  <DocumentsSettingsFormWrapper
                    initialValues={initialValues}
                    isLoading={isLoading}
                    validation={{
                      workspaces: yup
                        .array()
                        .of(
                          yup.object().shape(baseValidation(false)).nullable(),
                        )
                        .nullable(),
                    }}
                    onBeforeSubmit={onBeforeSubmitWorkspaces}
                  >
                    <WorkspaceSettingsForm
                      workspaces={workspaces?.readWorkspaces}
                    />
                  </DocumentsSettingsFormWrapper>
                </Tab>
              </>
            ) : null}
            {/* @ts-expect-error old Tab */}
            <Tab title="Dokumenten Einstellungen">
              <DocumentsSettingsFormWrapper
                initialValues={initialValues}
                validation={{
                  address: yup.mixed().nullable(),
                }}
                isLoading={isLoading}
              >
                <ContractDraftsSettingsForm />
              </DocumentsSettingsFormWrapper>
            </Tab>
          </Tabs>
        </div>
      </div>
    </DialogProvider>
  );
}
