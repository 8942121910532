import React, { Fragment, ReactNode } from 'react';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Section, Icons } from '@ampeersenergy/ampeers-ui-components';

import { FlexRow, StyledRouterLink, Flex, StyledIcon } from '../../components';
import { ReadDashboardQuery } from '../../graphql-types';

const { Accounting, Checkmark } = Icons;

const Bold = styled.span`
  font-weight: 600;
`;

const Intro = styled(Flex)`
  font-size: 20px;
  margin-bottom: 10px;
`;

const StyledNumber = styled.span`
  background: #fc9927;
  border-radius: 50px;
  color: #ffffff;
  width: 25px;
  height: 25px;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -2px;
  left: -2px;
  margin-left: 2px;
  margin-right: 2px;
`;

const StyledLink = styled(StyledRouterLink)`
  font-weight: normal;
`;

const buildHref = (i: ReadDashboardQuery['drafts'][0]) =>
  i.workflow
    ? `/accounting/workflows/${i.workflow?.id}-${i.workflow?.alias}`
    : `/accounting`;

const renderLinks = (items: ReadDashboardQuery['drafts']): ReactNode[] => {
  const links = [];
  const [first, second, third, ...more] = items;

  if (first) {
    links.push(
      <Fragment key="von">Von </Fragment>,
      <StyledLink key={first.customerName} to={buildHref(first)}>
        {first.customerName}
      </StyledLink>,
    );
  }

  if (second) {
    if (!third) {
      links.push(<Fragment key="und"> und </Fragment>);
    } else {
      links.push(<Fragment key="und">, </Fragment>);
    }

    links.push(
      <StyledLink key={second.customerName} to={buildHref(second)}>
        {second.customerName}
      </StyledLink>,
    );

    if (third) {
      if (!more || more.length === 0) {
        links.push(<Fragment key="secondUnd"> und </Fragment>);
      } else {
        links.push(<Fragment key="secondUnd">, </Fragment>);
      }

      links.push(
        <StyledLink key={third.customerName} to={buildHref(third)}>
          {third.customerName}
        </StyledLink>,
      );

      if (more && more.length > 0) {
        links.push(
          <Fragment key="more">
            {' '}
            und{' '}
            <StyledLink to="/accounting">{`${more.length} weitere`}</StyledLink>
          </Fragment>,
        );
      }
    }
  }

  return links;
};

const WorklistItem = styled(FlexRow)`
  padding: 10px 12px;

  :nth-child(2n) {
    background-color: #f7f7f7;
    border-radius: 4px;
  }
`;

const WorklistItemIcon = styled.div`
  margin-right: 10px;
  margin-top: 4px;
`;

const WorklistContent = styled(Flex)`
  font-size: 15px;
`;

const DisableLink: React.FC<{ to?: string; children: ReactNode }> = ({
  to,
  children,
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!to) return <>{children}</>;
  return <Link to={to}>{children}</Link>;
};

const renderWorklistItem = ({
  Icon,
  Title,
  Content,
  linkTo,
}: {
  Icon: React.ReactNode;
  Title: React.ReactNode;
  Content: React.ReactNode;
  linkTo?: string;
}): JSX.Element => {
  return (
    <WorklistItem>
      <DisableLink to={linkTo}>
        <WorklistItemIcon>{Icon}</WorklistItemIcon>
      </DisableLink>
      <Flex>
        <DisableLink to={linkTo}>
          <Bold>{Title}</Bold>{' '}
        </DisableLink>
        <WorklistContent>{Content}</WorklistContent>
      </Flex>
    </WorklistItem>
  );
};

const renderLoadingWorklistItem = (): JSX.Element => {
  return renderWorklistItem({
    Icon: (
      <ContentLoader height={35} width={35}>
        <rect x="4" y="0" rx="4" ry="4" height={35} width={35} />
      </ContentLoader>
    ),

    Title: (
      <ContentLoader height={10} width={120}>
        <rect x="0" y="0" rx="4" ry="4" height={10} width={120} />
      </ContentLoader>
    ),

    Content: (
      <ContentLoader height={10} width={180}>
        <rect x="0" y="0" rx="4" ry="4" height={10} width={180} />
      </ContentLoader>
    ),
  });
};

const DoneItem = styled(FlexRow)`
  flex-direction: column;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  height: 350px;
`;

const Center = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

function RenderDone(): JSX.Element {
  const theme = useTheme();

  return (
    <Center>
      <DoneItem>
        <div>
          <Checkmark size={55} color={theme.palette.success.color} />
        </div>
        Alles Erledigt
      </DoneItem>
    </Center>
  );
}

function DashboardWorklist({
  isLoading,
  firstName,
  drafts,
}: {
  isLoading: boolean;
  firstName?: string;
  drafts?: ReadDashboardQuery['drafts'];
}): JSX.Element {
  const theme = useTheme();

  if (isLoading) {
    return (
      <Section>
        <Intro>
          <div>
            Hallo <Bold>{firstName}</Bold>,{' '}
          </div>
          <div>
            Du hast heute{' '}
            <ContentLoader width={30} height={30}>
              <circle cx="15" cy="15" r="15" />
            </ContentLoader>
            Aufgaben
          </div>
        </Intro>
        {renderLoadingWorklistItem()}
      </Section>
    );
  }

  return (
    <Section>
      <Intro>
        <div>
          Hallo <Bold>{firstName}</Bold>,{' '}
        </div>
        <div>
          Du hast heute <StyledNumber>{drafts?.length}</StyledNumber>
          {drafts?.length === 1 ? ' Aufgabe' : 'Aufgaben'}:
        </div>
      </Intro>
      {drafts &&
        drafts.length > 0 &&
        renderWorklistItem({
          Icon: (
            <StyledIcon>
              <Accounting color={theme.primaryColor} size={25} />
            </StyledIcon>
          ),
          Title: 'Freizugebende Rechnungen',
          Content: renderLinks(drafts),
          linkTo: '/accounting',
        })}
      {drafts && drafts.length === 0 && RenderDone()}
    </Section>
  );
}

export default DashboardWorklist;
