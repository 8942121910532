import React from 'react';
import * as yup from 'yup';

import GraphqlForm from '../../../../components/graphql-form';
import {
  GraphqlFormField,
  GraphqlFormSelect,
} from '../../../../components/graphql-form/render';
import { Metadata, ReadMetadataDocument } from '../../../../graphql-types';
import { EditContainerProps } from '../../../../components/createFlow';

export default function AddKeyValueContainer({
  onSuccess,
  onAbort,
  variables,
}: EditContainerProps) {
  const usedKeys = variables?.metadata.map(
    (metadata: Metadata) => metadata.key,
  );
  const availableKeys = variables?.metadataKeys
    .map((metadata: Metadata) => metadata.key)
    .filter((key: string) => !usedKeys.includes(key));

  return (
    <GraphqlForm
      mutation="createMetadata"
      startInEdit
      onSuccess={onSuccess}
      onAbort={onAbort}
      refetchQueries={[
        {
          query: ReadMetadataDocument,
          variables: {
            contractLabel: variables.contractLabel,
          },
        },
      ]}
      variables={{
        contractLabel: variables.contractLabel,
        customerLabel: variables.customerLabel,
      }}
      formVariables={{}}
      validation={{
        key: yup.string().required(),
        value: yup.string().required(),
      }}
      readDocument={ReadMetadataDocument}
    >
      <GraphqlFormSelect name="key" label="Schlüssel">
        <option disabled hidden value="">
          Bitte wählen
        </option>
        {availableKeys.map((_: Metadata, index: number) => {
          return (
            <option value={availableKeys[index]}>{availableKeys[index]}</option>
          );
        })}
      </GraphqlFormSelect>
      <GraphqlFormField name="value" label="Wert" placeholder="Wert" />
    </GraphqlForm>
  );
}
