import {
  Flex,
  FlexRow,
  HistoricItem,
  HistoricTabs,
  PaddedShadowBox,
  Icons,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { DeepExtractType } from 'ts-deep-extract-types';

import { Entry, Relation } from '../../../../components';
import { StyledLink } from '../../../../components/graphql-form/style';
import {
  ReadContractsForMeterQuery,
  useReadContractsForMeterQuery,
} from '../../../../graphql-types';
import {
  formatDate,
  formatEmgEndDates,
} from '../../../../helpers/formatStrings';
import { isFinished } from '../../../../helpers/utils';

type ContractType = DeepExtractType<
  ReadContractsForMeterQuery,
  ['readContractsForMeter']
>[0];

type MeterHistoricContract = {
  contractId: string;
  contractLabel: string;
  customerNames: string[];
  validityStartDate?: string;
  validityEndDate?: string;
};

export function MeterHistory({ meterId }: { meterId: string }) {
  const { data, loading } = useReadContractsForMeterQuery({
    variables: { meterId },
  });

  const result = data?.readContractsForMeter;

  // TODO: contractMeter does not exist anymore
  const values = result
    ?.map((contract) => {
      const meter = contract.meters.find(
        (contractMeter) => contractMeter.id === meterId,
      );
      return {
        date: meter?.validityStart,
        id: contract.id,
        value: contract.label,
      } as HistoricItem;
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <PaddedShadowBox>
      <HistoricTabs
        items={values}
        title="Verträge"
        isLoading={loading}
        renderValue={(item) => (
          <Relation icon={Icons.Contract} title={item.value!} />
        )}
      >
        {(item) => {
          const currentContract: ContractType | undefined = result?.find(
            (contract) => contract.id === item.id,
          );

          if (!currentContract) return null;

          const currentMeter = currentContract.meters.find(
            (meter) => meter.id === meterId,
          );

          const meterContract: MeterHistoricContract = {
            validityStartDate: formatDate(
              currentMeter?.validityStart ?? undefined,
            ),
            validityEndDate: `${formatEmgEndDates(
              currentMeter?.validityEnd ?? undefined,
              true,
              false,
            )} ${isFinished(currentMeter?.validityEnd) ? '(beendet)' : ''}`,
            contractId: currentContract.id,
            contractLabel: currentContract.label,
            customerNames: currentContract.customerNames,
          };

          return <MeterHistoryContract contract={meterContract} />;
        }}
      </HistoricTabs>
    </PaddedShadowBox>
  );
}

function MeterHistoryContract({
  contract,
}: {
  contract: MeterHistoricContract;
}) {
  const location = useLocation();

  const basePath = getBasePath(location.pathname);
  return (
    <>
      <FlexRow>
        <Flex>
          <Entry title="Beginn Zuordnungsgültigkeit">
            {contract.validityStartDate}
          </Entry>
        </Flex>
        <Flex>
          <Entry title="Ende Zuordnungsgültigkeit">
            {contract.validityEndDate}
          </Entry>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <Entry title="Vertrag">
            <StyledLink
              to={`${basePath}/tenant/contract/${contract.contractId}/measurement`}
            >
              <Relation icon={Icons.Contract} title={contract.contractLabel} />
            </StyledLink>
          </Entry>
        </Flex>
        <Flex>
          <Entry title="Vertragspartner">
            {contract.customerNames.map((name) => (
              <Flex>{name}</Flex>
            ))}
          </Entry>
        </Flex>
      </FlexRow>
    </>
  );
}

function getBasePath(path: string) {
  if (path.includes('building')) {
    return path.split('/building/')[0];
  }
  if (path.includes('tenant')) {
    return path.split('/tenant/')[0];
  }
  return path.split('/meter/')[0];
}
