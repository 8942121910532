import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  from,
  HttpLink,
} from '@apollo/client';

import {
  errorMiddleware,
  setHeaderMiddleware,
  receiveSessionHeaderMiddleware,
} from './client-middleware';
import { _ae_env_ } from './helpers/env';

if (!_ae_env_.REACT_APP_BACKEND_BASE_URI) {
  throw new Error('_ae_env_.REACT_APP_BACKEND_BASE_URI is required');
}

const httpLink = new HttpLink({
  uri: `${_ae_env_.REACT_APP_BACKEND_BASE_URI}/graphql`,
});

export const client = new ApolloClient({
  link: from([
    setHeaderMiddleware,
    receiveSessionHeaderMiddleware,
    errorMiddleware,
    httpLink,
  ]),

  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'ContractMeterExcerpt': {
          const { isOperator = '', customerLabel = '' } = { ...object };
          return `${object.id}-${isOperator}-${customerLabel}`;
        }
        case 'MeterInfo': {
          const { validityStart = '' } = { ...object };
          return `${object.id}-${validityStart}`;
        }
        case 'Meter': {
          const { melo = '', contractId = '' } = object;
          return `${object.id}-${melo}-${contractId}`;
        }
        case 'OperationResult': {
          const { jobId = '' } = object;
          return `${object.id}-${jobId}`;
        }
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
    typePolicies: {
      Query: {
        fields: {
          // We need a custom merging policy for handling the list of accounting workflow. For more information see
          // https://www.apollographql.com/docs/react/pagination/cursor-based/#keeping-cursors-separate-from-items
          getAccountingRunWorkflows: {
            merge(existing, incoming, { readField }) {
              const data: Record<string, any> = {};

              incoming.data.forEach((workflow: any) => {
                const id = readField('WorkflowId', workflow) as string;
                if (id) {
                  data[id] = workflow;
                }
              });

              return {
                nextPageToken: incoming.nextPageToken,
                totalCount: incoming.totalCount,
                data,
              };
            },

            read(existing) {
              if (existing) {
                return {
                  nextPageToken: existing.nextPageToken,
                  totalCount: existing.totalCount,
                  data: Object.values(existing.data),
                };
              }
            },
          },
        },
      },
    },
  }),
});
