import React, { ReactNode } from 'react';
import styled from 'styled-components';

const OuterLabel = styled.span`
  display: inline-block;

  & > span {
    margin-right: 17px;
  }
`;

const InnerLabel = styled.span`
  background: #6c6c6c;
  display: inline-block;
  padding: 2px 5px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  margin-left: 5px;
  border-radius: 4px;
  font-weight: bold;
  position: relative;
  top: -2px;
  color: #fff;
`;

const energyTypes = {
  mixed: 'Gemischt',
  local: 'Lokal',
  residual: 'Rest',
};

export const EnergyLabel: React.FC<{
  type: 'local' | 'residual' | 'mixed';
  children: ReactNode;
}> = ({ children, type }) => {
  return (
    <OuterLabel>
      {children}
      <InnerLabel>{energyTypes[type]}</InnerLabel>
    </OuterLabel>
  );
};
