import {
  Expandable,
  Expandables,
  FlexRow,
  Icons,
  SubTitle,
  Table,
  TableCells,
} from '@ampeersenergy/ampeers-ui-components';
import { groupBy } from 'lodash';
import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';

import {
  AccountingRunValidatedConsumption,
  AccountingRunValidatedContractMeter,
  AccountingRunValidationError,
  OverviewStepResult,
} from '../../../../graphql-types';

import {
  columns,
  consumptionErrorColumns,
  ErrorTitle,
  FlexRowWithCenteredItems,
  formatPluralType,
  mergeErrorReasons,
} from './OverviewStepHelper';
import { renderErrorRow } from './RenderErrorRow';

const IconWrap = styled.div`
  margin-right: 5px;
`;

const SubtitleDescription = styled.p`
  color: ${({ theme }) => theme.palette.textMuted};
  margin: 5px 0;
  font-size: 14px;
  font-weight: initial;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const errorsForUser = [
  'missingMeterReading',
  'NON_INTERPOLATABLE_METER_CHANGE_AT_ACC_PERIOD_END',
  'invalidMeterReading',
  'BEGINREADING_GREATER_THAN_END_READING',
  'CC_42304__Ungültige Folge von Erfassungshinweisen',
];

interface ValidateReadingsResultProps {
  result: OverviewStepResult;
}

export type GroupedByErrorType = {
  [key: string]: {
    [key: string]: any[];
  };
};

// TODO: Refactor and simplify how contracts and errors are shown here
export function OverviewStepResults({ result }: ValidateReadingsResultProps) {
  const theme = useTheme();

  const {
    validContracts,
    invalidContracts,
    additionalErrors,
    consumptionValuesWarnings,
  } = result;
  const allContracts = React.useMemo(
    () => [...(validContracts ?? []), ...(invalidContracts ?? [])],
    [validContracts, invalidContracts],
  );

  const validContractsWithoutWarnings = React.useMemo(() => {
    const contractsWithWarningsIds = consumptionValuesWarnings?.map(
      (c) => c.contractLabel,
    );
    return validContracts.filter(
      (contract) => !contractsWithWarningsIds?.includes(contract.contractLabel),
    );
  }, [consumptionValuesWarnings, validContracts]);

  const rowLink = React.useCallback(
    ({ row }: TableCells<AccountingRunValidatedContractMeter>) => {
      const contract = row.original;
      const baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;
      return `${baseURL}/contract/${contract.id}`;
    },
    [],
  );

  const rowLinkConsumptions = React.useCallback(
    ({ row }: TableCells<AccountingRunValidatedConsumption>) => {
      const contract = row.original;
      const baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;
      return `${baseURL}/contract/${contract.id}`;
    },
    [],
  );

  const additionalErrorLink = React.useCallback(
    ({ row }: TableCells<AccountingRunValidationError>) => {
      const entry = row.original;
      let contract;
      let baseURL;

      switch (entry.type) {
        case 'contract':
          contract = allContracts.find((c) => c.id === entry.sourceId);
          baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;
          return `${baseURL}/contract/${entry.sourceId}`;
        case 'contractcomponent':
          contract = allContracts.find((c) => c.id === entry.contractId);
          baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;
          return `${baseURL}/contract/${entry.contractId}/contract`;
        case 'plant':
          contract = allContracts.find((c) => c.plantId === entry.sourceId);
          return `/project/${contract?.projectId}/plant/${contract?.plantId}`;
        default:
          break;
      }
    },
    [allContracts],
  );

  /**
   * we basically group all the errors by the type
   */
  const groupedByError: GroupedByErrorType = React.useMemo(
    () => ({
      plant: {
        ...groupBy(
          additionalErrors?.filter((e) => e?.type === 'plant'),
          (e) => (e ? mergeErrorReasons(e.errorString) : ''),
        ),
      },
      commonContract: {
        ...groupBy(
          additionalErrors?.filter((e) => e?.type === 'contract'),
          (e) => (e ? mergeErrorReasons(e.errorString) : ''),
        ),
      },
      contractMeter: groupBy(
        invalidContracts
          ?.map(({ meters, ...contract }) =>
            meters?.map(({ errors, ...meter }) =>
              errors.map((meterError) => ({
                ...meterError,
                ...contract,
                ...meter,
              })),
            ),
          )
          .flat(3),
        (e) => (e ? mergeErrorReasons(e.reason) : ''),
      ),
      contractcomponent: {
        ...groupBy(
          additionalErrors?.filter((e) => e?.type === 'contractcomponent'),
          (e) => (e ? mergeErrorReasons(e.errorString) : ''),
        ),
      },
    }),
    [additionalErrors, invalidContracts],
  );

  const errorGroups = React.useMemo(() => {
    const renderErrorsForUser: React.ReactNode[] = [];
    const renderErrorsForSupport: React.ReactNode[] = [];
    Object.keys(groupedByError).forEach((type) => {
      Object.keys(groupedByError[type]).forEach((errorMessage) => {
        if (errorsForUser.includes(errorMessage)) {
          renderErrorsForUser.push(
            renderErrorRow(
              type,
              errorMessage,
              groupedByError,
              additionalErrorLink,
              rowLink,
            ),
          );
        } else {
          renderErrorsForSupport.push(
            renderErrorRow(
              type,
              errorMessage,
              groupedByError,
              additionalErrorLink,
              rowLink,
              true,
            ),
          );
        }
      });
    });
    return {
      renderErrorsForUser,
      renderErrorsForSupport,
    };
  }, [groupedByError, additionalErrorLink, rowLink]);

  return (
    <>
      <SubTitle>
        Abrechenbare Verträge
        <SubtitleDescription>
          Folgende Verträge können abgerechnet werden
        </SubtitleDescription>
      </SubTitle>
      {validContracts.length === 0 && (
        <>
          {/* @ts-expect-error old Expandables */}
          <Expandables>
            <Expandable
              title={
                <FlexRow css="align-items: center;">
                  <>
                    <IconWrap>
                      <Icons.Warning
                        size={30}
                        color={theme.palette.warning.color}
                      />
                    </IconWrap>
                    Keine abrechenbaren Verträge gefunden
                  </>
                </FlexRow>
              }
            />
          </Expandables>
        </>
      )}

      {validContractsWithoutWarnings.length !== 0 && (
        <>
          {/* @ts-expect-error old Expandables */}
          <Expandables showChevron>
            {/* @ts-expect-error old Expandables */}
            <Expandable
              title={
                <FlexRowWithCenteredItems>
                  <>
                    <IconWrap>
                      <Icons.Checkmark
                        size={30}
                        color={theme.palette.success.color}
                      />
                    </IconWrap>
                    {`${validContractsWithoutWarnings?.length} ${
                      validContractsWithoutWarnings?.length === 1
                        ? 'abrechenbarer'
                        : 'abrechenbare'
                    } ${formatPluralType(
                      'contract',
                      validContractsWithoutWarnings?.length,
                    )} gefunden`}
                  </>
                </FlexRowWithCenteredItems>
              }
            >
              {validContracts?.length > 0 ? (
                <Table
                  columns={columns}
                  data={validContractsWithoutWarnings}
                  filterKind="Verträge"
                  isLoading={false}
                  rowLink={rowLink}
                  openInNewTab
                  compact
                  withAlternatingRows
                  withPagination
                  withFilter
                />
              ) : null}
            </Expandable>
          </Expandables>
        </>
      )}
      {consumptionValuesWarnings && consumptionValuesWarnings.length > 0 ? (
        <>
          {/* @ts-expect-error old Expandables */}
          <Expandables css="margin-top: 1rem;" showChevron>
            {/* @ts-expect-error old Expandables */}
            <Expandable
              title={
                <ErrorTitle
                  count={consumptionValuesWarnings.length}
                  type="contract"
                  error="auffälligem Verbrauch gefunden"
                  isError={false}
                />
              }
            >
              <Table
                columns={consumptionErrorColumns}
                data={consumptionValuesWarnings}
                filterKind="Verträge"
                isLoading={false}
                rowLink={rowLinkConsumptions}
                openInNewTab
                compact
                withAlternatingRows
                withPagination
                withFilter
              />
            </Expandable>
          </Expandables>
        </>
      ) : null}
      {errorGroups.renderErrorsForUser.length > 0 && (
        <>
          <SubTitle>
            Problembehebung durch Dich erforderlich
            <SubtitleDescription>
              Ohne Korrektur werden diese Verträge nicht mit in den
              Rechnungslauf übernommen.
            </SubtitleDescription>
          </SubTitle>
          <ErrorContainer>
            {errorGroups.renderErrorsForUser.map((error, index) => (
              <>
                {/* @ts-expect-error old Expandables */}
                <Expandables
                  showChevron
                  expandedIndex={0}
                  key={`error-user-${index}`}
                >
                  {error}
                </Expandables>
              </>
            ))}
          </ErrorContainer>
        </>
      )}

      {errorGroups.renderErrorsForSupport.length > 0 && (
        <>
          <SubTitle>
            Problembehebung durch unseren Support erforderlich
            <SubtitleDescription>
              Melde diese Probleme beim Support
            </SubtitleDescription>
          </SubTitle>
          <ErrorContainer>
            {errorGroups.renderErrorsForSupport.map((error, index) => (
              <>
                {/* @ts-expect-error old Expandables */}
                <Expandables
                  showChevron
                  expandedIndex={0}
                  key={`error-support-${index}`}
                >
                  {error}
                </Expandables>
              </>
            ))}
          </ErrorContainer>
        </>
      )}
    </>
  );
}
