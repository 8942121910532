/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import { Button, Link, ErrorAlert } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { merge } from 'lodash';

import { FlexRow } from '../layout';
import { FormikSubmit } from '../input';
import { FormFlowStep } from '../FormFlow';
import ErrorMsg from '../errorMsg';
import WithErrorsStepper from '../stepper/withErrorsStepper';
import { ValidationError } from '../../graphql-types';

import { submit } from './submit';
import { GraphqlFormContext } from './hooks/useGraphqlForm';
import { ActionBtns } from './style';
import { __DEV__ } from './isDev';
import { buildNestedYupSchema } from './validation';
import { useIntrospection } from './hooks/useIntrospection';
import { IbanExistsErrorClass } from './types';

import { GraphQlFormProps } from '.';

interface GraphqlMultiStepFormProps<ValuesTypes>
  extends Omit<GraphQlFormProps<ValuesTypes>, 'children'> {
  steps: FormFlowStep[];
  forceStep?: number | null;
  children?: React.ReactNode;
  hideCancel?: boolean;
  /**
   * @deprecated Title is no longer used
   */
  // eslint-disable-next-line react/no-unused-prop-types
  title?: string;
}

const Wrapper = styled.div`
  // width: 1024px;
`;

const Status = styled(FlexRow)`
  align-items: center;
`;

const Content = styled.div`
  // min-height: 600px;
`;

const AlertLink = styled(Link)`
  color: inherit;
`;

type FormValidationErrorProps = {
  stepLabel: string;
  fieldLabel: string;
  onClick: () => void;
};

function FormValidationError({
  stepLabel,
  fieldLabel,
  onClick,
}: FormValidationErrorProps) {
  return (
    <ErrorAlert>
      Im Schritt{' '}
      <AlertLink role="button" tabIndex={0} onClick={onClick}>
        {stepLabel}
      </AlertLink>{' '}
      ist das Feld <strong>{fieldLabel}</strong> fehlerhaft.
    </ErrorAlert>
  );
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
function GraphqlMultiStepForm<ValuesTypes extends unknown>({
  steps,
  mutation,
  values,
  defaultValues: userDefaultValues,
  formVariables,
  variables,
  onAbort,
  onSuccess,
  isLoading: isUserLoading,
  labels,
  readDocument,
  readDocumentFields,
  refetchQueries,
  validation,
  formatBeforeSubmit,
  hideCancel = false,
  onError: onErrorCallback,
  forceStep,
  children,
}: GraphqlMultiStepFormProps<ValuesTypes>) {
  const {
    isLoading: isIntrospectionLoading,
    validationSchema,
    initialValues,
    getFieldProps,
    fieldProps,
    schema,
    defaultValues,
  } = useIntrospection(mutation, values);
  const isLoading = isUserLoading || isIntrospectionLoading;
  const client = useApolloClient();
  const [stepIndex, setStepIndex] = useState(0);
  const [mutationError, setMutationError] = useState<string[] | null>(null);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  } | null>(null);
  const fieldsStepMap: { [key: string]: number } = useMemo(() => ({}), []);
  const renderedFields: { [key: string]: Set<string> } = useMemo(() => {
    return steps.reduce(
      (acc, step, index) => ({
        ...acc,
        [index]: new Set(),
      }),
      {},
    );
  }, [steps]);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (forceStep) {
      setStepIndex(forceStep);
    }
  }, [forceStep]);

  const registerField = useCallback(
    (fieldName: string) => {
      renderedFields[stepIndex].add(fieldName);
      fieldsStepMap[fieldName] = stepIndex;
    },
    [fieldsStepMap, renderedFields, stepIndex],
  );

  const unregisterField = useCallback(
    (fieldName: string) => {
      renderedFields[stepIndex].delete(fieldName);
    },
    [renderedFields, stepIndex],
  );

  const getFieldPropsProxy = useCallback(
    (fieldName: string) => {
      // if (!renderedFields[stepIndex].has(fieldName)) {
      //   renderedFields[stepIndex].add(fieldName);
      // }
      return getFieldProps(fieldName);
    },
    [getFieldProps],
  );

  /**
   * formik provides `isSubmitting` on his own but is not fast enough
   * in updating it which can result in double submits
   */
  const [isSubmitting, setSubmitting] = useState(false);

  const onValidate = async (newValues: any) => {
    const fields = Array.from(renderedFields[stepIndex]);
    const errors: { [key: string]: string } = {};

    for (const fieldName of fields) {
      try {
        await yupSchema.validateAt(fieldName, newValues, {
          context: newValues,
        });
      } catch (err) {
        errors[fieldName] = (err as any).message;
      }
    }

    return errors;
  };

  const onSubmit = useCallback(
    async (
      newValues: any,
      { setTouched, setSubmitting: setFormikSubmitting, setStatus }: any,
    ) => {
      setTouched({});

      if (stepIndex + 1 !== steps.length) {
        setSubmitting(false);
        setFormikSubmitting(false);
        return setStepIndex(stepIndex + 1);
      }

      if (!fieldProps || !initialValues) {
        return;
      }

      if (isSubmitting) {
        return;
      }

      setSubmitting(true);
      setFormikSubmitting(true);
      setValidationErrors(null);

      const formattedValues = formatBeforeSubmit
        ? formatBeforeSubmit(newValues)
        : newValues;

      try {
        const { data } = await submit({
          newValues: formattedValues,
          values,
          initialValues,
          fieldProps,
          refetchQueries,
          mutation,
          schema,
          readDocument,
          readDocumentFields,
          client,
          variables,
        });

        if (isMounted.current) {
          setFormikSubmitting(false);
          setSubmitting(false);
        }

        if (onSuccess) {
          const keys = Object.keys(data);

          if (keys.length === 0) {
            throw new Error('No key found');
          }

          onSuccess(data[keys[0]], {
            values: formattedValues,
          });
        }
      } catch (error) {
        if (__DEV__) {
          console.error(error);
        }
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        if (isMounted.current) {
          setFormikSubmitting(false);
          setSubmitting(false);
        }
        if (
          Array.isArray(error) &&
          error[0]?.__typename === 'ValidationError'
        ) {
          const formattedErrors = (error as any).reduce(
            (
              acc: { [field: string]: string },
              { pointer, message }: ValidationError,
            ) => {
              return { ...acc, [pointer]: message };
            },
            {},
          );

          setValidationErrors(formattedErrors);
          setTouched({});
          setStatus(formattedErrors);
        } else if (error instanceof IbanExistsErrorClass) {
          setMutationError([error.message]);
        } else {
          setMutationError(error as string[]);
        }
      }
    },
    [
      client,
      fieldProps,
      initialValues,
      isSubmitting,
      mutation,
      onSuccess,
      readDocument,
      readDocumentFields,
      refetchQueries,
      schema,
      stepIndex,
      steps.length,
      values,
      variables,
      formatBeforeSubmit,
      onErrorCallback,
    ],
  );

  const yupSchema = useMemo(
    () => buildNestedYupSchema(merge({}, validationSchema, validation)),
    [validation, validationSchema],
  );

  const onPrevStep = useCallback(() => {
    if (stepIndex === 0) {
      if (onAbort) onAbort();
      return;
    }

    setStepIndex(stepIndex - 1);
    // warn about losing data
  }, [onAbort, stepIndex]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formatError = (_errors: FormikErrors<{ [key: string]: any }>) => {
    // console.log(errors);
  };

  const stepperSteps: any = useMemo(
    () =>
      steps.map((s: FormFlowStep) => ({
        label: s.title,
      })),
    [steps],
  );

  const renderTestState = (
    _isSubmitting: boolean,
    isValid: boolean,
    hasErrors: boolean,
  ) => {
    if (process.env.NODE_ENV !== 'test') return null;
    const getState = () => {
      if (isLoading) {
        return 'is-loading';
      }

      if (_isSubmitting) {
        return 'is-submitting';
      }

      if (!isValid || hasErrors) {
        return 'is-invalid';
      }

      return 'is-ready';
    };

    return getState();
  };

  const formikInitialValues = useMemo(() => {
    return merge({}, initialValues, defaultValues, userDefaultValues);
  }, [initialValues, defaultValues, userDefaultValues]);

  const isLastStep = stepIndex === steps.length - 1;

  const hideCancelButton = stepIndex === 0 && hideCancel;

  const contextValue = React.useMemo(
    () => ({
      getFieldProps: getFieldPropsProxy,
      isEditing: true,
      isLoading,
      formVariables,
      registerField,
      unregisterField,
    }),
    [
      formVariables,
      getFieldPropsProxy,
      isLoading,
      registerField,
      unregisterField,
    ],
  );

  return (
    <Wrapper>
      <Formik
        initialValues={formikInitialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validate={onValidate}
      >
        {({ isSubmitting: _isSubmitting, errors, isValid }: any) => (
          <Form autoComplete="off">
            <>
              <WithErrorsStepper
                steps={stepperSteps}
                index={stepIndex}
                fieldsStepMap={fieldsStepMap}
                onStepClick={setStepIndex}
              />
              <Content>
                {mutationError && (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {Array.isArray(mutationError) ? (
                      mutationError.map((error) => (
                        <ErrorMsg
                          key={error}
                          title="Fehler"
                          error={error}
                          retryable={false}
                        />
                      ))
                    ) : (
                      <ErrorMsg
                        title="Fehler"
                        message={String(mutationError)}
                        retryable={false}
                      />
                    )}
                  </>
                )}
                {validationErrors &&
                  isLastStep &&
                  Object.keys(validationErrors).map((error) => {
                    const index = fieldsStepMap[error];
                    if (!index) return null;

                    const stepLabel = stepperSteps[index]?.label;
                    const fieldLabel = getFieldPropsProxy(error)?.label;

                    if (!stepLabel || !fieldLabel) return null;

                    return (
                      <FormValidationError
                        key={error}
                        stepLabel={stepLabel}
                        fieldLabel={fieldLabel}
                        onClick={() => setStepIndex(index)}
                      />
                    );
                  })}
                {/* <SubTitle> {steps[stepIndex].title}</SubTitle> */}
                <>{Object.values(errors).length !== 0 && formatError(errors)}</>
                <GraphqlFormContext.Provider value={contextValue}>
                  {steps[stepIndex].content}
                </GraphqlFormContext.Provider>
              </Content>
              <Status>
                <ActionBtns>
                  {!hideCancelButton ? (
                    <Button
                      onClick={onPrevStep}
                      disabled={_isSubmitting}
                      secondary
                      data-testid="graphql-form-abort"
                    >
                      {stepIndex === 0 ? 'Abbrechen' : 'Zurück'}
                    </Button>
                  ) : null}
                  <FormikSubmit data-testid="graphql-form-submit">
                    {isLastStep ? labels?.submit ?? 'Speichern' : 'Weiter'}
                  </FormikSubmit>
                </ActionBtns>
              </Status>
              <span
                key="test-id"
                data-testid={`graphql-form-${renderTestState(
                  _isSubmitting,
                  isValid,
                  Object.values(errors).length !== 0,
                )}`}
              />
            </>
            {children}
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default GraphqlMultiStepForm;
