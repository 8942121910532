import React from 'react';
import ContentLoader from 'react-content-loader';
import {
  Headline as HeadlineComponent,
  List,
  Section,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

import NewsItem, {
  DashboardNewsFeedContentType,
  LIST_PAGE_SIZE,
} from './newsItem';

const Headline = styled(HeadlineComponent)`
  text-transform: none !important;
  letter-spacing: unset !important;
  margin-bottom: 10px;
`;

export default function DashboardNewsFeed({
  latestNews,
  oldNews,
  isLoading = true,
}: {
  latestNews?: DashboardNewsFeedContentType[];
  oldNews?: DashboardNewsFeedContentType[];
  isLoading: boolean;
}) {
  const news = latestNews?.concat(oldNews || []);
  return (
    <>
      {isLoading ? (
        <Section>
          <ContentLoader
            id="content-loader-table"
            height={250}
            speed={2}
            style={{
              width: '100%',
            }}
          >
            <rect x="0" y="0" rx="0" ry="0" width="140" height="17" />
            <rect x="0" y="30" rx="0" ry="0" width="100%" height="19" />
            <rect x="0" y="75" rx="0" ry="0" width="400" height="2" />

            <rect x="0" y="102" rx="0" ry="0" width="140" height="17" />
            <rect x="0" y="132" rx="0" ry="0" width="100%" height="19" />
            <rect x="0" y="175" rx="0" ry="0" width="400" height="2" />

            <rect x="0" y="202" rx="0" ry="0" width="140" height="17" />
            <rect x="0" y="232" rx="0" ry="0" width="100%" height="19" />
          </ContentLoader>
        </Section>
      ) : (
        <Section>
          {/* @ts-expect-error old Headline */}
          <Headline title>Was gibt&apos;s neues?</Headline>
          {/* @ts-expect-error old Headline */}
          <List withPagination pageSize={LIST_PAGE_SIZE}>
            {news?.map((newsItem, index) => {
              const currNewsItem = {
                id: newsItem?.id,
                title: newsItem?.title,
                type: newsItem?.type,
                createdAt: newsItem?.createdAt,
                body: newsItem.body,
              };
              return (
                <NewsItem
                  content={{ index, currNewsItem, topicsLength: news.length }}
                  key={currNewsItem.id}
                />
              );
            })}
          </List>
        </Section>
      )}
    </>
  );
}
