import {
  Bold,
  CheckboxSimple,
  RadioButton,
  RadioGroup,
} from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import {
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../components/graphql-form/render';
import { SetStateAction } from '../../../helpers/stateHelpers';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { Grid } from '../../../components/layout';

const Headline = styled(Bold)`
  padding: 10px 0 6px;
  font-size: 15px;
`;

const AdditionalPersonWrapper = styled.div<{ $isEditing?: boolean }>`
  display: grid;
  grid-auto-rows: max-content;
  gap: ${({ $isEditing }) => ($isEditing ? `0.5rem` : `0`)};
`;

type CreateAdditionalPersonFormProps = {
  fieldNamePrefix: string;
  billingCustomerAdditionalEntityFieldsState: SetStateAction<boolean>;
  billingCustomerAdditionalPersonState: SetStateAction<boolean>;
};

export function CreateAdditionalPersonForm({
  fieldNamePrefix,
  billingCustomerAdditionalEntityFieldsState,
  billingCustomerAdditionalPersonState,
}: CreateAdditionalPersonFormProps) {
  const { setFieldValue } = useFormikContext();

  const [showAdditionalPersonFields, setShowAdditionalPersonFields] =
    billingCustomerAdditionalEntityFieldsState;

  const [isSetToPerson, setToPerson] = billingCustomerAdditionalPersonState;

  const resetAdditionalFieldsFn = () => {
    setFieldValue(`${fieldNamePrefix}.additionalNamePrefix`, '', false);
    setFieldValue(`${fieldNamePrefix}.additionalName`, '', false);
    setFieldValue(`${fieldNamePrefix}.companySecondaryFlag`, '', false);
  };

  if (!fieldNamePrefix?.includes('addressBilling')) {
    return null;
  }

  return (
    <AdditionalPersonWrapper>
      <CheckboxSimple
        checked={showAdditionalPersonFields}
        label="zusätzlicher Rechnungsadressat"
        id={`${fieldNamePrefix}.additionalPersonFields-name`}
        onChange={(checked) => {
          if (!checked) {
            resetAdditionalFieldsFn();
          }
          setShowAdditionalPersonFields(checked);
        }}
      />
      {showAdditionalPersonFields && (
        <>
          <CompanyToggle
            fieldNamePrefix={fieldNamePrefix}
            isAdditionalPerson={isSetToPerson}
            setAdditionalPerson={setToPerson}
            onChange={(_isSetToPerson) => {
              resetAdditionalFieldsFn();
              setFieldValue(
                `${fieldNamePrefix}.companySecondaryFlag`,
                !_isSetToPerson,
                false,
              );
            }}
          />
          {isSetToPerson ? (
            <AdditionalPerson fieldNamePrefix={fieldNamePrefix} />
          ) : (
            <AdditionalCompany fieldNamePrefix={fieldNamePrefix} />
          )}
        </>
      )}
    </AdditionalPersonWrapper>
  );
}

type UpdateAdditionalPersonFormProps = {
  fieldNamePrefix: string;
  isEditing: boolean;
};

export function UpdateAdditionalPersonForm({
  fieldNamePrefix,
  isEditing,
}: UpdateAdditionalPersonFormProps) {
  const { setFieldValue, initialValues } = useFormikContext();
  const initialPersonValues = get(initialValues, `${fieldNamePrefix}`);
  const initiallyWasPerson = !initialPersonValues?.companySecondaryFlag;

  const [isSetToPerson, setToPerson] = useState(initiallyWasPerson);

  useEffect(() => {
    setToPerson(initiallyWasPerson);
  }, [initiallyWasPerson, setToPerson]);

  useEffect(() => {
    if (!isEditing) {
      setToPerson(initiallyWasPerson);
      setFieldValue(
        `${fieldNamePrefix}.companySecondaryFlag`,
        !initiallyWasPerson,
        false,
      );
      setFieldValue(
        `${fieldNamePrefix}.additionalNamePrefix`,
        initialPersonValues?.additionalNamePrefix,

        false,
      );
      setFieldValue(
        `${fieldNamePrefix}.additionalName`,
        initialPersonValues?.additionalName,

        false,
      );
    }
  }, [
    fieldNamePrefix,
    initialPersonValues?.additionalName,
    initialPersonValues?.additionalNamePrefix,
    initiallyWasPerson,
    isEditing,
    setFieldValue,
  ]);

  const resetAdditionalPersonFields = React.useCallback(
    (_isSetToPerson: boolean) => {
      resetAdditionalFields({
        setFieldValue,
        fieldNamePrefix,
        isSetToPerson: _isSetToPerson,
        initiallyWasPerson,
        additionalNamePrefix: initialPersonValues?.additionalNamePrefix,
        additionalName: initialPersonValues?.additionalName,
      });
    },
    [
      setFieldValue,
      fieldNamePrefix,
      initiallyWasPerson,
      initialPersonValues?.additionalNamePrefix,
      initialPersonValues?.additionalName,
    ],
  );

  if (!fieldNamePrefix?.includes('addressBilling')) {
    return null;
  }

  return (
    <AdditionalPersonWrapper>
      <Headline>Zusätzlicher Rechnungsadressat</Headline>
      {isEditing && (
        <CompanyToggle
          fieldNamePrefix={fieldNamePrefix}
          isAdditionalPerson={isSetToPerson}
          setAdditionalPerson={setToPerson}
          onChange={(value) => resetAdditionalPersonFields(value)}
        />
      )}
      {isSetToPerson ? (
        <AdditionalPerson fieldNamePrefix={fieldNamePrefix} />
      ) : (
        <AdditionalCompany fieldNamePrefix={fieldNamePrefix} />
      )}
    </AdditionalPersonWrapper>
  );
}

function CompanyToggle({
  fieldNamePrefix,
  isAdditionalPerson,
  setAdditionalPerson,
  onChange,
}: {
  fieldNamePrefix: string;
  isAdditionalPerson: boolean;
  setAdditionalPerson: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (value: boolean) => void;
}) {
  return (
    <RadioGroup
      direction="row"
      id={`${fieldNamePrefix}.companySecondaryFlag`}
      name={`${fieldNamePrefix}.companySecondaryFlag`}
      value={!isAdditionalPerson}
      onChange={(value) => {
        setAdditionalPerson(value === 'false');
        onChange(value === 'false');
      }}
    >
      <RadioButton label="Person" value={false} />
      <RadioButton label="Firma" value />
    </RadioGroup>
  );
}

function AdditionalPerson({ fieldNamePrefix }: { fieldNamePrefix: string }) {
  const { isEditing } = useGraphqlForm();

  const content = useMemo(
    () => (
      <>
        <GraphqlFormField
          id={`${fieldNamePrefix}.additionalNamePrefix`}
          data-testid={`${fieldNamePrefix}.additionalNamePrefix`}
          name={`${fieldNamePrefix}.additionalNamePrefix`}
          label={null}
          renderEmptyString={false}
        />
        <GraphqlFormField
          name={`${fieldNamePrefix}.additionalName`}
          label={null}
          renderEmptyString={false}
        />
      </>
    ),
    [fieldNamePrefix],
  );

  if (isEditing) {
    return <Grid>{content}</Grid>;
  }

  return <GraphqlFormInputGroup>{content}</GraphqlFormInputGroup>;
}

function AdditionalCompany({ fieldNamePrefix }: { fieldNamePrefix: string }) {
  return (
    <Grid>
      <GraphqlFormField
        name={`${fieldNamePrefix}.additionalName`}
        label={null}
        placeholder="Firmenname"
      />
    </Grid>
  );
}

type ResetAdditionalFieldsInput = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  fieldNamePrefix: string;
  isSetToPerson: boolean;
  initiallyWasPerson: boolean;
  additionalNamePrefix: string;
  additionalName: string;
};

function resetAdditionalFields({
  setFieldValue,
  fieldNamePrefix,
  isSetToPerson,
  initiallyWasPerson,
  additionalNamePrefix,
  additionalName,
}: ResetAdditionalFieldsInput) {
  setFieldValue(
    `${fieldNamePrefix}.companySecondaryFlag`,
    !isSetToPerson,
    false,
  );

  if (initiallyWasPerson && isSetToPerson) {
    setFieldValue(
      `${fieldNamePrefix}.additionalNamePrefix`,
      additionalNamePrefix,
      false,
    );
    setFieldValue(`${fieldNamePrefix}.additionalName`, additionalName, false);
  } else if (!initiallyWasPerson && !isSetToPerson) {
    setFieldValue(`${fieldNamePrefix}.additionalNamePrefix`, '', false);
    setFieldValue(`${fieldNamePrefix}.additionalName`, additionalName, false);
  } else {
    setFieldValue(`${fieldNamePrefix}.additionalNamePrefix`, '', false);
    setFieldValue(`${fieldNamePrefix}.additionalName`, '', false);
  }
}
