import { Button } from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';

import { Flex, ErrorMsg } from '../../../../components';
import GraphqlForm from '../../../../components/graphql-form';
import {
  GraphqlFormField,
  GraphqlFormSelect,
} from '../../../../components/graphql-form/render';
import { ReadMetadataDocument } from '../../../../graphql-types';
import mutationDeleteMetadata from '../../../../queries/mutationDeleteMetadata';
import { EditContainerProps } from '../../../../components/createFlow';

const ButtonStyled = styled(Button)`
  margin-top: 10px;
`;

export default function EditMetadata({
  onSuccess,
  onAbort,
  variables,
}: EditContainerProps) {
  const client = useApolloClient();
  const [mutationError, setMutationError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const deleteMetadata = useCallback(async () => {
    setIsLoading(true);
    try {
      const result: any = await client.mutate({
        mutation: mutationDeleteMetadata,
        variables: {
          contractLabel: variables.contractLabel,
          customerLabel: variables.customerLabel,
          metadata: {
            key: variables.oldValues.key,
            value: variables.oldValues.value,
          },
        },
        refetchQueries: [
          {
            query: ReadMetadataDocument,
            variables: {
              contractLabel: variables.contractLabel,
            },
          },
        ],
      });
      variables.performedActionState[1]('deleted');
      if (onSuccess) onSuccess(result, { values: {} });
      setMutationError(null);
    } catch (err) {
      if (err instanceof Error) {
        setMutationError(err);
      } else {
        setMutationError(new Error('Unknown error'));
      }
    }

    setIsLoading(false);
  }, [client, variables, onSuccess]);

  return (
    <GraphqlForm
      mutation="updateMetadata"
      startInEdit
      defaultValues={{
        key: variables.oldValues.key,
        value: variables.oldValues.value,
      }}
      onSuccess={onSuccess}
      onAbort={onAbort}
      refetchQueries={[
        {
          query: ReadMetadataDocument,
          variables: {
            contractLabel: variables.contractLabel,
          },
        },
      ]}
      variables={{
        contractLabel: variables.contractLabel,
        customerLabel: variables.customerLabel,
      }}
      readDocument={ReadMetadataDocument}
    >
      {mutationError && <ErrorMsg error={mutationError} />}
      <GraphqlFormSelect name="key" label="Schlüssel" disabled>
        <option disabled value={variables.oldValues.key}>
          {variables.oldValues.key}
        </option>
      </GraphqlFormSelect>
      <GraphqlFormField name="value" label="Wert" placeholder="Wert" />
      <Flex>
        <ButtonStyled
          onClick={() => deleteMetadata()}
          secondary
          isLoading={isLoading}
          data-testid={`delete-metadata-${isLoading}`}
          id={`delete-metadata-${isLoading}`}
        >
          Wertpaar löschen
        </ButtonStyled>
      </Flex>
    </GraphqlForm>
  );
}
