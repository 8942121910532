import {
  AlertRetryable,
  Header,
  Icons,
  List,
  ListItem,
  Main,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';

import { DocTitle } from '../docTitle';
import { useGetAllScheduleRequestsResultsQuery } from '../../graphql-types';

import {
  getIconColor,
  getJobIcon,
  translateJobName,
} from './taskManagerHelper';

export function TasksStatus() {
  const navigate = useNavigate();

  const { data, error, loading, refetch } =
    useGetAllScheduleRequestsResultsQuery();

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const jobs = React.useMemo(() => {
    if (
      data?.getAllScheduleRequestsResults?.__typename ===
      'MultipleScheduleRequestsSuccess'
    ) {
      const addedDataSourceData =
        data.getAllScheduleRequestsResults.results.map((result) => {
          return { ...result };
        });
      return [addedDataSourceData]
        .flat()
        .sort(
          (a, b) =>
            new Date(b.startedOn!).getTime() - new Date(a.startedOn!).getTime(),
        );
    }
  }, [data]);

  const errorHandler = React.useCallback(() => {
    if (error) {
      return <AlertRetryable error={error} />;
    }
    if (data?.getAllScheduleRequestsResults?.__typename === 'JobNotFound') {
      return <AlertRetryable message="Job nicht gefunden" />;
    }
    if (
      data?.getAllScheduleRequestsResults?.__typename ===
      'ScheduleRequestsError'
    ) {
      return (
        <AlertRetryable message="Beim Laden des Jobs ist ein Fehler aufgetreten" />
      );
    }
    return null;
  }, [data, error]);

  return (
    <>
      <DocTitle titleParts={['Taskmanager']} />
      <Main>
        {/* @ts-expect-error old Header */}
        <Header Icon={Icons.Time} hideBackground>
          Taskmanager
        </Header>
        {errorHandler()}
        {/* @ts-expect-error old List */}
        <List header="Tasks">
          {jobs &&
            jobs?.map((result) => {
              const hasOperationError = result?.result?.some(
                (res) => res.data.__typename === 'OperationError',
              );

              const color = getIconColor(result.status, hasOperationError);
              const jobName = translateJobName(result.jobName);

              return (
                <>
                  {/* @ts-expect-error old ListItem */}
                  <ListItem
                    Icon={getJobIcon(result.status, hasOperationError)}
                    iconProps={{
                      color,
                      size: 30,
                    }}
                    timestamp={
                      result.startedOn ? new Date(result.startedOn) : undefined
                    }
                    title={result.jobLabel ?? jobName}
                    onClick={() => navigate(`/tasks/${result.jobId}`)}
                    key={result.jobId}
                  >
                    {result.progress}/{result.total} {jobName} verarbeitet
                  </ListItem>
                </>
              );
            })}
          {loading ? <LoadingItems /> : null}
        </List>
      </Main>
    </>
  );
}

function LoadingItems() {
  return (
    <ContentLoader width="100%" height="260">
      <circle cx="30" cy="20" r="12" />
      <rect x="60" y="0" width="200" height="20" />
      <rect x="60" y="24" width="40" height="16" />

      <circle cx="30" cy="90" r="12" />
      <rect x="60" y="70" width="220" height="20" />
      <rect x="60" y="94" width="40" height="16" />

      <circle cx="30" cy="160" r="12" />
      <rect x="60" y="140" width="180" height="20" />
      <rect x="60" y="164" width="40" height="16" />

      <circle cx="30" cy="230" r="12" />
      <rect x="60" y="210" width="240" height="20" />
      <rect x="60" y="234" width="40" height="16" />
    </ContentLoader>
  );
}
