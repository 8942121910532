import React, { useEffect, useState } from 'react';
import styled, { withTheme, CSSProperties } from 'styled-components';
import { useLocation, Link, matchPath } from 'react-router-dom';

import { Themeprops } from '../themeProvider';
import Ripples from '../ripple';
import useClickAndKeyPressTracking from '../../helpers/useClickAndKeyPressTracking';

const rippleStyle: CSSProperties = {
  backgroundColor: '#fff',
  border: 'none',
  fontWeight: 'bold',
  fontSize: '13px',
  padding: '14px',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  borderRadius: '4px',
  color: '#4C4C4C',
};

const Wrapper = styled.div`
  margin: 5px 10px;
  cursor: pointer;
`;

interface NavigationItemProps {
  icon?: any;
  label: any;
  href?: string;
  onClick?: () => any;
  isActive?: boolean;
  onlyIconActive?: boolean;
  exact?: boolean;
}

function NavigationItem(props: NavigationItemProps & Themeprops) {
  const { icon, theme, href, onClick, label, isActive, onlyIconActive, exact } =
    props;

  const location = useLocation();
  const IconComp = icon;

  const checkMatch = () => {
    if (href && location.pathname === href) return true;
    if (isActive) return true;
    if (href && location.pathname.startsWith(href) && href !== '/') return true;
    return null;
  };
  const macthingRoute = checkMatch();

  const nextStyle = {
    color: theme.primaryColor,
    backgroundColor: theme.secondaryColor,
  };

  const style = {
    ...rippleStyle,
    ...(macthingRoute !== null && !onlyIconActive ? nextStyle : {}),
  };

  const inner = (
    <Ripples style={style} nextStyle={nextStyle} persist>
      <IconComp
        size="25"
        color={macthingRoute !== null ? theme.primaryColor : '#666666'}
      />
      {/* <Label>{label}</Label> */}
    </Ripples>
  );

  const handleEvent = useClickAndKeyPressTracking(
    'sidebar-click',
    {
      sidebarLabel: label,
    },
    onClick,
  );

  return (
    <Wrapper>
      {onClick ? (
        <div
          onClick={handleEvent}
          title={label}
          onKeyPress={handleEvent}
          role="button"
          tabIndex={0}
          id={`sidebar-button-${label}`}
        >
          {inner}
        </div>
      ) : (
        <Link
          to={href ?? ''}
          title={label}
          onClick={handleEvent}
          id={`sidebar-button-${label}`}
        >
          {inner}
        </Link>
      )}
    </Wrapper>
  );
}

export default withTheme(NavigationItem);
