import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import { Bold, Hint } from '../../../../../components';
import { EditContainerProps } from '../../../../../components/createFlow';
import GraphqlForm from '../../../../../components/graphql-form';
import {
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../../../components/graphql-form/render';
import { StyledLink } from '../../../../../components/graphql-form/style';
import {
  ReadContractCancelationDateQueryVariables,
  ReadContractDocument,
  ReadContractFastDocument,
  ReadContractQuery,
  TerminateContractDocument,
  useReadContractCancelationDateQuery,
} from '../../../../../graphql-types';
import { formatDate } from '../../../../../helpers/formatStrings';
import { yupUTCDate } from '../../../../../helpers/yupUTCDate';

const Headline = styled(Bold)`
  margin-top: 10px;
  font-size: 15px;
`;

const HintStyled = styled(Hint)`
  display: block;
  width: 430px;
`;

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error.color};
`;

interface SingleCustomerTerminationFormProps {
  tariffLink: string;
  variables: ReadContractCancelationDateQueryVariables;
  updateEndDate: (endDate: string) => void;
}

function SingleCustomerTerminationForm({
  tariffLink,
  variables,
  updateEndDate,
}: SingleCustomerTerminationFormProps) {
  const [hintMessage, setHintMessage] = useState<string | null>(null);
  const [isEarlyCancelation, setIsEarlyCancelation] = useState(false);

  const { setFieldValue, getFieldProps } = useFormikContext<{
    endDate: string | null;
    cancelationDate: string | null;
  }>();

  const { data: cancelationDate, loading: cancelationDateLoading } =
    useReadContractCancelationDateQuery({
      variables: {
        ...variables,
        ...(getFieldProps('cancelationDate').value && {
          noticeDate: DateTime.fromJSDate(
            new Date(getFieldProps('cancelationDate').value),
          )
            .endOf('day')
            .toJSDate()
            .toISOString(),
        }),
      },
      skip:
        !variables.contractId ||
        !variables.tariffId ||
        !getFieldProps('cancelationDate').value,
    });

  const contractEndDate = useMemo(
    () => cancelationDate?.readContractCancelationDate,
    [cancelationDate?.readContractCancelationDate],
  );

  useEffect(() => {
    if (cancelationDate?.readContractCancelationDate) {
      const date = DateTime.fromISO(
        cancelationDate?.readContractCancelationDate,
      )
        .toUTC()
        .startOf('day')
        // we make sure that is the middle of the day so that we can avoid most of the timezone issues
        .plus({ hours: 12 })
        .toISO();
      setFieldValue('endDate', date);
      updateEndDate(date);
    }
  }, [
    cancelationDate?.readContractCancelationDate,
    setFieldValue,
    updateEndDate,
  ]);

  useEffect(() => {
    if (!contractEndDate || !getFieldProps('endDate').value) return;

    setIsEarlyCancelation(
      DateTime.fromISO(getFieldProps('endDate').value).toUTC().endOf('day') <
        DateTime.fromISO(contractEndDate).toUTC().endOf('day'),
    );
  }, [contractEndDate, getFieldProps]);

  useEffect(() => {
    if (cancelationDateLoading) return;

    if (contractEndDate && getFieldProps('cancelationDate').value) {
      setHintMessage(
        `Wird der Vertrag zum ${formatDate(
          getFieldProps('cancelationDate').value,
          { utc: false },
        )} gekündigt, ist das nächstmögliche Vertragsende zum ${formatDate(
          contractEndDate,
          { utc: true },
        )}. `,
      );
    } else if (!contractEndDate) {
      setHintMessage(null);
    }
  }, [cancelationDateLoading, contractEndDate, getFieldProps]);

  return (
    <>
      <Headline>Kündigungsgrund</Headline>
      Aus welchem Grund wird der Vertrag mit dem Mieter gekündigt?
      <div>
        <GraphqlFormField name="cancelationReason" label={null} />
      </div>
      <GraphqlFormInputGroup>
        <GraphqlFormField name="cancelationDate" />
        <GraphqlFormField name="endDate" />
      </GraphqlFormInputGroup>
      <HintStyled>
        {hintMessage ||
          (!getFieldProps('cancelationDate').value ? (
            <ErrorMessage>{`Bitte wähle ein gültiges Datum, damit die Kündigung durchgeführt werden kann. `}</ErrorMessage>
          ) : (
            `Das nächstmögliche Enddatum für den Vertrag kann nicht berechnet werden. `
          ))}
        <>
          Die Details zu den Mindestlaufzeiten und Kündigungsfristen findest du
          am verknüpften <StyledLink to={tariffLink}>Tarif</StyledLink>.
        </>
      </HintStyled>
      <HintStyled>
        {isEarlyCancelation &&
          `Es handelt sich um eine vorzeitige Kündigung, weswegen der Grund hierfür im folgenden Feld beschrieben werden muss.`}
      </HintStyled>
      <GraphqlFormField name="earlyTerminationReason" />
    </>
  );
}

interface SingleCustomerTerminationProps extends EditContainerProps {
  variables?: {
    contract: ReadContractQuery['readContract'];
    isLoading: boolean;
  };
}

function SingleCustomerTermination({
  variables,
  onSuccess,
  onAbort,
}: SingleCustomerTerminationProps) {
  const location = useLocation();

  // TODO: How can we get rid of `!`?
  const tariff = variables?.contract?.tariffs?.[0];
  const tariffId = tariff?.tariffId;
  const tariffLink = `${location.pathname}/tariff/${tariffId}`;

  const [endDate, setEndDate] = useState<string | undefined | null>(null);

  const contractId = useMemo(
    () => variables?.contract?.id,
    [variables?.contract?.id],
  );
  const currentDate = useMemo(() => DateTime.now().toISODate(), []);

  const queryVariables = useMemo(
    () => ({
      contractId: contractId!,
      tariffId: tariffId!,
    }),
    [contractId, tariffId],
  );

  return (
    <GraphqlForm
      mutation="terminateContract"
      variables={{
        contractId: variables?.contract?.id,
        contractLabel: variables?.contract?.label,
        currentDate,
      }}
      refetchQueries={[
        {
          query: ReadContractDocument,
          variables: {
            contractId,
          },
        },
        {
          query: ReadContractFastDocument,
          variables: {
            contractId,
            contractLabel: variables?.contract?.label,
          },
        },
      ]}
      values={{
        reason: 'Auszug',
        cancelationDate: currentDate,
      }}
      readDocument={TerminateContractDocument}
      startInEdit
      onSuccess={onSuccess}
      onAbort={onAbort}
      labels={{
        submit: 'Kündigen',
      }}
      isLoading={variables?.isLoading}
      validation={{
        earlyTerminationReason: yup
          .string()
          .when('endDate', (formEndDate: Date, schema: any) => {
            if (!endDate || !formEndDate) return schema;

            if (
              DateTime.fromJSDate(formEndDate).endOf('day') <
              DateTime.fromISO(endDate).endOf('day')
            ) {
              return schema.required();
            }
          }),
        cancelationDate: checkDateIsBiggerThanStartDate(
          variables?.contract?.startDate,
        ).required(),

        endDate: checkDateIsBiggerThanStartDate(
          variables?.contract?.startDate,
        ).required(),
      }}
    >
      <SingleCustomerTerminationForm
        tariffLink={tariffLink}
        variables={queryVariables}
        updateEndDate={setEndDate}
      />
    </GraphqlForm>
  );
}

const checkDateIsBiggerThanStartDate = (isoStartDate: string) =>
  yupUTCDate.test(
    'is-after-start-date',
    'Das Datum muss nach dem Startdatum liegen',
    (value) => {
      if (value) {
        const startDate = DateTime.fromISO(isoStartDate);
        const endDateToTest = DateTime.fromJSDate(value);
        return endDateToTest > startDate;
      }
      return true;
    },
  );

export default SingleCustomerTermination;
