import React from 'react';
import styled, { withTheme, ThemeProps } from 'styled-components';

import { Theme } from './themeProvider';
import { IconProps } from './baseIcon';
import { FlexRow } from './layout';

const Wrap = styled(FlexRow)`
  align-items: center;
`;

const Head = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  font-size: 15px;
`;

const Childs = styled.div`
  margin-top: 12px;
`;

const IconWrap = styled.div`
  display: flex;
  margin-right: 5px;
`;

function Relation({
  icon,
  children,
  title,
  className,
  color,
  theme,
}: {
  icon: React.ComponentType<IconProps>;
} & React.PropsWithChildren &
  ThemeProps<Theme> & {
    title: string;
    className?: string;
    color?: string;
  }) {
  const Icon = icon;

  return (
    <Wrap className={`${children ? 'has-childs' : ''} ${className ?? ''}`}>
      <IconWrap>
        <Icon color={color || theme.primaryColor} size={16} />
      </IconWrap>
      <Head style={{ color: color || theme.primaryColor }}>{title}</Head>
      {children && <Childs>{children}</Childs>}
    </Wrap>
  );
}

export default withTheme(Relation);
