import * as React from 'react';
import styled from 'styled-components/macro';
import {
  Button,
  ButtonGroup,
  ButtonIcon,
  TextareaCounter,
  Icons,
} from '@ampeersenergy/ampeers-ui-components';
import { err } from 'neverthrow';

import { PreviewContentProps } from '../../../../components/contract-documents/createMultipleDocumentsModal';
import { PdfPreview } from '../../../../components/contract-documents/steps/PdfPreview';
import { CreationResult } from '../../../../components/contract-documents/types';

export const PricesheetChangeContext = React.createContext<{
  reason: string;
  updateReason: (value: string) => void;
}>({
  reason: '',
  updateReason: () => {},
});

export const usePricesheetReason = () =>
  React.useContext(PricesheetChangeContext);

const reasons = {
  energyPrices:
    'die Energiemärkte spielen verrückt und wir stecken mittendrin. Die Beschaffungspreise von Strom sind enorm gestiegen. Obgleich wir viel Strom über langjährige Lieferverträge eingekauft haben, schlagen sich die extrem hohen Kosten für den noch zu beschaffenden Teil auch in unserem Preis für Ihren Tarif nieder. Glücklicherweise beziehen Sie einen großen Anteil Ihres Stroms vom Dach. Damit ändert sich der Preis für den Lokalstrom nicht und die Preisanpassung fällt merklich geringer aus als bei Anbietern, die Ihnen reinen Netzstrom anbieten.',
  networkCharges:
    'auch in diesem Jahr haben die Netzbetreiber die Preise für die Netznutzungsentgelte erhöht. Diese Preiserhöhung schlägt sich auch auf Ihren Tarif nieder. Glücklicherweise beziehen Sie einen großen Anteil Ihres Stroms vom Dach und profitieren somit von der „Strompreisbremse“. Da sich die Netznutzungsentgelte nur auf Ihren Reststrom aus dem öffentlichen Netz beziehen ändert sich der Bezugspreis für den Lokalstrom nicht und die Preisanpassung fällt merklich geringer aus als bei Anbietern, die Ihnen reinen Netzstrom anbieten.',
};

const PreviewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

const PreviewHeadline = styled.h4`
  margin-bottom: 8px;
  margin-top: 20px;
`;

type PricesheetChangePreviewProps = PreviewContentProps;

export default function PricesheetChangePreview({
  previewDescription,
  onNext,
  onPreview,
}: PricesheetChangePreviewProps) {
  const { reason, updateReason } = usePricesheetReason();
  const [warning, setWarning] = React.useState('');

  const [previewResult, setPreviewResult] = React.useState<CreationResult>();
  const [isLoading, setIsLoading] = React.useState(false);

  const loadPreview = React.useCallback(
    async (_reason?: string) => {
      setIsLoading(true);
      try {
        const result = await onPreview({ reason: _reason });
        setPreviewResult(result);
      } catch (error) {
        setPreviewResult(
          err({
            reason: (error as Error).toString(),
          }),
        );
      }
      setIsLoading(false);
    },
    [onPreview],
  );

  React.useEffect(() => {
    loadPreview(reason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPreview]);

  React.useEffect(() => {
    if (reason && warning) {
      setWarning('');
    }
  }, [reason, warning]);

  const updatePreview = React.useCallback(() => {
    if (reason) {
      loadPreview(reason);
    }
  }, [reason, loadPreview]);

  const handleNext = React.useCallback(() => {
    if (!reason && reason.length === 0) {
      setWarning('Bitte gib einen Grund an.');
    } else {
      onNext();
    }
  }, [onNext, reason]);

  const buttonDisabled = !reason;

  return (
    <PreviewContainer>
      <div>
        <h3>Grund der Preisanpassung</h3>
        <p>
          Beschreibe den Grund für die Preisanpassung. Der Text wird dann an der
          entsprechenden Stelle im Template eingesetzt. Du kannst unsere
          Textvorschläge als Ausgangpunkt nutzen:
        </p>
        <ButtonGroup>
          <Button
            secondary
            small
            onClick={() => updateReason(reasons.networkCharges)}
          >
            Gestiegene Netzengelte
          </Button>
          <Button
            secondary
            small
            onClick={() => updateReason(reasons.energyPrices)}
          >
            Gestiegene Energiepreise
          </Button>
        </ButtonGroup>
        <TextareaCounter
          characterThreshold={100}
          id="pricechangeReason"
          data-testid="pricechangeReason"
          warning={warning || undefined}
          maxLength={550}
          placeholder="Grund der Preisanpassung"
          value={reason}
          onChange={(e) => updateReason(e.target.value)}
          isEditing
          css={`
            margin-top: 1rem;
          `}
        />
        {/* @ts-expect-error old ButtonIcon */}
        <ButtonIcon
          disabled={buttonDisabled}
          secondary
          icon={Icons.Refresh}
          onClick={() => updatePreview()}
          css={`
            margin-top: -2rem;
          `}
        >
          Vorschau
        </ButtonIcon>
      </div>
      <div>
        {previewDescription && (
          <PreviewHeadline>{previewDescription}</PreviewHeadline>
        )}
        <PdfPreview
          isLoading={isLoading}
          fileSrc={
            previewResult?.isOk() ? previewResult.value.fileURL : undefined
          }
          error={
            previewResult?.isErr() ? previewResult.error.reason : undefined
          }
          dimensions={{ width: '100%', height: 650 }}
          onNext={handleNext}
        />
      </div>
    </PreviewContainer>
  );
}
