import React from 'react';
import styled from 'styled-components';

import {
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../../../components/graphql-form/render';
import GraphqlForm from '../../../../../components/graphql-form';
import { PlantDetailDocument } from '../../../../../graphql-types';
import { EditContainerProps } from '../../../../../components/createFlow';

const FieldGroup = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const FixedWidthWhileEdit = styled.div`
  .is-editing & {
    display: flex;
    width: initial !important;
  }
`;

function NewAddress({ onSuccess, onAbort, variables }: EditContainerProps) {
  return (
    <GraphqlForm
      mutation="createPlantAdditionalAddress"
      startInEdit
      variables={{
        plantId: parseFloat(variables.plantId),
      }}
      disableEdit={false}
      onSuccess={onSuccess}
      onAbort={onAbort}
      refetchQueries={[
        {
          query: PlantDetailDocument,
          variables: {
            plantId: parseFloat(variables.plantId),
          },
        },
      ]}
      readDocument={PlantDetailDocument}
    >
      <FieldGroup>
        <GraphqlFormInputGroup>
          <GraphqlFormField name="address.streetName" />
          <FixedWidthWhileEdit>
            <GraphqlFormField name="address.streetNumber" />
          </FixedWidthWhileEdit>
        </GraphqlFormInputGroup>
        <GraphqlFormInputGroup>
          <GraphqlFormField name="address.zipCode" />
          <GraphqlFormField name="address.city" />
          <GraphqlFormField name="address.country" />
        </GraphqlFormInputGroup>
      </FieldGroup>
    </GraphqlForm>
  );
}

export default NewAddress;
