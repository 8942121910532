import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import { useLazyQuery } from '@apollo/client';

import { Entry } from '../../components';
import GraphqlForm from '../../components/graphql-form';
import { EditContainerProps } from '../../components/createFlow';
import { GraphqlFormField } from '../../components/graphql-form/render';
import {
  formatBoolean,
  formatDate,
  formatDecimal,
} from '../../helpers/formatStrings';
import {
  ReadTariffDocument,
  PriceSheet,
  UpdatePriceSheetDocument,
  CheckDeletePriceSheetQuery,
  CheckDeletePriceSheetQueryVariables,
  CheckDeletePriceSheetDocument,
} from '../../graphql-types';
import { FlexRow, Flex } from '../../components/layout';
import { LOCKING_FEATURE_START_DATE } from '../../helpers/constants';

import { DeletePriceSheetModal } from './deletePriceSheetModal';

export default function EditPricesheet({
  onSuccess,
  onAbort,
  variables,
  refetch,
}: EditContainerProps) {
  const { tariffId } = useParams<{ tariffId: string }>();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isPriceSheetCheckSuccess, setIsPriceSheetCheckSuccess] =
    useState(false);

  const invalidNames = useMemo(() => {
    if (!variables) return [];

    return variables.tariff.priceSheets
      .filter((p: PriceSheet) => p.name !== variables.priceSheet.name)
      .map((p: PriceSheet) => p.name);
  }, [variables]);
  const isFirstPriceSheet =
    variables.tariff.priceSheets?.[0].id === variables.priceSheet.id;
  const [checkDeletePriceSheet] = useLazyQuery<
    CheckDeletePriceSheetQuery,
    CheckDeletePriceSheetQueryVariables
  >(CheckDeletePriceSheetDocument);

  useEffect(() => {
    if (!isFirstPriceSheet) {
      return setIsPriceSheetCheckSuccess(false);
    }
    const checkPriceSheet = async () => {
      try {
        const checkResponse = await checkDeletePriceSheet({
          variables: {
            id: variables.priceSheet.id,
          },
        });
        const checkSuccess = checkResponse.data?.checkDeletePriceSheet?.success;
        setIsPriceSheetCheckSuccess(checkSuccess ?? false);
      } catch (error) {
        console.error('Error checking price sheet:', error);
      }
    };

    checkPriceSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables.priceSheet.id]);

  return (
    <FlexRow
      style={{
        alignItems: 'flex-end',
        gap: '2px',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <GraphqlForm
        values={variables.priceSheet}
        mutation="updatePriceSheet"
        readDocument={UpdatePriceSheetDocument}
        startInEdit={false}
        onSuccess={onSuccess}
        onAbort={onAbort}
        refetchQueries={[
          {
            query: ReadTariffDocument,
            variables: { tariffId: [tariffId] },
          },
        ]}
        variables={{
          tariffId,
          priceSheetId: variables.priceSheet.id,
        }}
        validation={{
          name: yup
            .mixed()
            .notOneOf(
              invalidNames,
              'Es gibt bereits ein Preisblatt mit diesem Namen',
            )
            .required(),
        }}
      >
        <FlexRow>
          <Flex>
            <GraphqlFormField name="name" autoFocus />
            <Entry title="Grundpreis (Netto)" unit="€/Monat">
              {formatDecimal(variables.priceSheet.basicPrice)}
            </Entry>
            {variables.priceSheet.energyPrice === undefined ||
            variables.priceSheet.energyPrice === null ? (
              <>
                <Entry title="Arbeitspreis Lokalstrom (Netto)" unit="Cent/kWh">
                  {formatDecimal(variables.priceSheet.energyPriceLocal)}
                </Entry>
                <Entry title="Arbeitspreis Reststrom (Netto)" unit="Cent/kWh">
                  {formatDecimal(variables.priceSheet.energyPriceResidual)}
                </Entry>
              </>
            ) : (
              <Entry title="Arbeitspreis gemischt (Netto)" unit="Cent/kWh">
                {formatDecimal(variables.priceSheet.energyPrice)}
              </Entry>
            )}
            <Entry title="Beginn der Preisblattgültigkeit">
              {formatDate(variables.priceSheet.startDate)}
            </Entry>
            <Entry title="Bestehende Preisgarantien ignorieren">
              {formatBoolean(variables.priceSheet.ignorePriceGuarantee)}
            </Entry>
          </Flex>
        </FlexRow>
      </GraphqlForm>

      {variables.priceSheet?.createdAt &&
        isFirstPriceSheet &&
        isPriceSheetCheckSuccess &&
        new Date(variables.priceSheet.createdAt) >
          LOCKING_FEATURE_START_DATE && (
          <Button
            data-testid="graphql-form-delete"
            secondary
            onClick={() => setDeleteModalIsOpen(true)}
          >
            Löschen
          </Button>
        )}

      <DeletePriceSheetModal
        priceSheet={variables.priceSheet}
        isOpen={deleteModalIsOpen}
        onClose={() => {
          refetch?.();
          setDeleteModalIsOpen(false);
        }}
      />
    </FlexRow>
  );
}
