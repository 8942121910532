import React from 'react';
import styled from 'styled-components';
import {
  ColumnDefinition,
  FlexRow,
  SubTitle,
  Table,
} from '@ampeersenergy/ampeers-ui-components';

import { LegendItem } from '../../../../components';
import {
  formatDate,
  formatSettlementDay,
} from '../../../../helpers/formatStrings';
import { InvoiceDraftsContainer } from '../../oldWorkflow/invoiceDrafts/invoiceDraftsContainer';
import {
  AccountingRunMeta,
  AccountingRunWorkflowStep,
  AccountingRunWorkflowStepName,
  InvoiceCycle,
  OpcStepResult,
  OverviewStepResult,
} from '../../../../graphql-types';
import { getLabelForStepName } from '../accountingRunHelper';

import { SummaryBarChart } from './SummaryBarChart';

const Margin = styled.div`
  margin-top: 100px;
  margin-bottom: 40px;
`;

const SummaryTable = styled(Table)`
  margin-bottom: 20px;
`;

const FixedLabel = styled.div`
  width: 200px;
`;

const Row = styled(FlexRow)`
  align-items: baseline;
  margin-top: 19px;
  font-size: 14px;
`;

const StepSum = styled.div`
  color: #848484;
  font-size: 14px;
  padding-left: 10px;
  width: 80px;
  text-align: right;
`;

const LegendWrap = styled(FlexRow)`
  justify-content: flex-end;
`;

const SubsectionTitle = styled(SubTitle)`
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
`;

const BarsWrapper = styled.div`
  padding: 10px;
`;

interface WorkflowSummaryProps {
  startedAt?: string;
  doneAt?: string;
  meta?: AccountingRunMeta;
  steps: AccountingRunWorkflowStep[];
}

export function FinalStep({
  startedAt,
  doneAt,
  meta,
  steps,
}: WorkflowSummaryProps) {
  const columns: ColumnDefinition<any>[] = [
    {
      Header: 'Beginn Rechnungslauf',
      accessor: 'startedAt',
      type: 'date',
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: 'Abschluss Rechnungslauf',
      accessor: 'doneAt',
      type: 'date',
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: 'Rechnungsperiode',
      accessor: 'paymentPeriod',
      Cell: (props) =>
        `${formatDate(props.value?.startAt)} - ${formatDate(
          props.value?.endAt,
        )}`,
      sortType: (rowA, rowB, columnId, desc) => {
        if (desc) {
          return (
            Date.parse(rowA.values[columnId].endAt) -
            Date.parse(rowB.values[columnId].endAt)
          );
        }
        return (
          Date.parse(rowA.values[columnId].startAt) -
          Date.parse(rowB.values[columnId].startAt)
        );
      },
    },
    {
      Header: 'Abrechnungsstichtag',
      accessor: 'settlementDate',
      Cell: (props) => {
        return formatSettlementDay(props.value?.day, props.value?.month);
      },
      sortType: (rowA, rowB, columnId) =>
        Date.parse(
          `${rowA.values[columnId].month}/${
            rowA.values[columnId].day
          }/${new Date().getFullYear()}`,
        ) -
        Date.parse(
          `${rowB.values[columnId].month}/${
            rowB.values[columnId].day
          }/${new Date().getFullYear()}`,
        ),
    },
  ];

  const tableData = [
    {
      startedAt,
      doneAt,
      paymentPeriod: {
        startAt: meta?.paymentPeriodStartAt,
        endAt: meta?.paymentPeriodEndAt,
      },
      settlementDate: {
        day: meta?.settlementDay,
        month: meta?.settlementMonth,
      },
    },
  ];

  const barCharts = React.useMemo(() => {
    let allContracts = 0;
    const overviewStep = steps.find(
      (s) => s.name === AccountingRunWorkflowStepName.OverviewStep,
    );
    if (overviewStep?.result?.__typename === 'OverviewStepResult') {
      allContracts +=
        overviewStep.result.validContracts.length +
        overviewStep.result.invalidContracts.length;
    }
    const rows: React.ReactNode[] = [];

    steps.forEach((step, index) => {
      let succeeded = 0;
      let failed = 0;

      switch (step.result?.__typename) {
        case 'OverviewStepResult':
          succeeded = step.result.validContracts?.length ?? 0;
          failed = step.result.invalidContracts?.length ?? 0;
          break;
        case 'ValidateContractConsumptionsStepResult':
          succeeded =
            step.result.contracts?.filter(
              (c) => !c?.errors || c.errors.length === 0,
            ).length ?? 0;
          failed =
            step.result.contracts?.filter(
              (c) => c?.errors && c.errors.length > 0,
            ).length ?? 0;
          break;
        case 'OpcStepResult':
          succeeded = step.result?.passed?.length ?? 0;
          failed = step.result?.errored?.length ?? 0;
          break;
        default:
          return;
      }

      const stepTotal = failed + succeeded;

      rows.push(
        <Row key={step.name}>
          <FixedLabel>{getLabelForStepName(step.name)}</FixedLabel>
          <SummaryBarChart
            total={allContracts}
            stepTotal={stepTotal}
            stepSuccess={succeeded}
            stepFailures={failed}
            index={index}
          />
          <StepSum>{stepTotal} (100%)</StepSum>
        </Row>,
      );
    });

    return rows;
  }, [steps]);

  const opcName =
    (
      steps.find((step) => step.name === AccountingRunWorkflowStepName.OpcStep)
        ?.result as OpcStepResult
    )?.name ?? '';
  const session =
    (
      steps.find(
        (step) => step.name === AccountingRunWorkflowStepName.OverviewStep,
      )?.result as OverviewStepResult
    )?.session ?? '';

  return (
    <>
      <SummaryTable isLoading={false} columns={columns} data={tableData} />

      <BarsWrapper>
        <SubsectionTitle>Abrechnungsphasen</SubsectionTitle>
        <LegendWrap>
          <LegendItem color="#7795F4">Korrekt</LegendItem>
          <LegendItem color="#13238E">Fehlerhaft</LegendItem>
          <LegendItem color="#ACACAC">Eingangsrechnungen</LegendItem>
        </LegendWrap>
        {barCharts}
      </BarsWrapper>

      <Margin>
        <InvoiceDraftsContainer
          accountingType={meta?.accountingType ?? ''}
          accountingName={opcName}
          uuid={session}
          paymentPeriodStart={meta?.paymentPeriodStartAt}
          paymentPeriodEnd={meta?.paymentPeriodEndAt}
          invoiceCycle={meta?.invoiceCycle ?? InvoiceCycle.Yearly}
        />
      </Margin>
    </>
  );
}
