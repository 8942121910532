import {
  Document,
  Documents,
  DocumentType,
  Icons,
  IFrameModal,
  ShadowBox,
  SubTitle,
  ButtonIcon,
  FeatureAnnouncement,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import { documentTypesI18nToKey } from '../helpers/formatStrings';

import { CreateDocumentModal } from './contract-documents/createSingleDocumentModal';
import DocumentUpload from './document-upload';
import { useFileUploadWrapper } from './document-upload/UploadDropzone';

type ViewDocument = { fileURL: string; type: string } | null;

const Wrapper = styled(ShadowBox)`
  padding: 0px 20px;
  margin-left: 10px;
  width: 380px;
  margin-right: 17px;
`;

const DocumentHeadline = styled.div`
  display: flex;
  align-items: center;
`;

export function DocumentsSidebar({
  documentTypes,
  contractId,
  customerEmail,
  documentDeliveryMethod,
  documentInitiallyShown,
  previewDocumentMutation,
  generateDocumentMutation,
  onDelete,
  className,
  allowUpload,
  refetchDocuments,
}: {
  documentTypes: DocumentType[];
  contractId?: string;
  customerEmail?: string | null;
  documentDeliveryMethod?: string;
  documentInitiallyShown?: string | null;
  previewDocumentMutation?: React.ComponentProps<
    typeof CreateDocumentModal
  >['previewDocumentMutation'];
  generateDocumentMutation?: React.ComponentProps<
    typeof CreateDocumentModal
  >['generateDocumentMutation'];
  onDelete?: (document: Document) => void;
  className?: string;
  allowUpload?: boolean;
  refetchDocuments?: () => void;
}) {
  const [viewDocument, setViewDocument] = useState<ViewDocument>(null);
  const [modalOpen, setCreateModalOpen] = useState(
    Boolean(documentInitiallyShown),
  );
  const [type, setType] = useState<null | string>(
    documentInitiallyShown || null,
  );

  const onCreate = useCallback(
    (_type: string) => {
      setType(documentTypesI18nToKey[_type]);
      setCreateModalOpen(true);
    },
    [setCreateModalOpen],
  );

  const onOpen = useCallback(
    (fileURL: string, _type: string) => {
      const url = new URL(fileURL);
      url.searchParams.set('attachment', 'false');
      setViewDocument({ fileURL: url.toString(), type: _type });
    },
    [setViewDocument],
  );

  const { file, openFileDialog, isFileDialogActive } = useFileUploadWrapper();

  return (
    <>
      {allowUpload && (
        <DocumentUpload
          open={!!file}
          contractId={contractId}
          onSuccess={refetchDocuments}
        />
      )}
      <Wrapper className={className}>
        <DocumentHeadline>
          <SubTitle>Dokumente</SubTitle>
          {allowUpload && (
            <FeatureAnnouncement
              name="document-upload-announcement"
              id="document-upload-announcement"
              text="Neu: Eigene Dokumente hochladen ✨"
              expiresAt={new Date('2022-04-01')}
              css={`
                z-index: 9 !important;
              `}
            >
              {/* @ts-expect-error old ButtonIcon */}
              <ButtonIcon
                data-tip
                data-for="document-upload-announcement"
                icon={Icons.Upload}
                onClick={() => openFileDialog()}
                disabled={isFileDialogActive}
                secondary
                css={`
                  margin-left: 1rem;
                `}
              />
            </FeatureAnnouncement>
          )}
        </DocumentHeadline>
        <Documents
          types={documentTypes}
          onCreate={onCreate}
          onOpen={onOpen}
          onDelete={onDelete}
        />
        {modalOpen &&
          contractId &&
          documentDeliveryMethod &&
          previewDocumentMutation &&
          generateDocumentMutation && (
            <CreateDocumentModal
              type={type!}
              onClose={() => setCreateModalOpen(false)}
              customerEmail={customerEmail!}
              contractId={contractId}
              documentDeliveryMethod={documentDeliveryMethod}
              previewDocumentMutation={previewDocumentMutation}
              generateDocumentMutation={generateDocumentMutation}
            />
          )}
        {viewDocument !== null && (
          <IFrameModal
            src={viewDocument.fileURL}
            contentLabel={`Show ${viewDocument.type}`}
            closeModal={() => setViewDocument(null)}
            title={viewDocument.type}
          />
        )}
      </Wrapper>
    </>
  );
}
