import {
  Icons,
  Label,
  ShadowBox,
  FlexRow,
  ButtonIcon,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import styled from 'styled-components';

import {
  CreateMeterInput,
  MeterType,
  MsoType,
} from '../../../../graphql-types';
import { CreateMeterForm } from '../../meter/CreateMeterForm';

const IconWrap = styled.div`
  margin-right: 5px;
  display: inline-block;
`;

const MeterWrap = styled(ShadowBox)`
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 3px;

  & > label {
    margin-bottom: -7px;
  }
`;

const Header = styled(FlexRow)`
  justify-content: space-between;
`;

const getLabel = (m: CreateMeterInput, prefixGeneration = false) => {
  switch (m.meterType) {
    case MeterType.MsoH:
      return 'Z1 - Hausanschluss';
    case MeterType.MsoE:
      if (!prefixGeneration) {
        return 'ZN - Erzeugung';
      }

      return m.msoType === MsoType.E1 ? 'Z2 - Erzeugung' : 'Z3 - Erzeugung';
    case MeterType.MsoK:
      return 'Z4 - Kaskade';
    default:
      return '';
  }
};

export function MeasurementMeter({
  meter,
  name,
  deleteable = false,
  onDelete,
  meteringDisabled,
  prefixGeneration,
}: {
  meter: CreateMeterInput;
  name: string;
  deleteable?: boolean;
  onDelete: () => void;
  meteringDisabled: boolean;
  prefixGeneration: boolean;
}) {
  return (
    <MeterWrap>
      <Header>
        <Label>
          <IconWrap>
            <Icons.Meter size={18} color="#545454" />
          </IconWrap>
          {getLabel(meter, prefixGeneration)}
        </Label>
        {deleteable && (
          <>
            {/* @ts-expect-error old ButtonIcon */}
            <ButtonIcon
              icon={Icons.Delete}
              onClick={onDelete}
              small
              secondary
              color="#E30045"
            />
          </>
        )}
      </Header>
      <CreateMeterForm
        fieldNamePrefix={name}
        meteringDisabled={meteringDisabled}
        showMeterType={false}
      />
    </MeterWrap>
  );
}
