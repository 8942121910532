import React from 'react';
import styled from 'styled-components';
import {
  Table,
  Tabs,
  Tab,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';

import { useReadContractExcerptsQuery } from '../../../graphql-types';

type ResultData = {
  name: string;
  customerLabel: string;
  label: string;
  error?: any;
};

export const columns: ColumnDefinition<ResultData>[] = [
  {
    Header: 'Mieter',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'KundenNr.',
    accessor: 'customerLabel',
    type: 'string',
  },
  {
    Header: 'VertragsNr.',
    accessor: 'label',
    type: 'string',
  },
];

const columnsWithError: ColumnDefinition<ResultData>[] = [
  ...columns,
  {
    Header: 'Fehler',
    accessor: 'error',
    type: 'string',
  },
];

const Spacer = styled.div`
  margin-top: 10px;
`;

export function OPCResult({
  succeeded,
  failed,
}: {
  succeeded: { id: number }[];
  failed: { id: number; error: string }[];
}) {
  const { data } = useReadContractExcerptsQuery({
    variables: {
      ids: [
        ...succeeded.map(({ id }: any) => id),
        ...failed.map(({ id }: any) => id),
      ],
    },
  });

  const mapper = ({ id, error }: any) => {
    if (data?.readContractExcerpts) {
      const contract = data.readContractExcerpts.find(
        (c) => parseInt(c.id, 10) === id,
      );

      if (contract) {
        return {
          ...contract,
          error,
        };
      }
    }

    return {
      label: id,
      name: '',
      customerLabel: '',
      meterNumber: '',
      startDate: '',
      endDate: '',
      contractStatus: '',
      error: '',
      projectId: null,
      plantId: null,
    };
  };

  return (
    <Tabs>
      {/* @ts-expect-error old Tab */}
      <Tab title={`${succeeded.length} Erfolgreich`}>
        <Spacer>
          <Table
            columns={columns}
            data={succeeded.map(mapper)}
            filterKind="Erfolgreiche Verträge"
            isLoading={false}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
      {/* @ts-expect-error old Tab */}
      <Tab
        title={`${failed.length} Fehlerhaft`}
        hasNotification={failed.length !== 0}
      >
        <Spacer>
          <Table
            columns={columnsWithError}
            data={failed.map(mapper)}
            filterKind="Fehlerhafte Verträge"
            isLoading={false}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
    </Tabs>
  );
}
