import * as React from 'react';
import {
  Button,
  ColumnDefinition,
  Tab,
  Table,
  Tabs,
} from '@ampeersenergy/ampeers-ui-components';
import 'styled-components/macro';
import { DateTime } from 'luxon';

import {
  JsonBox,
  OperationResult,
  OperationResultContent,
} from '../../../graphql-types';
import { UploadModal } from '../../importer/uploadModal';
import CSVService from '../../../services/csv';

interface ContractDocumentsProps {
  results: OperationResult[];
}

function isJSONBox(data: OperationResultContent): data is JsonBox {
  return data.__typename === 'JSONBox';
}

export function ContractImportResults({ results }: ContractDocumentsProps) {
  const [uploadModalOpen, setUploadModalOpen] = React.useState(false);

  const successfullResults = results
    .map((result) => result.data)
    .filter(isJSONBox)
    .map((data) => {
      return {
        contractLabel: data.value?.contractLabel ?? '',
        customerLabel: data.value?.customerLabel ?? '',
      };
    });
  const failedResults = results
    .filter((result) => result.data.__typename === 'OperationError')
    .map((result) => {
      if (result.data.__typename !== 'OperationError') {
        return null;
      }
      return {
        contractLabel: result.id,
        reason: result.data.reason,
      };
    });

  const succeededCount = successfullResults.length;
  const failedCount = failedResults.length;

  const columns: ColumnDefinition[] = [
    {
      Header: 'Kunden-Nr.',
      accessor: 'customerLabel',
    },
    {
      Header: 'Vertrags-Nr.',
      accessor: 'contractLabel',
    },
  ];

  const errorColumns: ColumnDefinition[] = [
    {
      Header: 'Vertrags-Nr.',
      accessor: 'contractLabel',
    },
    {
      Header: 'Fehler',
      accessor: 'reason',
    },
  ];

  const downloadImportOverview = React.useCallback(() => {
    const fields = ['customerLabel', 'contractLabel', 'status', 'date'];
    const headers = ['Kunden-Nr.', 'Vertrags-Nr.', 'Status', 'Datum'];
    const date = DateTime.utc().setLocale('de').toLocal();
    const fileNameDate = date.toFormat('dd-MM-yyyy-HH-mm');
    const fileName = `${fileNameDate}_Importlog_Vertraege.csv`;

    const csvDate = date.toFormat('dd.MM.yyyy');

    const succeeded =
      successfullResults?.map((result) => ({
        customerLabel: result?.customerLabel,
        contractLabel: result?.contractLabel,
        status: 'success',
        date: csvDate,
      })) ?? [];

    const failed =
      failedResults.map((result) => ({
        customerLabel: '',
        contractLabel: result?.contractLabel,
        status: result?.reason,
        date: csvDate,
      })) ?? [];

    return CSVService.downloadCSV([...succeeded, ...failed], {
      fields,
      headers,
      fileName,
    });
  }, [failedResults, successfullResults]);

  return (
    <>
      <div css="display: flex; justify-content: flex-end; gap: 10px">
        <Button secondary onClick={() => setUploadModalOpen(true)}>
          Neuer Import
        </Button>
        <Button secondary onClick={() => downloadImportOverview()}>
          Download Import Übersicht
        </Button>
      </div>
      <Tabs>
        {/* @ts-expect-error old Tab */}
        <Tab title={`${succeededCount} erfolgreich`}>
          <Table
            isLoading={false}
            data={successfullResults}
            columns={columns}
          />
        </Tab>
        {/* @ts-expect-error old Tab */}
        <Tab title={`${failedCount} fehlerhaft`}>
          <Table
            isLoading={false}
            data={failedResults}
            columns={errorColumns}
          />
        </Tab>
      </Tabs>

      {uploadModalOpen && <UploadModal setIsOpen={setUploadModalOpen} />}
    </>
  );
}
