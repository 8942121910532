import React, { useCallback, useState } from 'react';
import {
  Modal,
  Button,
  AlertRetryable,
  FlexRow,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { ApolloError } from '@apollo/client';

import {
  useDeleteOdooContractMutation,
  DeleteOdooContractMutation,
} from '../../../../graphql-types';

const Buttons = styled(FlexRow)`
  margin-top: 10px;

  > :first-child {
    margin-right: 5px;
  }
`;

const Wrapper = styled.div`
  width: 400px;
  margin-top: 8px;
`;

/**
 * this component handles the cleanup of previously
 * generated contracts in case the user wants creation to
 * leave the creation flow
 */
const DiscardChangesDialog: React.FC<{
  contractIdToDiscard: string;
  afterDiscard: () => void;
  close: () => void;
}> = ({ close, afterDiscard, contractIdToDiscard }) => {
  const [mutate] = useDeleteOdooContractMutation();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<{
    type: DeleteOdooContractMutation['deleteOdooContract']['__typename'];
  } | null>(null);
  const [deleteAttempt, setDeleteAttempt] = useState(false);

  const deleteContract = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await mutate({
        variables: {
          id: contractIdToDiscard,
        },
      });
      switch (data?.deleteOdooContract.__typename) {
        case 'SuccessResponse':
          setError(null);
          close();
          afterDiscard();
          break;

        case 'OdooContractNotFoundError':
        case 'OdooContractPartnerAssignedToOtherContractError':
        case 'UnknownError':
          setDeleteAttempt(true);
          setError({
            type: data?.deleteOdooContract.__typename,
          });
          break;
        default:
          break;
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        setError({
          type: (err.graphQLErrors[0] as any)?.extensions?.exception.name,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [mutate, contractIdToDiscard, close, afterDiscard]);

  return (
    <Modal
      isOpen
      contentLabel="Änderungen verwerfen"
      title="Änderungen verwerfen"
      onRequestClose={() => {
        close();
        if (deleteAttempt) {
          afterDiscard();
        }
      }}
    >
      <Wrapper>
        {error !== null && (
          <AlertRetryable
            message={`Verwerfen der Messkonzept-Verträge fehlgeschlagen (${error.type}). Bitte wende Dich an unseren Support.`}
            retryable={false}
          />
        )}
        {!deleteAttempt && (
          <>
            Wenn Du die Bearbeitung an dieser Stelle abbrichst, werden alle
            Vertragsdaten verworfen.
            <Buttons>
              <Button onClick={() => close()} disabled={isLoading}>
                Bearbeitung Fortsetzen
              </Button>
              <Button
                secondary
                onClick={deleteContract}
                isLoading={isLoading}
                data-test-id="discard"
              >
                Verwerfen
              </Button>
            </Buttons>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default DiscardChangesDialog;
