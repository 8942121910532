/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Filter,
  FilterBar,
  Flex,
  FlexRow,
  Modal,
  SubTitle,
  Table,
} from '@ampeersenergy/ampeers-ui-components';
import { DeepExtractType } from 'ts-deep-extract-types';

import {
  ReadPlantMetersQuery,
  useReadPlantMetersQuery,
} from '../../graphql-types';
import { EMPTY_STR, formatString } from '../../helpers/formatStrings';
import { ErrorMsg } from '../../components';

import { AddMeterContainer } from './addMeterContainer';

type ContractMeterExcerptType = DeepExtractType<
  ReadPlantMetersQuery,
  ['readPlantMeters', 'matchedTenantMeters']
>[0];

const AlignCenter = styled.div`
  align-self: center;
`;

const AddMeterButton = styled(Button)`
  margin-left: 6px;
`;

const widths: { [key: string]: number | string } = {
  'ZählerNr.': '8%',
  Zählerplatz: '10%',
  MeLo: '20%',
  Mieter: '16%',
  Betreiber: '20%',
  Zählertyp: '8%',
  'VertragsNr.': '13%',
};

const C = (Header: string, accessor: string) => ({
  Header,
  accessor,
  ...(widths[Header] && { width: widths[Header] }),
});
const baseColumnOrder = [
  'ZählerNr.',
  'Zählerplatz',
  'VertragsNr.',
  'MeLo',
  'Mieter',
  'Zählertyp',
];
const _commonMappings: { [key: string]: string } = {
  'ZählerNr.': 'meterNumber',
  Zählerplatz: 'meterPlace',
  MeLo: 'melo',
  Mieter: 'name',
  Betreiber: 'name',
  Zählertyp: 'meterType',
  'VertragsNr.': 'contractLabel',
};
const _measurementMappings: { [key: string]: string } = {
  'ZählerNr.': 'meterNumber',
  Zählerplatz: 'meterPlace',
  MeLo: 'melo',
  Zählertyp: 'meterType',
};
const consumptionColumns = baseColumnOrder.map((_) => C(_, _commonMappings[_]));
const generationColumns = baseColumnOrder.map((_) =>
  _ === 'Mieter'
    ? C('Betreiber', _commonMappings.Betreiber)
    : C(_, _commonMappings[_]),
);
const measurementColumns = baseColumnOrder.map((_) =>
  _ === 'Mieter'
    ? C('Betreiber', { ..._commonMappings, ..._measurementMappings }.Betreiber)
    : C(_, { ..._commonMappings, ..._measurementMappings }[_]),
);
const nondelivedColumns = measurementColumns.filter(
  ({ Header }) => Header !== 'Betreiber',
);

function MeterList({ plantId }: { plantId: string }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const location = useLocation();
  const { data, error, loading } = useReadPlantMetersQuery({
    variables: {
      plantId,
    },
  });
  const navigate = useNavigate();

  const onRowClick = (row: any) => {
    if (row.original.id) navigate(`${location.pathname}/${row.original.id}`);
  };

  const arrayOfMeterNumbers = useMemo(() => {
    if (data) {
      return [
        ...(data?.readPlantMeters.matchedBuildingMeters.map(
          (m: ContractMeterExcerptType) => m.meterNumber,
        ) ?? {}),
        ...(data?.readPlantMeters.unmatchedMeters.map(
          (m: ContractMeterExcerptType) => m.meterNumber,
        ) ?? {}),
        ...(data?.readPlantMeters.matchedTenantMeters.map(
          (m: ContractMeterExcerptType) => m.meterNumber,
        ) ?? {}),
      ];
    }
    return [];
  }, [data]);

  const { tenantMeters, nondelivedMeters, generationMeters } = useMemo(() => {
    const meters: {
      tenantMeters: ContractMeterExcerptType[];
      nondelivedMeters: ContractMeterExcerptType[];
      generationMeters: ContractMeterExcerptType[];
    } = {
      tenantMeters: [],
      nondelivedMeters: [],
      generationMeters: [],
    };

    if (data) {
      meters.tenantMeters = data?.readPlantMeters.matchedTenantMeters ?? [];

      const tenantMeterNumbers = meters.tenantMeters.map(
        (m: ContractMeterExcerptType) => m.meterNumber,
      );

      meters.generationMeters =
        data?.readPlantMeters.matchedBuildingMeters ?? [];

      meters.nondelivedMeters =
        data?.readPlantMeters.unmatchedMeters
          .filter((m) => {
            // remove meters that are currently assigned to a tenant
            return !tenantMeterNumbers.includes(m.meterNumber);
          })
          .reduce(
            (
              acc: ContractMeterExcerptType[],
              cur: ContractMeterExcerptType,
            ) => {
              // remove duplicate meters
              return acc.find((m) => m.meterNumber === cur.meterNumber)
                ? acc
                : [...acc, cur];
            },
            [],
          )
          .map((c) => ({
            ...c,
            melo: formatString(c.melo),
            customerFormatted: EMPTY_STR,
            customerLabel: EMPTY_STR,
          })) ?? [];
    }

    return meters;
  }, [data]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Create-Meter-Modal"
        title="Zähler hinzufügen"
      >
        <AddMeterContainer
          onDone={() => setModalIsOpen(false)}
          existingMeterNumbers={arrayOfMeterNumbers}
          plantId={plantId}
        />
      </Modal>
      <FilterBar
        filters={filters}
        columns={consumptionColumns}
        setAllFilters={setFilters}
        kind="Zähler"
      />
      {error && <ErrorMsg error={error} />}
      <FlexRow>
        <Flex>
          <SubTitle>Mieterzähler</SubTitle>
        </Flex>
        <AlignCenter>
          <AddMeterButton
            id="add-meter-btn"
            onClick={() => setModalIsOpen(true)}
          >
            Zähler hinzufügen
          </AddMeterButton>
        </AlignCenter>
      </FlexRow>
      <Table
        data={tenantMeters}
        columns={consumptionColumns}
        filterKind="Zähler"
        // rowLink={rowLink}
        onRowClick={onRowClick}
        filterState={filters}
        isLoading={loading}
        compact
        withAlternatingRows
        withBoxShadow
        withPagination
      />
      <SubTitle>Gebäudezähler</SubTitle>
      <Table
        data={generationMeters}
        columns={generationColumns}
        filterKind="Zähler"
        // rowLink={rowLink}
        onRowClick={onRowClick}
        filterState={filters}
        isLoading={loading}
        compact
        withAlternatingRows
        withBoxShadow
        withPagination
      />
      <SubTitle>Zähler (nicht beliefert)</SubTitle>
      <Table
        data={nondelivedMeters}
        columns={nondelivedColumns}
        filterKind="Zähler"
        // rowLink={rowLink}
        onRowClick={onRowClick}
        filterState={filters}
        isLoading={loading}
        compact
        withAlternatingRows
        withBoxShadow
        withPagination
      />
    </div>
  );
}

export default MeterList;
