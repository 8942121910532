import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  Table,
  Tabs,
  Tab,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';

import { CalculatedContractConsumption, ValidatedContractMeter } from './types';

export const columns: ColumnDefinition<CalculatedContractConsumption>[] = [
  {
    Header: 'Mieter',
    accessor: 'name',
    type: 'string',
  },
  {
    Header: 'KundenNr.',
    accessor: 'customerLabel',
    type: 'string',
  },
  {
    Header: 'VertragsNr.',
    accessor: 'contractLabel',
    type: 'string',
  },
];

const columnsWithError: ColumnDefinition<CalculatedContractConsumption>[] = [
  ...columns,
  {
    Header: 'Fehler',
    accessor: 'errors',
    Cell: (props) => props.value.join(', '),
  },
];

const Spacer = styled.div`
  margin-top: 10px;
`;

export function MixedResult({
  contracts,
}: {
  contracts: CalculatedContractConsumption[];
}) {
  const succeeded = contracts.filter((c) => c.errors.length === 0);
  const failed = contracts.filter((c) => c.errors.length !== 0);

  const rowLink = useCallback(
    ({ row }: { row: any }) => {
      const entry = row.original as ValidatedContractMeter;
      const contract = contracts.find((c) => c.id === entry.id);
      const baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;

      return `${baseURL}/contract/${entry.id}`;
    },
    [contracts],
  );

  return (
    <Tabs>
      {/* @ts-expect-error old Tab */}
      <Tab title={`${succeeded.length} Erfolgreich`}>
        <Spacer>
          <Table
            columns={columns}
            data={succeeded}
            filterKind="Erfolgreiche Verträge"
            isLoading={false}
            rowLink={rowLink}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
      {/* @ts-expect-error old Tab */}
      <Tab
        title={`${failed.length} Fehlerhaft`}
        hasNotification={failed.length !== 0}
      >
        <Spacer>
          <Table
            columns={columnsWithError}
            data={failed}
            filterKind="Fehlerhafte Verträge"
            isLoading={false}
            rowLink={rowLink}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
    </Tabs>
  );
}
