/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useCallback } from 'react';
import { Modal, Stepper } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { err, ok, Result } from 'neverthrow';
import { FetchResult } from '@apollo/client';

import {
  DeliveryPreference,
  GenerateContractDocumentMutation,
  GenerateContractDocumentPreviewMutation,
  useReadCurrentUserQuery,
} from '../../graphql-types';
import { documentTypesKeyToI18n } from '../../helpers/formatStrings';
import { useHasRole } from '../useHasRole';

import { SendStep } from './steps/sendStep';
import { PreviewStep } from './steps/previewStep';
import { CreationError, CreationHandlerWithoutArgs } from './types';
import { FinalStep } from './steps/finalStep';
import { MultiPreviewStep } from './steps/MultiPreviewStep';

const ModalWrap = styled.div`
  min-width: 550px;
`;

const StepperWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const missingAttributesErrorMapping: {
  consumption: string;
  reduction: string;
  iban: string;
} = {
  consumption: 'angenommener Jahresverbrauch (aJV)',
  reduction: 'Abschlagswert',
  iban: 'IBAN',
};

const MissingAttributesInContractDocumentErrorMessage = function fn(
  attributes: string[],
): string {
  return `Das Dokument konnte nicht erstellt werden. Bitte ergänze zunächst:\n${attributes
    .map(
      (a) =>
        `- ${
          missingAttributesErrorMapping[
            a.trim() as 'consumption' | 'reduction' | 'iban'
          ]
        }`,
    )
    .join(
      '\n',
    )}\nAnschließend kannst Du erneut versuchen das Dokument zu erstellen.`;
};

export function CreateDocumentModal({
  type,
  onClose,
  contractId,
  customerEmail,
  documentDeliveryMethod,
  previewDocumentMutation,
  generateDocumentMutation,
}: {
  type: string;
  onClose: () => void;
  contractId: string;
  customerEmail?: string | null;
  documentDeliveryMethod: string;
  previewDocumentMutation: (
    type: string,
    contractId: string,
  ) => Promise<FetchResult<GenerateContractDocumentPreviewMutation>>;
  generateDocumentMutation: (
    type: string,
    contractId: string,
    deliveryPreference: DeliveryPreference | null,
  ) => Promise<FetchResult<GenerateContractDocumentMutation>>;
}) {
  const { data } = useReadCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });
  const { hasRole: disableB2C } = useHasRole(
    'feature_contract_documents_b2c_disable',
  );

  const [step, setStep] = useState(0);

  const [deliveryPreference, setDeliveryPreference] =
    useState<DeliveryPreference | null>();

  const generateDocumentInternal: () => Promise<
    Result<boolean, CreationError>
  > = useCallback(async () => {
    const { data, errors } = await generateDocumentMutation(
      type,
      contractId,
      deliveryPreference as DeliveryPreference,
    );
    if (
      data?.generateContractDocument.__typename === 'SuccessResponse' ||
      data?.generateContractDocument.__typename ===
        'MultipleFilesSuccessResponse'
    ) {
      return ok(true);
    }

    if (errors) {
      return err({ reason: errors[0].message });
    }

    return err({ reason: 'Unbekannter Fehler' });
    // do not add more dependencies here, because otherwise it gets executed multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPreference]);

  const generatePreview: CreationHandlerWithoutArgs = useCallback(
    async (subType?: string) => {
      const { data, errors } = await previewDocumentMutation(
        subType || type,
        contractId,
      );

      if (errors) {
        return err({ reason: errors[0].message });
      }

      if (
        data?.generateContractDocumentPreview.__typename ===
        'ContractDocumentPreview'
      ) {
        return ok({ fileURL: data?.generateContractDocumentPreview.fileURL });
      }

      if (data?.generateContractDocumentPreview.__typename === 'UnknownError') {
        return err({
          reason: data.generateContractDocumentPreview.message,
        });
      }

      if (
        data?.generateContractDocumentPreview.__typename ===
        'MissingAttributesInContractDocumentError'
      ) {
        return err({
          reason: MissingAttributesInContractDocumentErrorMessage(
            data.generateContractDocumentPreview.attributes,
          ),
        });
      }

      return err({
        reason: 'Unbekannter Fehler',
      });
    },
    [contractId, previewDocumentMutation, type],
  );

  return (
    <Modal
      isOpen
      contentLabel="create-document"
      title={`${documentTypesKeyToI18n[type]} erstellen`}
      onRequestClose={onClose}
    >
      <ModalWrap>
        <StepperWrapper>
          <Stepper
            steps={[
              { label: 'Vorschau' },
              { label: 'Versandart' },
              { label: 'Erstellen' },
            ]}
            index={step}
          />
        </StepperWrapper>
        {step === 0 && (
          <>
            {type && type === 'contract_draft_letter' ? (
              <MultiPreviewStep
                onNext={() => setStep(1)}
                onPreview={generatePreview}
              />
            ) : (
              <PreviewStep
                onNext={() => setStep(1)}
                onPreview={generatePreview}
              />
            )}
          </>
        )}

        {step === 1 && (
          <SendStep
            onNext={(_deliveryPreference: string) => {
              setDeliveryPreference(_deliveryPreference as DeliveryPreference);
              setStep(2);
            }}
            customerEmail={customerEmail}
            deliveryPreference={documentDeliveryMethod}
            userEmail={data?.readCurrentUser.email!}
            disableB2CDelivery={disableB2C!}
          />
        )}
        {step === 2 && (
          <FinalStep
            type={documentTypesKeyToI18n[type]}
            deliveryPreference={deliveryPreference!}
            onClose={onClose}
            onCreate={generateDocumentInternal}
          />
        )}
      </ModalWrap>
    </Modal>
  );
}
