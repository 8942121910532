/* eslint-disable no-console */
import {
  CrmBoardCardType,
  CrmBoardType,
} from '../presentations/crm-board/crm-board.types';
import { GetInitialBoardQuery, Card } from '../../../graphql-types';
import { isTrueStringBool } from '../../../helpers/utils';

class TransformService {
  transformInitialBoardData(
    initialBoardQueryData: GetInitialBoardQuery,
  ): CrmBoardType {
    console.log('GotData to transform', initialBoardQueryData);

    /**
     * we only display the first instance per client
     */
    const instance = initialBoardQueryData.crminstances?.nodes[0];

    let columns: CrmBoardType['columns'] = [];
    if (instance) {
      columns = instance.crminstanceLaneRelsByIdCrminstance?.nodes.map(
        (boardLane) => {
          return {
            id: boardLane?.laneByIdLanes!.id,
            title: boardLane?.laneByIdLanes!.name,
            // @ts-expect-error because the generated type is too ambitious
            cards: boardLane!.laneByIdLanes.laneCardRelsByIdLanes.nodes.map(
              ({ cardByIdCard }: any) => {
                const {
                  isperson,
                  prefix,
                  name,
                  companyname,
                  co,
                  contractstartdate: contractStartDate,
                  contractenddate: contractEndDate,
                  street,
                  streetnr: streetNo,
                  createdat,
                  updatedat,
                  ajv: expectedConsumption,
                  reductionstartdate: reductionStartDate,
                  reduction,
                  city,
                  id,
                  zipcode: zipCode,
                  phone,
                  plantid,
                  meterid,
                  tariffid,
                  workspaceid,
                  notes,
                  email: mail,
                  state,
                  shippingaddressstreet,
                  shippingaddressstreetnr,
                  shippingaddresszipcode,
                  shippingaddresscity,
                  leadorigin,
                } = cardByIdCard;
                return {
                  id,
                  columnId: boardLane?.laneByIdLanes!.id,
                  isperson,
                  prefix,
                  name,
                  companyname,
                  co,
                  contractStartDate,
                  contractEndDate,
                  street,
                  streetNo,
                  zipCode,
                  city,
                  expectedConsumption,
                  reductionStartDate,
                  reduction,
                  created: new Date(createdat),
                  updated: updatedat ? new Date(updatedat) : undefined,
                  mail,
                  phone,
                  plantid,
                  meterid,
                  tariffid,
                  workspaceid,
                  notes,
                  status: state,
                  shippingaddressstreet,
                  shippingaddressstreetnr,
                  shippingaddresszipcode,
                  shippingaddresscity,
                  leadorigin,
                };
              },
            ),
          };
        },
      );
    }

    return {
      id: instance?.id,
      columns,
    };
  }

  convertBoardCardToCard(boardCard: CrmBoardCardType): Partial<Card> {
    const {
      expectedConsumption,
      reductionStartDate: reductionstartdate,
      reduction,
      city,
      mail: email,
      isperson,
      prefix,
      name,
      id,
      companyname,
      co,
      contractStartDate: contractstartdate,
      contractEndDate: contractenddate,
      phone,
      plantid,
      meterid,
      tariffid,
      workspaceid,
      notes,
      street,
      streetNo: streetnr,
      zipCode: zipcode,
      shippingaddressstreet,
      shippingaddressstreetnr,
      shippingaddresszipcode,
      shippingaddresscity,
      leadorigin,
    } = boardCard;

    const ispersonBool = isTrueStringBool(isperson);
    const plantidString = String(plantid);
    const meteridString = String(meterid);
    const tariffidString = String(tariffid);
    const workspaceidString = String(workspaceid);
    const reductionString = String(reduction);

    return {
      ...(ispersonBool !== null && { isperson: ispersonBool }),
      ajv:
        expectedConsumption === null ||
        // @ts-expect-error type
        expectedConsumption === '' ||
        expectedConsumption === undefined
          ? null
          : // @ts-expect-error type
            parseInt(expectedConsumption, 10),
      ...(reductionString === '' ? { reduction: null } : { reduction }),
      ...(reductionstartdate
        ? { reductionstartdate }
        : { reductionstartdate: null }),
      city,
      ...(contractstartdate
        ? { contractstartdate }
        : { contractstartdate: null }),
      ...(contractenddate ? { contractenddate } : { contractenddate: null }),
      email,
      ...(ispersonBool ? { prefix } : { prefix: 'keine Angabe' }),
      ...(ispersonBool ? { name } : { name: '' }),
      id: parseInt(id, 10),
      ...(!ispersonBool ? { companyname } : { companyname: '' }),
      ...(!ispersonBool ? { co } : { co: '' }),
      phone,
      ...(plantidString === '' ? { plantid: null } : { plantid }),
      ...(meteridString === '' ? { meterid: null } : { meterid }),
      ...(tariffidString === '' ? { tariffid: null } : { tariffid }),
      ...(workspaceidString === '' ? { workspaceid: null } : { workspaceid }),
      notes,
      street,
      streetnr,
      zipcode,
      shippingaddressstreet,
      shippingaddressstreetnr,
      shippingaddresszipcode,
      shippingaddresscity,
      leadorigin,
    };
  }
}

export const transformService = new TransformService();
