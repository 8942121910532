import { useFormikContext } from 'formik';
import { merge } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Button, Modal } from '@ampeersenergy/ampeers-ui-components';

import {
  Entry,
  ErrorMsg,
  Flex,
  FlexRow,
  HintWarning,
  Label,
} from '../../../components';
import { ThemedFontAwesomeIcon } from '../../../components/faIcons';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../components/graphql-form/render';
import { OptionalCustomValue } from '../../../components/OptionalCustomValue';
import OptionalField, {
  OptionalFieldStyling,
} from '../../../components/optionalField';
import {
  useReadContractByCustomerQuery,
  useReadCustomersSimpleQuery,
  useReadOpsClientSettingsQuery,
} from '../../../graphql-types';
import { formatDate, formatString } from '../../../helpers/formatStrings';
import { usePrevious } from '../../../helpers/usePrevious';
import {
  filterTariffSuggestions,
  sortExistingCustomersSuggestions,
  sortTariffsSuggestions,
} from '../../../helpers/utils';
import { PersonForm } from '../edit/editFormParts';
import { StyledLabel } from '../sharedForms';
import {
  loadJSONFromStorage,
  saveJSONToStorage,
} from '../../../helpers/storageUtils';
import { TooltipInfo } from '../../../components/TooltipInfo';

import { ContractStatus } from './formParts/contractStatus/contractStatus';
import { YearConsumptionForm } from './PaymentForm';

const Spacer = styled.div`
  width: 25px;
`;

const NewCustomerStyling = styled.div<{ isUpdateContract: boolean }>`
  margin-top: 10px;
  padding-left: ${(props) => (props.isUpdateContract ? '0px' : '10px')};
`;

const ModalTitle = styled.span`
  color: ${(props) => props.theme.primaryColor};
`;

export interface FormPartProps {
  fieldNamePrefix?: string;
}

export function CustomerForm({
  fieldNamePrefix = '',
  customerPersonState,
  customerLabelState,
  existingCustomerState,
  resetCustomerStates,
  disableNewCustomer = false,
}: {
  customerPersonState: any;
  customerLabelState: any;
  existingCustomerState: any;
  resetCustomerStates: any;
  disableNewCustomer?: boolean;
} & FormPartProps) {
  const [isExistingCustomer, setExistingCustomer] = existingCustomerState;
  const { formVariables } = useGraphqlForm();
  const { values, setValues, getFieldProps, setFieldValue } =
    useFormikContext();
  /**
   * when the selected customer in the field `customer.existingCustomer.id`
   * changes we schedule a customer update which gets
   * executes after we:
   *  1.) resolve the contractId of that selected customer
   *  2.) loaded that customers contract via `contractId`
   *  3.) auto-fill some form fields from that customers contract
   *
   * We schedule that auto-fill once per changing `customer.existingCustomer.id` via
   * `isCustomerUpdateScheduled`.
   */
  const [isCustomerUpdateScheduled, scheduleCustomerUpdate] = useState(false);
  const customerId = getFieldProps('customer.existingCustomer.id').value || '';
  const previousCustomerId = usePrevious(customerId);
  const [showNewPartnerModal, setShowNewPartnerModal] = useState(false);
  const [preventNewPartnerModal, setPreventNewPartnerModal] = useState(
    loadJSONFromStorage('hideNewPartnerModal') || false,
  );

  const handleShowNewPartnerModal = () => {
    if (!preventNewPartnerModal) {
      setShowNewPartnerModal(true);
    }
  };

  const handlePreventNewPartnerModal = () => {
    setPreventNewPartnerModal(
      (preventNewPartnerModalValue) => !preventNewPartnerModalValue,
    );
  };

  useEffect(() => {
    saveJSONToStorage('hideNewPartnerModal', preventNewPartnerModal);
  }, [preventNewPartnerModal]);

  const customerPersonStateValue = customerPersonState[0];

  useEffect(() => {
    if (
      previousCustomerId !== customerId &&
      typeof customerId === 'string' &&
      customerId !== ''
    ) {
      scheduleCustomerUpdate(true);
    }
  }, [customerId, previousCustomerId]);

  useEffect(() => {
    setFieldValue(
      'customer.addressBilling.companyFlag',
      !customerPersonStateValue,
      false,
    );
    setFieldValue(
      'customer.addressShipping.companyFlag',
      !customerPersonStateValue,
      false,
    );
    setFieldValue(
      'customer.addressShipping.companyFlag',
      !customerPersonStateValue,
      false,
    );
  }, [customerPersonStateValue, setFieldValue]);

  const { data, error } = useReadCustomersSimpleQuery({
    fetchPolicy: 'cache-first',
    variables: {
      isOperator:
        formVariables.isOperator === undefined
          ? false
          : formVariables.isOperator,
    },
    skip: customerId === '',
  });

  const selectedCustomer = data?.readCustomers.find(
    (customer) =>
      customer.id === getFieldProps('customer.existingCustomer.id').value,
  );

  const {
    data: dataContract,
    error: errorContract,
    loading: loadingContract,
  } = useReadContractByCustomerQuery({
    variables: {
      customerId: selectedCustomer?.id ?? '',
    },
    skip: !selectedCustomer || !selectedCustomer.id,
  });

  const resetCustomer = useCallback(() => {
    resetCustomerStates();
    setValues(
      merge({}, values, {
        customer: {
          existingCustomer: { id: '' },
          person: {
            namePrefix: '',
            name: '',
            contactPhone: '',
            contactMobile: '',
            companyFlag: false,
            co: '',
          },
          label: '',
          birthday: '',
          addressBilling: {
            namePrefix: '',
            name: '',
            streetName: '',
            streetNumber: '',
            zipCode: '',
            city: '',
            email: '',
            country: 'DE',
            additionalName: '',
            additionalNamePrefix: '',
            companyFlag: false,
            co: '',
          },
          address: {
            streetName: '',
            streetNumber: '',
            zipCode: '',
            city: '',
            country: 'DE',
          },
        },
      }),
      false,
    );
  }, [setValues, values, resetCustomerStates]);

  useEffect(() => {
    if (!isCustomerUpdateScheduled || dataContract === undefined) {
      return;
    }

    scheduleCustomerUpdate(false);
    setValues(
      merge({}, values, {
        customer: {
          person: {
            namePrefix:
              dataContract?.readContractByCustomer?.customer.addressShipping
                ?.namePrefix || '',
            name:
              dataContract?.readContractByCustomer?.customer.person.name || '',
            contactPhone:
              dataContract?.readContractByCustomer?.customer.person
                .contactPhone || '',
            contactMobile:
              dataContract?.readContractByCustomer?.customer.person
                .contactMobile || '',
            companyFlag:
              dataContract?.readContractByCustomer?.customer.person
                .companyFlag || '',
            co: dataContract?.readContractByCustomer?.customer.person.co || '',
          },
          label: dataContract?.readContractByCustomer?.customer.label || '',
          birthday:
            dataContract?.readContractByCustomer?.customer.birthday || '',
          addressBilling: {
            namePrefix:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .namePrefix || '',
            name:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .name || '',
            companyFlag:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .companyFlag || '',
            co:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .co || '',
            streetName:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .streetName || '',
            streetNumber:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .streetNumber || '',
            zipCode:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .zipCode || '',
            city:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .city || '',
            email:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .email || '',
            country:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .country || '',
            additionalName:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .additionalName || '',
            additionalNamePrefix:
              dataContract?.readContractByCustomer?.customer.addressBilling
                .additionalNamePrefix || '',
          },
          address: {
            streetName:
              dataContract?.readContractByCustomer?.customer.address
                .streetName || '',
            streetNumber:
              dataContract?.readContractByCustomer?.customer.address
                .streetNumber || '',
            zipCode:
              dataContract?.readContractByCustomer?.customer.address.zipCode ||
              '',
            city:
              dataContract?.readContractByCustomer?.customer.address.city || '',
            country:
              dataContract?.readContractByCustomer?.customer.address.country ||
              'DE', // TODO
          },
        },
      }),
      false,
    );
  }, [isCustomerUpdateScheduled, setValues, values, dataContract]);

  if (error) {
    return <ErrorMsg error={errorContract} />;
  }

  return (
    <>
      <Label>
        Vertragspartner
        <TooltipInfo
          id="customerTooltip"
          text="Ein Vertragspartner kann beliebig vielen Verträgen zugeordnet werden und entspricht immer einer juristischen Person."
        />
      </Label>
      {!disableNewCustomer && (
        <>
          <input
            type="radio"
            data-testid="existing-customer"
            id="existing-customer"
            checked={isExistingCustomer}
            onClick={() => {
              resetCustomer();
              setExistingCustomer(true);
            }}
            readOnly
          />
          <StyledLabel htmlFor="existing-customer">Existierender</StyledLabel>
          <br />
          <input
            type="radio"
            data-testid="new-customer"
            id="new-customer"
            checked={!isExistingCustomer}
            onClick={() => {
              resetCustomer();
              setExistingCustomer(false);
              handleShowNewPartnerModal();
            }}
            readOnly
          />
          <StyledLabel htmlFor="new-customer">Neuer</StyledLabel>
          <br />
          {showNewPartnerModal && (
            <Modal
              isOpen
              contentLabel="create-document"
              title={<ModalTitle>Hinweis</ModalTitle>}
              onRequestClose={() => setShowNewPartnerModal(false)}
              maxWidth="md"
            >
              <p
                css={`
                  margin: 30px 0;
                `}
              >
                Ein Vertragspartner kann mehrere Verträge haben. Solltest du
                einen Vertrag für einen bestehenden Vertragspartner anlegen
                wollen, wähle bitte den entsprechenden Vertragspartner aus.
                Einmal erstellte Verträge können nicht einfach einem anderen
                Vertragspartner zugeordnet werden.
              </p>
              <div
                css={`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <div
                  onClick={handlePreventNewPartnerModal}
                  onKeyDown={handlePreventNewPartnerModal}
                  css={`
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                  `}
                  role="button"
                  tabIndex={0}
                >
                  <input
                    type="checkbox"
                    css={`
                      margin-right: 10px;
                    `}
                    checked={preventNewPartnerModal}
                  />
                  Beim nächsten Mal nicht mehr anzeigen
                </div>
                <Button small onClick={() => setShowNewPartnerModal(false)}>
                  Verstanden
                </Button>
              </div>
            </Modal>
          )}
        </>
      )}
      {formVariables?.isSumMeter && (
        <HintWarning>
          Hinweis: Es müssen getrennte Vertragspartner für den Bezugs- und
          Einspeise-Vertrag gewählt bzw. angelegt werden
        </HintWarning>
      )}
      {isExistingCustomer ? (
        <>
          <GraphqlFormField
            name="customer.existingCustomer.id"
            sort={sortExistingCustomersSuggestions}
            label={null}
          />
          {customerId !== '' && (
            <>
              <Entry
                dataTestId="customer-label"
                title="Kunden-Nr."
                isLoading={loadingContract}
              >
                {`${dataContract?.readContractByCustomer?.customer.label}`}
              </Entry>
              <Entry title="Geburtsdatum" isLoading={loadingContract}>
                {formatDate(
                  dataContract?.readContractByCustomer?.customer.birthday,
                )}
              </Entry>
              <Label>Kontakt</Label>
              <Entry
                title={null}
                isLoading={loadingContract}
                prependix={<ThemedFontAwesomeIcon icon="envelope" />}
              >
                {formatString(
                  dataContract?.readContractByCustomer?.customer.addressBilling
                    .email,
                )}
              </Entry>
              <Entry
                title={null}
                isLoading={loadingContract}
                prependix={<ThemedFontAwesomeIcon icon="phone" />}
              >
                {formatString(
                  dataContract?.readContractByCustomer?.customer.person
                    .contactPhone,
                )}
              </Entry>
              <Entry
                title={null}
                isLoading={loadingContract}
                prependix={<ThemedFontAwesomeIcon icon="mobile" />}
              >
                {formatString(
                  dataContract?.readContractByCustomer?.customer.person
                    .contactMobile,
                )}
              </Entry>
            </>
          )}
        </>
      ) : (
        <NewCustomerStyling
          isUpdateContract={formVariables.useCase === 'updateContract'}
        >
          {/* <Label>Vertragspartner</Label> */}
          <PersonForm />
          <OptionalCustomValue
            label="Kunden-Nr."
            fieldPaths={[`${fieldNamePrefix}customer.label`]}
            managedState={customerLabelState}
            options={['Generierte', 'Eigene']}
            tooltipText="Jeder Vertragspartner hat eine eindeutige Kundennummer zur Zuordnung. Du kannst diese Nummer entweder selbst vorgeben oder generieren lassen."
          >
            <GraphqlFormField
              name={`${fieldNamePrefix}customer.label`}
              label={false}
              readOnly={customerLabelState[0]}
            />
          </OptionalCustomValue>
          {customerPersonState[0] ? (
            <>
              <Label>Geburtsdatum</Label>
              <BirthdayForm fieldNamePrefix={fieldNamePrefix} />
            </>
          ) : null}
          <Label>Kontakt</Label>
          <PersonContactForm fieldNamePrefix={fieldNamePrefix} />
        </NewCustomerStyling>
      )}
      <GraphqlFormField
        name={`${fieldNamePrefix}customer.advertisementAccepted`}
      />
      <GraphqlFormField
        name={`${fieldNamePrefix}customer.documentDeliveryMethod`}
        hint={
          getFieldProps(`${fieldNamePrefix}customer.documentDeliveryMethod`)
            .value === 'email' &&
          'Kundinnen und Kunden mit E-Mail Kommunikationsweise erhalten Ihre Abrechnungen automatisch'
        }
        tooltipText="Bei E-Mailversand werden Rechnungsdokumente automatisch per E-Mail übermittelt."
      />
    </>
  );
}

export function ContractForm({
  fieldNamePrefix,
  fixedOpenEndDateState,
  consumptionFirstOptionSelectionState,
  debtorLabelFirstOptionSelectionState,
  contractLabelState,
  /**
   * the second measurement contract
   * needs the same startDate and endDate as the
   * first one so we disable the field with
   * this flag
   */
  disabledStartAndEndDates,
}: {
  fixedOpenEndDateState: any;
  consumptionFirstOptionSelectionState?: any;
  debtorLabelFirstOptionSelectionState?: any;
  contractLabelState: any;
  disabledStartAndEndDates?: boolean;
} & FormPartProps) {
  const [isFixed, setIsFixed] = fixedOpenEndDateState;

  const sortTariff = useCallback(sortTariffsSuggestions, []);
  const { getFieldProps, setFieldValue } = useFormikContext();
  const { formVariables } = useGraphqlForm();

  useEffect(() => {
    if (!isFixed && getFieldProps(`${fieldNamePrefix}endDate`).value !== '') {
      setFieldValue(`${fieldNamePrefix}endDate`, '', true);
    }
    if (formVariables.formState === 'first-contract') {
      formVariables.sumMeterContractLabelState[1](
        getFieldProps(`${fieldNamePrefix}label`).value,
      );
    }
  }, [isFixed, getFieldProps, setFieldValue, fieldNamePrefix, formVariables]);

  const contractStartDate = DateTime.fromISO(
    getFieldProps(`${fieldNamePrefix}startDate`).value,
    {
      zone: 'utc',
    },
  );

  return (
    <Flex>
      <OptionalCustomValue
        label="Vertragsnummer"
        fieldPaths={[`${fieldNamePrefix}label`]}
        managedState={contractLabelState}
        options={['Generierte', 'Eigene']}
        tooltipText="Jeder Vertrag hat eine eindeutige Vertragsnummer zur Zuordnung. Du kannst diese Nummer entweder selbst vorgeben oder generieren lassen."
      >
        <GraphqlFormField
          name={`${fieldNamePrefix}label`}
          label={false}
          readOnly={contractLabelState[0]}
        />
      </OptionalCustomValue>
      <FlexRow>
        <Flex>
          <GraphqlFormField name={`${fieldNamePrefix}signDate`} />
        </Flex>
        <Spacer />
        <Flex>
          <GraphqlFormField
            name={`${fieldNamePrefix}startDate`}
            disabled={disabledStartAndEndDates}
            {...(formVariables?.isSumMeter && {
              warning:
                'Hinweis: Der Vertragsbeginn für den Bezugs- und Einspeisevertrag muss gleich sein.',
            })}
            tooltipText="Zu diesem Datum beginnt die Stromlieferung. Steht das Datum noch nicht fest, kann ein beliebiges Datum gewählt werden, das später angepasst werden kann. Hierfür ist der Status “In Transfer” erforderlich."
          />
        </Flex>
      </FlexRow>
      <Label>Lieferende</Label>
      <input
        type="radio"
        data-testid="contractEndDate-open"
        id="contractEndDate-open"
        checked={!isFixed}
        disabled={disabledStartAndEndDates}
        onClick={() => setIsFixed(false)}
        readOnly
      />
      <StyledLabel htmlFor="contractEndDate-open">Offenes Ende</StyledLabel>{' '}
      <br />
      <input
        type="radio"
        data-testid="contractEndDate-fixed"
        id="contractEndDate-fixed"
        checked={isFixed}
        disabled={disabledStartAndEndDates}
        onClick={() => setIsFixed(true)}
        readOnly
      />
      <StyledLabel htmlFor="contractEndDate-fixed">Festes Ende</StyledLabel>
      <br />
      {isFixed && (
        <GraphqlFormField
          name={`${fieldNamePrefix}endDate`}
          label={false}
          disabled={disabledStartAndEndDates}
        />
      )}
      <ContractStatus />
      {consumptionFirstOptionSelectionState && (
        <YearConsumptionForm
          fieldNamePrefix={fieldNamePrefix}
          consumptionFirstOptionSelectionState={
            consumptionFirstOptionSelectionState
          }
        />
      )}
      {debtorLabelFirstOptionSelectionState && (
        <DebtorLabelForm
          fieldNamePrefix={fieldNamePrefix}
          debtorLabelFirstOptionSelectionState={
            debtorLabelFirstOptionSelectionState
          }
        />
      )}
      <GraphqlFormField
        name={`${fieldNamePrefix}workspace.id`}
        singleValuePrefill
        tooltipText="Der Vertragstyp ordnet den Vertrag einem bestimmten Produkt und damit der produktspezifischen Dokumentenvorlage zu."
      />
      <GraphqlFormField
        name={`${fieldNamePrefix}tariff.id`}
        sort={sortTariff}
        placeholder="Tarif - Preisblatt"
        filter={filterTariffSuggestions(contractStartDate)}
        tooltipText="Die Tarifinformationen umfassen nicht nur Preise, sondern auch Steuerinformationen, Mindestlaufzeiten und Kündigungsfristen, die für den Vertrag gelten."
      />
      <GraphqlFormField
        name={`${fieldNamePrefix}loadProfile.id`}
        tooltipText="Als Lastprofil kann entweder H0 für Haushalte (auch für Summenzähler) oder G0 für gewerbliche Verbraucher eingetragen werden. Aktuell wird diese Information lediglich mitgeführt, sie ist aber nicht abrechnungsrelevant."
      />
    </Flex>
  );
}

export function PersonContactForm({ fieldNamePrefix }: FormPartProps) {
  return (
    <>
      <GraphqlFormField
        name={`${fieldNamePrefix}customer.addressBilling.email`}
        prependix={<ThemedFontAwesomeIcon icon="envelope" />}
        label={null}
      />
      <GraphqlFormField
        name={`${fieldNamePrefix}customer.person.contactPhone`}
        prependix={<ThemedFontAwesomeIcon icon="phone" />}
        label={null}
      />
      <GraphqlFormField
        name={`${fieldNamePrefix}customer.person.contactMobile`}
        prependix={<ThemedFontAwesomeIcon icon="mobile" />}
        label={null}
      />
    </>
  );
}

export function BirthdayForm({ fieldNamePrefix }: FormPartProps) {
  return (
    <GraphqlFormField
      name={`${fieldNamePrefix}customer.birthday`}
      label={null}
    />
  );
}

export function DebtorLabelForm({
  fieldNamePrefix,
  debtorLabelFirstOptionSelectionState,
}: FormPartProps & { debtorLabelFirstOptionSelectionState: any }) {
  const { data: opsClientSetting } = useReadOpsClientSettingsQuery();

  const { debtorLabel: debtorLabelMandatory } =
    opsClientSetting?.readOpsClientSettings ?? {};
  const [isDebtorLabelDisabled, setDebtorLabelDisabled] =
    debtorLabelFirstOptionSelectionState;

  React.useEffect(() => {
    setDebtorLabelDisabled(!debtorLabelMandatory);
  }, [debtorLabelMandatory, setDebtorLabelDisabled]);

  const tooltipText =
    'Wenn die Debitorennummer für den Austausch mit dem Buchhaltungssystem benötigt wird, ist dieses Feld ein Pflichtfeld. Bitte stelle sicher, dass Du das korrekte Debitorenkonto verknüpfst, da Anpassungen nur durch das AE Service-Team umgesetzt werden können.';

  return (
    <>
      {debtorLabelMandatory ? (
        <GraphqlFormField
          name={`${fieldNamePrefix}debtorLabel`}
          tooltipText={tooltipText}
        />
      ) : (
        <OptionalField
          label="Debitorennummer"
          options={['Nein', 'Ja']}
          test-id="debtorLabel"
          managedState={debtorLabelFirstOptionSelectionState}
          resetFields={[`${fieldNamePrefix}debtorLabel`]}
          tooltipText={tooltipText}
        >
          <OptionalFieldStyling>
            {!isDebtorLabelDisabled && (
              <GraphqlFormField name={`${fieldNamePrefix}debtorLabel`} />
            )}
          </OptionalFieldStyling>
        </OptionalField>
      )}
    </>
  );
}
