import {
  ButtonIcon,
  Icons,
  useDialog,
  useToasts,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { generatePath, useNavigate, useLocation } from 'react-router-dom';

import {
  useDeleteOldAccountingWorkflowMutation,
  useReadWorkflowsQuery,
} from '../../../graphql-types';

const ModalMessage = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const DeleteButtonIcon = styled(ButtonIcon)`
  &:hover svg {
    fill: ${(props) => props.theme.palette.error.color};
  }
`;

export function NavigationActions({
  currentWorkflowId,
  uuid,
  name,
  isOpcCreated,
}: {
  currentWorkflowId: string;
  uuid?: string;
  name?: string;
  isOpcCreated?: boolean;
}) {
  const { data } = useReadWorkflowsQuery();
  const [deleteWorkflowMutation] = useDeleteOldAccountingWorkflowMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToasts();
  const { openConfirmation } = useDialog();

  const onAction = useCallback(
    (op: 'next' | 'prev') => {
      if (!data) return;
      const list = data.readWorkflows;
      const index = list.findIndex((w) => w.id === currentWorkflowId);
      const nextIndex = (() => {
        if (op === 'prev') {
          return index + 1 === list.length ? 0 : index + 1;
        }
        return index === 0 ? list.length - 1 : index - 1;
      })();

      const nextPath = generatePath(location.pathname, {
        id: list[nextIndex].id,
        name: list[nextIndex].alias || '',
      });
      navigate(nextPath);
    },
    [currentWorkflowId, data, location.pathname, navigate],
  );

  const handleDeleteWorkflowModalClick = async () => {
    try {
      const res = await deleteWorkflowMutation({
        variables: { workflowId: currentWorkflowId, uuid },
      });

      if (
        res.data?.deleteOldAccountingWorkflow &&
        res.data?.deleteOldAccountingWorkflow.__typename === 'WorkflowResponse'
      ) {
        navigate(`/accounting/workflows`);
        toast.success(
          `Der Workflow mit der ID ${currentWorkflowId} wurde erfolgreich gelöscht`,
          {
            autoClose: 2500,
          },
        );
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      toast.error(`There was an error trying to delete ${name}: ${message}`);
    }
  };

  const content = (
    <ModalMessage>
      Bist Du sicher, dass Du den <br /> {name} löschen möchtest?
    </ModalMessage>
  );

  const deleteMutation = async () => {
    await openConfirmation({
      confirmButtonLabel: 'Löschen',
      dangerButton: true,
      content,
      title: name || '',
      maxWidth: 'md',
      handleRequest: handleDeleteWorkflowModalClick,
    });
  };

  return (
    <>
      {!isOpcCreated && uuid && currentWorkflowId && name && (
        <>
          {/* @ts-expect-error old ButtonIcon */}
          <DeleteButtonIcon
            icon={Icons.Delete}
            secondary
            data-tip
            data-for="prev-btn"
            onClick={deleteMutation}
          />
        </>
      )}

      {/* @ts-expect-error old ButtonIcon */}
      <ButtonIcon
        icon={Icons.ArrowLeft}
        secondary
        small
        data-tip
        data-for="prev-btn"
        onClick={() => onAction('prev')}
        title="Vorheriger Abrechnungslauf"
      />
      {/* @ts-expect-error old ButtonIcon */}
      <ButtonIcon
        icon={Icons.ArrowRight}
        secondary
        small
        data-tip
        data-for="next-btn"
        onClick={() => onAction('next')}
        title="Nächster Abrechnungslauf"
      />
    </>
  );
}
