import React, { useState, useCallback, useMemo } from 'react';
import {
  Table,
  Button,
  ColumnDefinition,
  TableRows,
} from '@ampeersenergy/ampeers-ui-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeepExtractType } from 'ts-deep-extract-types';

import {
  formatContractStatus,
  formatEmgEndDates,
} from '../../helpers/formatStrings';
import { ContractExcerpt, PlantDetailExtendedQuery } from '../../graphql-types';
import CreateOverlay from '../contract/create/createOverlay';

type TenantContractType = DeepExtractType<
  PlantDetailExtendedQuery,
  ['readPlant', 'tenantContracts']
>;

const columns: ColumnDefinition<TenantContractType>[] = [
  {
    Header: 'Mieter',
    accessor: 'name',
  },
  {
    Header: 'KundenNr.',
    accessor: 'customerLabel',
  },
  {
    Header: 'VertragsNr.',
    accessor: 'label',
  },
  {
    Header: 'ZählerNr.',
    accessor: 'meterNumber',
    Cell: ({ value }) => (value.trim() === '' ? 'Zuordnung beendet' : value),
  },
  {
    Header: 'Lieferbeginn',
    accessor: 'startDate',
    type: 'date',
  },
  {
    Header: 'Lieferende',
    accessor: 'endDate',
    type: 'date',
    Cell: ({ value }) => formatEmgEndDates(value, true, false),
  },
  {
    Header: 'Vertragsstatus',
    accessor: 'contractStatus',
  },
];

function TenantList({ data, isLoading }: any) {
  const [createOverlayVisible, setCreateOverlayVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const onRowClick = (row: any) => {
    const entry = row.original as ContractExcerpt;

    navigate(`${location.pathname}/contract/${entry.id}${location.search}`);
  };

  const renderTableActions = useCallback(
    () => (
      <Button onClick={() => setCreateOverlayVisible(true)}>
        Mieter anlegen
      </Button>
    ),
    [],
  );

  const tableData = useMemo(
    () =>
      (data?.readPlant?.tenantContracts ?? []).map((row: any) => ({
        ...row,
        contractStatus: formatContractStatus(row.contractStatus).title,
      })),
    [data],
  );

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        withFilter
        filterKind="Mieter"
        onRowClick={onRowClick}
        isLoading={isLoading}
        renderTableActions={renderTableActions}
        compact
        withAlternatingRows
        // withRouting
        withPagination
      />
      {data?.readPlant && createOverlayVisible && (
        <CreateOverlay
          plant={data.readPlant}
          onClose={() => setCreateOverlayVisible(false)}
          kind="tenant"
          isVisible={createOverlayVisible}
          defaultValues={{
            customer: {
              isOperator: false,
            },
          }}
        />
      )}
    </>
  );
}

export default TenantList;
