/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-is-mounted */

import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import { withComponentLabel } from './withComponentLabel';
import { LegendItem } from './legend';

interface ProgressRingProps {
  isLoading: boolean;
  radius: number;
  stroke: number;
  progress: number[];
  colors: string[];
  textColor: string;
  value?: string | number;
  withProgress?: boolean;
  legendItems?: { legend: string; color: string }[];
}

interface ProgressRingState {
  progress: number[];
}

const LegendWrapper = styled.div`
  display: flex;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`;

const Animate = styled.div`
  .animate {
    transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .text {
    border-radius: 4px;
    transform-origin: 50% 50%;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    background: #fef5e6;
    font-weight: bold;
  }
`;

export class ProgressRing extends React.Component<
  ProgressRingProps,
  ProgressRingState
> {
  private circumference: number;

  constructor(props: ProgressRingProps) {
    super(props);

    this.state = {
      progress: new Array(props.progress.length).fill(0),
    };

    this.circumference = props.radius * 2 * Math.PI;
  }

  componentDidMount() {
    // emulating progress
    const interval = setInterval(() => {
      this.state.progress.forEach((p: number, index: number) => {
        const newProgressValue = p + 1;

        if (newProgressValue <= this.props.progress[index]) {
          const newProgressValues = this.state.progress.slice();
          newProgressValues.splice(index, 1, newProgressValue);

          this.setState({
            progress: newProgressValues,
          });
        }
      });

      if (
        this.state.progress.every(
          (p: number, index: number) => p === this.props.progress[index],
        )
      )
        clearInterval(interval);
    }, 20);
  }

  render() {
    const {
      radius,
      stroke,
      isLoading,
      textColor,
      value,
      colors,
      legendItems,
      withProgress = true,
    } = this.props;

    const strokeDashOffsets = this.state.progress.map(
      (_: number, index: number) =>
        this.circumference -
        (this.state.progress
          .slice(0, index + 1)
          .reduce((acc, _p) => acc + _p, 0) /
          100) *
          this.circumference,
    );

    const boxCenter = radius + stroke;
    const boxSize = radius * 2 + stroke * 2;
    return (
      <LegendWrapper
        style={legendItems?.length === 1 ? { alignItems: 'center' } : {}}
      >
        {isLoading ? (
          <Animate>
            <svg height={boxSize} width={boxSize}>
              <ContentLoader speed={2} height={boxSize} width={boxSize}>
                <circle
                  className="animate"
                  r={radius}
                  cx={boxCenter}
                  cy={boxCenter}
                />
              </ContentLoader>
            </svg>
          </Animate>
        ) : (
          <Animate>
            <svg height={boxSize} width={boxSize}>
              {strokeDashOffsets
                .slice(0)
                .reverse()
                .map((offset: number, index: number) => (
                  <circle
                    key={`${offset}-${index}`}
                    className="animate"
                    stroke={colors[strokeDashOffsets.length - index - 1]}
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={`${this.circumference} ${this.circumference}`}
                    style={{ strokeDashoffset: offset }}
                    r={radius}
                    cx={boxCenter}
                    cy={boxCenter}
                  />
                ))}
              <text
                className="text"
                x={boxCenter}
                y={boxCenter}
                dominantBaseline="middle"
                textAnchor="middle"
                fill={textColor}
              >
                {withProgress ? `${this.state.progress[0]}%` : value}
              </text>
            </svg>
          </Animate>
        )}
        {legendItems && (
          <Legend>
            {legendItems.map(({ legend, color }) => (
              <LegendItem
                key={`${legend}-${color}`}
                secondary
                small
                color={color}
                className="legendItem"
              >
                {legend}
              </LegendItem>
            ))}
          </Legend>
        )}
      </LegendWrapper>
    );
  }
}

const ProgressRingWithLabel = withComponentLabel(ProgressRing);

interface ProgressRingWithLabelsProps extends ProgressRingProps {
  labels: string[];
  secondary?: boolean;
}

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .legend {
    font-size: 14px;
  }
`;

export const ProgressRingWithLabels: React.FC<ProgressRingWithLabelsProps> = ({
  labels,
  colors,
  ...props
}) => (
  <ProgressRingWithLabel
    colors={colors}
    label={
      <LabelWrapper>
        {labels.map((label, index) =>
          labels.length === 1 ? (
            label
          ) : (
            <LegendItem
              key={`${colors[index]}-${label}`}
              className="legend"
              color={colors[index]}
              {...props}
            >
              {label}
            </LegendItem>
          ),
        )}
      </LabelWrapper>
    }
    {...props}
  >
    <></>
  </ProgressRingWithLabel>
);
