import React, { ReactNode } from 'react';
import { FlexRow, Icons } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

const Hint = styled.div`
  color: #6f6f6f;
  font-size: 14px;
  background: #f9f9f9;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;

  svg {
    margin-right: 10px;
  }
`;

export const InfoAlert: React.FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Hint>
    <FlexRow>
      <Icons.Info size={35} color="#6f6f6f" />
      {children}
    </FlexRow>
  </Hint>
);
