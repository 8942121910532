/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormikProps, useFormikContext } from 'formik';
import {
  FormikInput,
  FormikTextarea,
  Button,
  Label,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import type { CrmBoardCardEditType } from '../crm-board-card-edit/crm-board-card-edit';
import RelationSelect from '../../../../components/relationSelect';
import { FormikSelect } from '../../../../components';
import { isTrueStringBool } from '../../../../helpers/utils';
import MeterSelect from '../../../../components/form/relationSelects/meterSelect';
import { formatCurrency, formatDate } from '../../../../helpers/formatStrings';
import InputSuggestion from '../../../../components/inputSuggestion';
import { useReadTariffQuery } from '../../../../graphql-types';
import { deductionCalculation } from '../../../../helpers/calculations';

import './crm-board-card-edit-form.scss';

type CrmBardCardEditFormFieldLabelKeys = Required<keyof CrmBoardCardEditType>;

export type CrmBardCardEditFormFieldLabels = {
  [key in CrmBardCardEditFormFieldLabelKeys]: string;
};

export type CrmBoardCardEditFormPropType = {
  initialValues?: Partial<CrmBoardCardEditType>;
  labels: CrmBardCardEditFormFieldLabels;
  onSubmit: (values: CrmBoardCardEditType) => void;
  submitLabel: string;
  metadata: { created: Date | undefined; updated: Date | undefined };
  isEditing: boolean;
  setDirty: (isDirty: boolean) => void;
  validationSchema: FormikProps['validationSchema'];
};

const FormikTextareaStyling = styled.div`
  textarea {
    min-height: 100px;
  }
`;

export default function CrmBoardCardEditForm(
  props: CrmBoardCardEditFormPropType & FormikProps<CrmBoardCardEditType>,
) {
  const {
    isSubmitting,
    submitLabel,
    isValid,
    submitForm,
    labels,
    metadata: { created, updated },
    isEditing,
    setDirty,
    dirty,
  } = props;
  const meterValidationState = useState(false);
  const [isMeterValidating] = meterValidationState;
  const { setFieldValue, getFieldProps, initialValues } = useFormikContext();
  const { data: dataTariff } = useReadTariffQuery({
    variables: {
      tariffId: getFieldProps('tariffid').value,
    },
    skip: !getFieldProps('tariffid').value,
  });
  const isInitialEndDateFixed = Boolean(initialValues?.contractEndDate);
  const [isFixed, setIsFixed] = useState(isInitialEndDateFixed);

  const contractStartDate = getFieldProps('contractStartDate').value;
  const contractEndDate = getFieldProps('contractEndDate').value;
  const meterSelectDisabled =
    !getFieldProps('plantid').value ||
    !getFieldProps('contractStartDate').value;
  const meterSelectMessage = `Bitte wählen Sie zunächst ${
    !getFieldProps('plantid').value && !getFieldProps('contractStartDate').value
      ? 'eine Kundenanlage und einen Lieferbeginn'
      : !getFieldProps('plantid').value
      ? 'eine Kundenanlage'
      : 'einen Lieferbeginn'
  } aus.`;
  const meterIdValidationData = useMemo(
    () => ({
      meterId: getFieldProps('meterid').value,
      plantId: getFieldProps('plantid').value,
      contractStartDate: getFieldProps('contractStartDate').value,
      contractEndDate: getFieldProps('contractEndDate').value,
    }),
    [getFieldProps],
  );

  useEffect(() => {
    /**
     * reset contractEndDate when user switches from a fixed contract end
     * to a open contract end.
     */
    if (!isFixed && contractEndDate !== '' && contractEndDate !== undefined) {
      setFieldValue('contractEndDate', '', false);
    }

    if (
      getFieldProps('meterid').value &&
      (getFieldProps('contractStartDate').value === '' ||
        getFieldProps('plantid').value === '')
    ) {
      setFieldValue('meterid', '', false);
    }
  }, [
    getFieldProps,
    setFieldValue,
    isFixed,
    meterIdValidationData,
    contractEndDate,
  ]);

  const isDeductionCalculatable =
    getFieldProps('expectedConsumption').value > 0 &&
    getFieldProps('contractStartDate').value &&
    getFieldProps('reductionStartDate').value &&
    (getFieldProps('reduction').value === '' ||
      getFieldProps('reduction').value === undefined) &&
    getFieldProps('tariffid').value &&
    dataTariff?.readTariff.priceSheets[0].basicPrice &&
    dataTariff?.readTariff.priceSheets[0].energyPrice &&
    DateTime.fromISO(getFieldProps('contractStartDate').value).year ===
      DateTime.fromISO(getFieldProps('reductionStartDate').value).year;

  const deduction = useMemo(() => {
    if (!isDeductionCalculatable) {
      return '';
    }
    const expectedConsumption = getFieldProps('expectedConsumption').value;
    const _contractStartDate = getFieldProps('contractStartDate').value;
    const reductionStartDate = getFieldProps('reductionStartDate').value;
    const basicPrice = dataTariff?.readTariff.priceSheets[0].basicPrice;
    const energyPrice = dataTariff?.readTariff.priceSheets[0].energyPrice;

    return deductionCalculation({
      expectedConsumption,
      contractStartDate: _contractStartDate,
      reductionStartDate,
      basicPrice,
      energyPrice,
    });
  }, [isDeductionCalculatable, dataTariff, getFieldProps]);

  const onAcceptDeduction = useCallback(() => {
    setFieldValue('reduction', deduction, true);
  }, [deduction, setFieldValue]);

  const ispersonBool = ['string', 'boolean'].includes(
    typeof getFieldProps('isperson').value,
  )
    ? isTrueStringBool(getFieldProps('isperson').value)
    : initialValues?.isperson;

  useEffect(() => {
    setDirty(dirty);
  }, [setDirty, dirty]);

  return (
    <Form noValidate autoComplete="off">
      <section className="form">
        {/* Start Kontakdaten */}
        <h3 className="title"> Kontakdaten </h3>
        <FormikSelect
          name="isperson"
          id="isperson"
          label="Kunde"
          className="form-field isperson"
          data-testid="isperson-select"
          required
        >
          <option value="true" selected>
            Person
          </option>
          <option value="false"> Firma </option>
        </FormikSelect>
        {ispersonBool ? (
          <>
            <div className="form-field prefix">
              <FormikSelect
                name="prefix"
                id="prefix"
                label={labels.prefix}
                required
                data-testid="prefix"
              >
                <option disabled hidden value="">
                  Bitte wählen
                </option>
                <option value="keine Angabe">keine Angabe</option>
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
                <option value="Herr Dr.">Herr Dr.</option>
                <option value="Frau Dr.">Frau Dr.</option>
                <option value="Herr Prof. Dr.">Herr Prof. Dr.</option>
                <option value="Frau Prof. Dr.">Frau Prof. Dr.</option>
              </FormikSelect>
            </div>
            <div className="form-field name">
              <FormikInput
                placeholder={labels.name}
                name="name"
                type="text"
                label={labels.name}
                data-testid="name"
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-field company-name">
              <FormikInput
                placeholder={labels.companyname}
                name="companyname"
                type="text"
                label={labels.companyname}
                data-testid="companyName"
              />
            </div>
            <div className="form-field co">
              <FormikInput
                placeholder={labels.co}
                name="co"
                type="text"
                label=""
                data-testid="coField"
              />
            </div>
          </>
        )}

        {/* Start Kontakdaten Second Row */}
        <div className="form-field mail">
          <FormikInput
            placeholder={labels.mail}
            name="mail"
            type="email"
            label={labels.mail}
            data-testid="email"
          />
        </div>

        <div className="form-field phone">
          <FormikInput
            placeholder={labels.phone}
            name="phone"
            type="tel"
            label={labels.phone}
            data-testid="phone"
          />
        </div>

        <div className="form-field leadorigin">
          <FormikSelect
            name="leadorigin"
            id="leadorigin"
            label={labels.leadorigin}
            data-testid="leadorigin"
          >
            <option value="phone">Telefon</option>
            <option value="rental">Vermietung</option>
            <option value="internal">Intern</option>
            <option value="letter">Bewohneranschreiben</option>
            <option value="homepage">Homepage</option>
            <option value="event">Veranstaltung</option>
            <option value="other">Sonstiges</option>
          </FormikSelect>
        </div>
        {/* End Kontakdaten Second Row */}

        {/* End Kontakdaten */}

        {/* Start Addresse */}

        <h3 className="title"> Addresse</h3>

        <Label className=" form-field shippingaddress-label">
          Neue Abnahmestelle
        </Label>
        <Label className=" form-field address-label">Aktuelle Adresse</Label>
        <div className="form-field shippingaddressstreet">
          <FormikInput
            placeholder={labels.shippingaddressstreet}
            name="shippingaddressstreet"
            type="text"
            label={null}
            data-testid="shippingaddressstreet"
          />
        </div>

        <div className="form-field shippingaddressstreetnr">
          <FormikInput
            placeholder={labels.shippingaddressstreetnr}
            name="shippingaddressstreetnr"
            type="text"
            label={null}
            data-testid="shippingaddressstreetnr"
          />
        </div>

        <div className="form-field street">
          <FormikInput
            placeholder={labels.street}
            name="street"
            type="text"
            label={null}
            data-testid="street"
          />
        </div>

        <div className="form-field street-no">
          <FormikInput
            placeholder={labels.streetNo}
            name="streetNo"
            type="text"
            label={null}
            data-testid="streetNo"
          />
        </div>

        <div className="form-field shippingaddresszipcode">
          <FormikInput
            placeholder={labels.shippingaddresszipcode}
            name="shippingaddresszipcode"
            type="text"
            label={null}
            data-testid="shippingaddresszipcode"
          />
        </div>

        <div className="form-field shippingaddresscity">
          <FormikInput
            placeholder={labels.shippingaddresscity}
            name="shippingaddresscity"
            type="text"
            label={null}
            data-testid="shippingaddresscity"
          />
        </div>

        <div className="form-field zip-code">
          <FormikInput
            placeholder={labels.zipCode}
            name="zipCode"
            type="text"
            label={null}
            data-testid="zipCode"
          />
        </div>

        <div className="form-field city">
          <FormikInput
            placeholder={labels.city}
            name="city"
            type="text"
            label={null}
            data-testid="city"
          />
        </div>
        {/* End Addresse */}

        {/* Start Vertrag */}
        <h3 className="title"> Vertrag </h3>

        <div className="form-field contractStartDate">
          <FormikInput
            placeholder={labels.contractStartDate}
            name="contractStartDate"
            type="date"
            label={labels.contractStartDate}
            data-testid="contractStartDate"
          />
        </div>
        <FormikSelect
          name="contractEndDate-open"
          id="contractEndDate-open"
          label="Geplantes Lieferende"
          className="form-field contractEndDate"
          data-testid="contractEndDate-open"
          onChange={(e) => setIsFixed(e.target.value === 'true')}
          required
        >
          <option value="false" selected>
            Offenes Ende
          </option>
          <option value="true"> Festes Ende </option>
        </FormikSelect>

        <div className="form-field contractEndDate-input-field">
          {isFixed ? (
            <FormikInput
              placeholder={labels.contractEndDate}
              name="contractEndDate"
              type="date"
              label={null} // labels.contractEndDate}
            />
          ) : (
            <div>&nbsp;</div>
          )}
        </div>

        <div className="form-field plantid">
          <RelationSelect
            type="Plant"
            data-testid="plantid"
            id="plantid"
            label={labels.plantid}
            name="plantid"
            placeholder={labels.plantid}
          />
        </div>
        <div className="form-field meterid">
          <MeterSelect
            type="Meter"
            data-testid="meterid"
            id="meterid"
            label={labels.meterid}
            name="meterid"
            placeholder={labels.meterid}
            plantId={getFieldProps('plantid').value}
            boundStartAt={contractStartDate}
            {...(isFixed && { boundEndAt: contractEndDate })}
            disabled={meterSelectDisabled}
            {...(meterSelectDisabled && {
              warning: meterSelectMessage,
            })}
            meterValidationState={meterValidationState}
          />
        </div>
        <div>&nbsp;</div>

        <div className="form-field contractType">
          <RelationSelect
            type="Workspace"
            data-testid="workspaceid"
            id="workspaceid"
            label={labels.workspaceid}
            name="workspaceid"
            placeholder={labels.workspaceid}
          />
        </div>
        <div className="form-field tariffid">
          <RelationSelect
            type="Tariff"
            data-testid="tariffid"
            id="tariffid"
            label={labels.tariffid}
            name="tariffid"
            placeholder={labels.tariffid}
            filter={(tariffs) => {
              // filter currently valid tariffs
              const currentTariffs = tariffs.filter(
                (tariff) =>
                  Date.parse(tariff.validityStartDate) <=
                    Date.parse(contractStartDate) &&
                  Date.parse(tariff.validityEndDate) >=
                    Date.parse(contractStartDate),
              );
              // filter currently valid price sheets
              const filteredTariffs = currentTariffs.map((tariff) => {
                const filteredPriceSheets = tariff.priceSheets.filter(
                  (priceSheet) =>
                    Date.parse(priceSheet.startDate) <=
                    Date.parse(contractStartDate),
                );

                return {
                  ...tariff,
                  priceSheets: filteredPriceSheets,
                };
              });

              /* since it is possible that
              tariff.validityStartDate < priceSheet.startDate
              we have to filter out tariffs which have
              no currently valid price sheets
              */
              const tariffsWithNonEmptyPriceSheets = filteredTariffs.filter(
                (tariff) => tariff.priceSheets.length > 0,
              );

              return tariffsWithNonEmptyPriceSheets;
            }}
          />
        </div>

        <div className="form-field expected-consumption">
          <FormikInput
            placeholder={labels.expectedConsumption}
            name="expectedConsumption"
            type="number"
            label={labels.expectedConsumption}
            unit="kWh"
            data-testid="expectedConsumption"
          />
        </div>

        <div className="form-field reductionStartDate">
          <FormikInput
            placeholder={labels.reductionStartDate}
            name="reductionStartDate"
            type="date"
            label={labels.reductionStartDate}
            data-testid="reductionStartDate"
          />
        </div>
        <div className="form-field reduction">
          <FormikInput
            placeholder={labels.reduction}
            name="reduction"
            type="number"
            label={labels.reduction}
            unit="€"
            data-testid="reduction"
          />
          {isDeductionCalculatable && (
            <InputSuggestion
              message="Vorgeschlagener Abschlag:"
              value={formatCurrency(deduction)}
              onAccept={onAcceptDeduction}
            />
          )}
        </div>
        {/* End Vertrag */}

        <FormikTextareaStyling className="form-field notes">
          <FormikTextarea
            data-testid="notes"
            id="notes"
            label={labels.notes}
            name="notes"
            placeholder={labels.notes}
          />
        </FormikTextareaStyling>
      </section>
      <div className="lastRow">
        {isEditing ? (
          <ae-ui-card-content>
            <span className="label">
              Bearbeitet am: {formatDate(updated?.toISOString())}
            </span>
            <br />
            <span className="label">
              Erstellt am {formatDate(created?.toISOString())}
            </span>
          </ae-ui-card-content>
        ) : (
          <div />
        )}
        <Button
          disabled={isSubmitting || !isValid || isMeterValidating}
          onClick={submitForm}
        >
          {submitLabel}
        </Button>
      </div>
    </Form>
  );
}
