import * as React from 'react';
import {
  AlertRetryable,
  formatDateTime,
  PaddedShadowBox,
  PageTitleLayout,
  SpinnerDark,
} from '@ampeersenergy/ampeers-ui-components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  OperationResult,
  Status,
  useGetScheduleRequestsWorkflowQuery,
} from '../../graphql-types';
import { DocTitle } from '../docTitle';

import {
  getIconColor,
  getJobIcon,
  translateJobName,
} from './taskManagerHelper';
import { ContractDocuments } from './taskdetails/ContractDocuments';
import { ContractImportResults } from './taskdetails/ContractImportResults';
import { useTaskManager } from './TaskManagerProvider';

const Content = styled(PaddedShadowBox)`
  padding: 32px;
`;

const JobTitle = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const JobMeta = styled.dl`
  margin: 0 0 0 34px;
  color: ${(props) => props.theme.palette.textMuted};

  div {
    display: flex;
    gap: 4px;
  }

  dt {
    font-weight: bold;
    margin: 0;
    &:after {
      content: ':';
    }
  }
  dd {
    margin: 0;
  }
`;

const POLLING_INTERVAL = 1500;

export function TaskDetail() {
  const isPolling = React.useRef(false);
  const { id } = useParams<{
    id: string;
    dataSource: string;
  }>();

  const { removeJob } = useTaskManager();

  const { data, loading, error, startPolling, stopPolling } =
    useGetScheduleRequestsWorkflowQuery({
      variables: { workflowId: id ?? '' },
      skip: !id,
    });

  const job = React.useMemo(() => {
    if (
      data?.getScheduleRequestsWorkflow?.__typename === 'ScheduleRequestSuccess'
    ) {
      return data?.getScheduleRequestsWorkflow.result;
    }
  }, [data]);

  React.useEffect(() => {
    if (job?.status === Status.InProgress || job?.status === Status.Active) {
      startPolling(POLLING_INTERVAL);
      isPolling.current = true;
    } else if (isPolling.current) {
      stopPolling();
    }
  }, [startPolling, stopPolling, job, removeJob, id]);

  const jobTitle = React.useMemo(() => {
    if (!loading && job) {
      return `${job.jobLabel ?? translateJobName(job.jobName)}`;
    }
    return '';
  }, [job, loading]);

  const errorHandler = React.useCallback(() => {
    if (error) {
      return <AlertRetryable error={error} />;
    }
    if (data?.getScheduleRequestsWorkflow?.__typename === 'JobNotFound') {
      return <AlertRetryable message="Job nicht gefunden" />;
    }
    if (
      data?.getScheduleRequestsWorkflow?.__typename === 'ScheduleRequestsError'
    ) {
      return <AlertRetryable message="Beim Laden ist ein Fehler aufgetreten" />;
    }
    return null;
  }, [data, error]);

  const hasOperationErrors = job?.result?.some(
    (res) => res.data.__typename === 'OperationError',
  );

  const Icon = getJobIcon(job?.status, hasOperationErrors);
  const iconColor = getIconColor(job?.status, hasOperationErrors);

  return (
    <>
      <DocTitle
        titleParts={[jobTitle, 'Taskmanager'].filter((title) => title)}
      />
      {/* @ts-expect-error old PageTitleLayout */}
      <PageTitleLayout levelsUpToParent={1} title="Taskmanager">
        {errorHandler()}
        {!loading && job ? (
          <Content>
            <JobTitle>
              {job.status === Status.InProgress ? (
                <SpinnerDark size={30} />
              ) : (
                <Icon size={30} color={iconColor} />
              )}
              {jobTitle} ({job.progress}/{job.total} verarbeitet)
            </JobTitle>
            <JobMeta>
              {job.startedOn ? (
                <div>
                  <dt>Gestartet am</dt>
                  <dd>{formatDateTime(job.startedOn)}</dd>
                </div>
              ) : null}
              {(job.status === Status.Completed ||
                job.status === Status.CompletedWithErrors) &&
              job.finishedOn ? (
                <div>
                  <dt>Beendet am</dt>
                  <dd>{formatDateTime(job.finishedOn)}</dd>
                </div>
              ) : null}
            </JobMeta>
            {job.status === Status.Completed ||
            job.status === Status.Failed ||
            job.status === Status.CompletedWithErrors ? (
              <TaskResults jobName={job.jobName} results={job.result} />
            ) : null}
          </Content>
        ) : null}
      </PageTitleLayout>
    </>
  );
}

interface TaskResultsProps {
  jobName: string;
  results: OperationResult[];
  // TODO: ask Max about byLabel, cf. https://ampeers.atlassian.net/browse/LS-4227
}

function TaskResults({ jobName, results }: TaskResultsProps) {
  if (jobName === 'pricesheet_change_letter') {
    return <ContractDocuments results={results} />;
  }
  if (jobName === 'confirm_invoices') {
    return <ContractDocuments results={results} />;
  }
  if (jobName === 'contract_import' || jobName === 'contract_update_import') {
    return <ContractImportResults results={results} />;
  }

  return null;
}
