import {
  ButtonIcon,
  Icons,
  useDialog,
  useToasts,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useHasRole } from '../../../components/useHasRole';
import {
  AccountingRunWorkflowState,
  AccountingRunWorkflowStepName,
  OpcStepResult,
  OverviewStepResult,
  StepStatus,
  useDeleteAccountingRunWorkflowMutation,
  useReadInvoiceDraftsQueryQuery,
  useRestartAccountingRunWorkflowMutation,
} from '../../../graphql-types';

const ModalMessage = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const DeleteButtonIcon = styled(ButtonIcon)`
  &:hover svg {
    fill: ${(props) => props.theme.palette.error.color};
  }
`;

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function DetailsActions({
  workflow,
  workflowId,
}: {
  workflow: AccountingRunWorkflowState;
  workflowId: string;
}) {
  const { name } = workflow;

  const { hasRole: hasOperationsRole } = useHasRole('ae-operations');
  const [deleteWorkflowMutation] = useDeleteAccountingRunWorkflowMutation();
  const [restartAccountingRunMutation] =
    useRestartAccountingRunWorkflowMutation();
  const navigate = useNavigate();
  const toast = useToasts();
  const { openConfirmation } = useDialog();

  const sessionId = getSessionId(workflow);
  const opcStepStarted = hasOpcStepStarted(workflow);

  // We only want to enable deletion of a workflow if
  // - The workflow has been initialized, i.e. we have a sessionId
  // - The OPC step hasn't started yet. If it has, proper deletion necessitates
  //   clean up in odoo which isn't currently possible programmatically.
  const deletionAllowed = sessionId && !opcStepStarted;

  const opcName = getOpcName(workflow);

  const { data: invoiceDrafts, loading: invoiceDraftsLoading } =
    useReadInvoiceDraftsQueryQuery({
      variables: {
        filterByOPCName: opcName,
        showDocuments: false,
      },
      skip: !opcName || workflow.doneAt,
      // We don't want to overwrite the `showDocuments: true` query.
      // Alternatively we could handle this in caches `merge` function.
      fetchPolicy: 'no-cache',
    });

  const canRestartAccountingRun =
    !workflow.doneAt &&
    ((!invoiceDraftsLoading && !opcName) ||
      invoiceDrafts?.drafts.every((invoice) => !invoice.confirmed));

  const handleDeleteWorkflowModalClick = React.useCallback(async () => {
    try {
      const deleteWorkflowResponse = await deleteWorkflowMutation({
        variables: { workflowId },
      });

      if (deleteWorkflowResponse.data?.deleteAccountingRunWorkflow.success) {
        // If we immediately go back to the table, the response for the initial refetch still
        // includes the deleted workflow.
        await sleep(2000);
        toast.success(
          `Der Abrechnungslauf "${name}" wurde erfolgreich gelöscht`,
          {
            autoClose: 4000,
          },
        );
        navigate(`/accounting/runs`);
      } else {
        toast.error(
          `Der Abrechnungslauf "${name}" mit der ID ${workflowId} konnte nicht erfolgreich gelöscht werden.`,
        );
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      toast.error(
        `Es gab einen Fehler beim Versuch den Abrechnungslauf "${name}" mit der ID ${workflowId} zu löschen: ${message}`,
      );
    }
  }, [deleteWorkflowMutation, name, toast, workflowId]);

  const handleRestartAccountingRunWorkflow = React.useCallback(async () => {
    try {
      const restartAccountingRunWorkflowResponse =
        await restartAccountingRunMutation({
          variables: { workflowId },
        });

      if (
        restartAccountingRunWorkflowResponse.data?.restartAccountingRunWorkflow
          .success
      ) {
        window.location.reload();
        toast.success(
          `Der Abrechnungslauf "${name}" wurde erfolgreich neu gestartet`,
          {
            autoClose: 4000,
          },
        );
      } else {
        toast.error(
          `Der Abrechnungslauf "${name}" mit der ID ${workflowId} konnte nicht neugestartet werden.`,
        );
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      toast.error(
        `Es gab einen Fehler beim Versuch den Abrechnungslauf "${name}" mit der ID ${workflowId} neuzustarten: ${message}`,
      );
    }
  }, [name, restartAccountingRunMutation, toast, workflowId]);

  const deleteWorkflowModal = React.useCallback(async () => {
    const modalContent = (
      <ModalMessage>
        Bist Du sicher, dass Du die Abrechnung
        <br /> <b>&quot;{name}&quot;</b> <br />
        löschen möchtest?
        {opcName && hasOperationsRole && (
          <>
            <br />
            <br />
            🚨<b>Vorsicht</b>🚨
            <br /> Für den Workflow wurde bereits das OPC &quot;
            {opcName}
            &quot; erstellt.
            <br />
            Für eine vollständige Löschung muss das noch aus Odoo gelöscht
            werden.
          </>
        )}
      </ModalMessage>
    );

    await openConfirmation({
      confirmButtonLabel: 'Löschen',
      dangerButton: true,
      content: modalContent,
      title: name || '',
      maxWidth: 'md',
      handleRequest: handleDeleteWorkflowModalClick,
    });
  }, [
    handleDeleteWorkflowModalClick,
    hasOperationsRole,
    name,
    opcName,
    openConfirmation,
  ]);

  const restartAccountingRunClick = React.useCallback(async () => {
    const modalContent = (
      <ModalMessage>
        Bist Du sicher, dass Du die Abrechnung
        <br /> <b>&quot;{name}&quot;</b> <br />
        neustarten möchtest?
        {opcName && hasOperationsRole && (
          <>
            <br />
            <br />
            🚨<b>Vorsicht</b>🚨
            <br /> Für den Abrechnungslauf wurde bereits das OPC &quot;
            {opcName}
            &quot; erstellt.
            <br />
            Damit der Abrechnungslauf nach dem Neustart erfolgreich
            abgeschlossen werden kann, muss das OPC noch manuell aus Odoo
            gelöscht werden.
          </>
        )}
      </ModalMessage>
    );

    await openConfirmation({
      confirmButtonLabel: 'Neustarten',
      dangerButton: true,
      content: modalContent,
      title: name || '',
      maxWidth: 'md',
      handleRequest: handleRestartAccountingRunWorkflow,
    });
  }, [
    handleRestartAccountingRunWorkflow,
    hasOperationsRole,
    name,
    opcName,
    openConfirmation,
  ]);

  return (
    <>
      {(deletionAllowed || hasOperationsRole) && (
        <>
          {/* @ts-expect-error old ButtonIcon */}
          <DeleteButtonIcon
            icon={Icons.Delete}
            secondary
            onClick={deleteWorkflowModal}
            aria-label="Abrechnungslauf löschen"
          />
        </>
      )}
      {hasOperationsRole && canRestartAccountingRun ? (
        <>
          {/* @ts-expect-error old ButtonIcon */}
          <ButtonIcon
            icon={Icons.Refresh}
            secondary
            onClick={restartAccountingRunClick}
            aria-label="Abrechnungslauf neustarten"
          />
        </>
      ) : null}
    </>
  );
}

function getSessionId(workflow: AccountingRunWorkflowState) {
  const results = workflow.steps.map((s) => s.result);

  const overviewStepResult = results.find(
    (r): r is OverviewStepResult => r?.__typename === 'OverviewStepResult',
  );

  return overviewStepResult?.session;
}

function hasOpcStepStarted(workflow: AccountingRunWorkflowState) {
  const opcStepStatus = workflow.steps.find(
    (s) => s.name === AccountingRunWorkflowStepName.OpcStep,
  )?.status;

  return opcStepStatus !== StepStatus.Inactive;
}

function getOpcName(workflow: AccountingRunWorkflowState) {
  const results = workflow.steps.map((s) => s.result);

  const overviewStepResult = results.find(
    (r): r is OpcStepResult => r?.__typename === 'OpcStepResult',
  );

  return overviewStepResult?.name;
}
