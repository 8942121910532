import React from 'react';
import { Modal, DialogProvider } from '@ampeersenergy/ampeers-ui-components';
import { useNavigate } from 'react-router-dom';

import { AccountingType } from '../../../graphql-types';
import AccountingWorkflowFormInnerDashboard from '../dashboard/AccountingWorkflowFormInnerDashboard';
import {
  StandardAccountingInvoice,
  YearlyAccountingInvoice,
} from '../dashboard/dashboardAccountingTypes';

import CreateAccountingWorkflowForm from './createAccountingWorkflow.form';
import { CreateAccountingWorkflowFormProps } from './types';
import { CreateAccountingWorkflowFormInner } from './CreateAccountingWorkflowFormInner';

export type InnerFormType = 'default' | 'dashboard';

type CreateAccountingWorkflowModalProps = Pick<
  CreateAccountingWorkflowFormProps,
  'formVariables'
> & {
  onClose: () => void;
  innerFormType?: InnerFormType;
  invoicesIncluded?: (
    | YearlyAccountingInvoice[0]
    | StandardAccountingInvoice[0]
  )[];
  title?: string;
};

export function CreateAccountingWorkflowModal({
  onClose,
  formVariables,
  innerFormType = 'default',
  invoicesIncluded,
  title,
}: CreateAccountingWorkflowModalProps) {
  const navigate = useNavigate();
  const { accountingTypes = [AccountingType.StandardAccounting] } =
    formVariables;

  const mutation = 'createAccountingRunWorkflow';

  const onSuccess = (result: any) => {
    const workflow = result.createAccountingRunWorkflow;
    navigate(`/accounting/runs/${workflow?.id}`);
    onClose();
  };

  const readDocumentsFields = ['id'];

  return (
    <DialogProvider>
      <Modal
        isOpen
        onRequestClose={onClose}
        contentLabel={title || 'Abrechnung erstellen'}
        title={title || 'Abrechnung erstellen'}
        minWidth={650}
      >
        <CreateAccountingWorkflowForm
          mutation={mutation}
          readDocumentsFields={readDocumentsFields}
          formVariables={formVariables}
          onSuccess={onSuccess}
          onAbort={onClose}
          innerFormType={innerFormType}
          innerForm={
            innerFormType === 'dashboard' ? (
              <AccountingWorkflowFormInnerDashboard
                invoicesIncluded={invoicesIncluded}
              />
            ) : (
              <CreateAccountingWorkflowFormInner
                accountingTypes={accountingTypes}
              />
            )
          }
        />
      </Modal>
    </DialogProvider>
  );
}
