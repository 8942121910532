import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import * as yup from 'yup';

import {
  GraphqlFormField,
  GraphqlFormInputGroup,
  GraphqlFormSelect,
} from '../../../../../components/graphql-form/render';
import {
  Entry,
  FlexRow,
  Flex,
  Label,
  ErrorMsg,
} from '../../../../../components';
import {
  filterTariffSuggestions,
  sortTariffsSuggestions,
} from '../../../../../helpers/utils';
import {
  Metering,
  ReadContractDocument,
  ReadContractQuery,
  ReadMeterReadingsDocument,
  usePlantDetailQuery,
  useReadMeterReadingsQuery,
} from '../../../../../graphql-types';
import { formatEmgEndDates } from '../../../../../helpers/formatStrings';
import {
  METER_OBIS_CHANNEL_RLM_BEZUG,
  METER_OBIS_CHANNEL_SLP_BEZUG,
  METER_READING_END_DATE,
  METER_READING_START_DATE,
} from '../../../../../helpers/constants';
import GraphQlForm from '../../../../../components/graphql-form';
import { areDatesOnSameDay } from '../../../../../helpers/dateHelpers';
import { findMeterReadingMinMaxLimits } from '../../../../../helpers/validators';
import { yupUTCDate } from '../../../../../helpers/yupUTCDate';

import TariffChangeWarningMessage from './tariffChangeWarningMessage';
import { CurrentTariffType } from './replaceTariff';

const Spacer = styled.div`
  width: 25px;
`;

const Hint = styled.div`
  color: #a9a9a9;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 6px;
  display: block;
  margin-top: 15px;
`;

interface EditContractTariffsComponentProps {
  setDate: (value: string) => void;
  onSuccess?: (result: any, additional: { values: any }) => void;
  onAbort?: () => void;
  currentTariff?: CurrentTariffType;
  latestStartDate: string;
  contract: ReadContractQuery['readContract'];
  meterReadingDate: string;
}

function EditContractTariffsComponent({
  setDate,
  onSuccess,
  onAbort,
  currentTariff,
  latestStartDate,
  contract,
  meterReadingDate,
}: EditContractTariffsComponentProps) {
  const { id: contractId, plantId } = contract || {};

  const {
    data: plantData,
    error,
    loading: isPlantLoading,
  } = usePlantDetailQuery({
    variables: {
      plantId: String(plantId),
    },
    skip: plantId === undefined,
  });

  const sortedMeters = React.useMemo(() => {
    if (!contract?.contractMeter?.meters) return [];

    return contract?.contractMeter?.meters
      .slice(0)
      .sort(
        (meterA: any, meterB: any) =>
          new Date(meterB.validityStart!).getTime() -
          new Date(meterA.validityStart!).getTime(),
      );
  }, [contract]);

  const { data: dataMeterReading, loading: loadingReadMeterReading } =
    useReadMeterReadingsQuery({
      variables: {
        meterId: sortedMeters[0]?.id,
        startDate: METER_READING_START_DATE,
        endDate: METER_READING_END_DATE,
        metering:
          sortedMeters[0]?.metering === Metering.Rlm
            ? Metering.Rlm
            : Metering.Slp,
      },
      skip: sortedMeters.length === 0,
    });

  if (error) {
    return <ErrorMsg error={error} />;
  }

  return (
    <GraphQlForm
      mutation="updateContractComponentTariff"
      variables={{
        contractId,
        meterId: sortedMeters[0]?.id,
        meterReadingDate: new Date(meterReadingDate),
      }}
      defaultValues={{
        tariff: {
          id: currentTariff?.tariffId,
        },
      }}
      isLoading={isPlantLoading || loadingReadMeterReading}
      startInEdit
      formVariables={{
        balancingAreaAccountId: plantData?.readPlant?.balancingAreaAccountId,
        plantId,
      }}
      onSuccess={onSuccess}
      onAbort={onAbort}
      validation={{
        tariffAssignmentValidityStartDate: yupUTCDate
          .test(
            'meterReadingAlreadyExists',
            'Für das angegebene Datum existiert bereits ein Zählerstand.',
            (value?: Date) => {
              if (!value) {
                return true;
              }

              return !dataMeterReading?.readMeterReadings
                .map(({ from }) => DateTime.fromISO(from).toJSDate())
                .some((meteringDate) => areDatesOnSameDay(meteringDate, value));
            },
          )
          .test(
            'latestStartDate',
            'Darf nicht vor Beginn der letzten Gültigkeit liegen',
            (value?: Date) => {
              if (latestStartDate === undefined || !value) {
                return true;
              }
              return value.getTime() > new Date(latestStartDate).getTime();
            },
          )
          .test(
            'contractEndDate',
            'Darf nicht nach Ende der Gültigkeit vom Vertrag liegen',
            (value?: Date) => {
              if (contract?.endDate === undefined || !value) {
                return true;
              }
              return value.getTime() < new Date(contract?.endDate).getTime();
            },
          )
          .required(),
        meterReading: {
          value: yup.lazy(() => {
            if (meterReadingDate === undefined) yup.number().required();

            const formattedDateValue = dataMeterReading?.readMeterReadings
              ? dataMeterReading?.readMeterReadings.map(({ from, value }) => ({
                  date: from,
                  value: String(value),
                }))
              : [];
            const { min, max } = findMeterReadingMinMaxLimits(
              formattedDateValue,
              new Date(meterReadingDate),
            );
            const firstDate = DateTime.fromISO(
              formattedDateValue[0]?.date,
            ).toISODate();
            const schema = yup.number().required();

            if (max === 0) {
              return schema.test(
                'maxZero',
                `Muss größer 0 sein und nach ${firstDate} liegen`,
                () => false,
              );
            }
            if (min === max) {
              return schema.test(
                'minEqualMax',
                `Muss gleich ${min} sein`,
                (value: number | undefined) => value === min,
              );
            }
            if (max) {
              return schema
                .min(min, `Muss zwischen ${min} und ${max} liegen`)
                .max(max, `Muss zwischen ${min} und ${max} liegen`);
            }
            return schema.min(min, `Muss größer oder gleich ${min} sein`);
          }),
          reason: yup.string().required(),
          valueStatus: yup.string().required(),
        },
      }}
      refetchQueries={[
        {
          query: ReadContractDocument,
          variables: {
            contractId,
            contractLabel: contract?.label,
            customerId: contract?.customer?.id,
            customerLabel: contract?.customer?.label,
          },
        },
        {
          query: ReadMeterReadingsDocument,
          variables: {
            meterId: sortedMeters[0]?.id,
            startDate: METER_READING_START_DATE,
            endDate: METER_READING_END_DATE,
            metering:
              sortedMeters[0]?.metering === Metering.Rlm
                ? Metering.Rlm
                : Metering.Slp,
            obisChannel:
              sortedMeters[0]?.metering !== Metering.Rlm
                ? METER_OBIS_CHANNEL_SLP_BEZUG
                : METER_OBIS_CHANNEL_RLM_BEZUG,
          },
          skip: sortedMeters.length === 0,
        },
      ]}
    >
      <EditContractTariffsInnerForm contract={contract} setDate={setDate} />
    </GraphQlForm>
  );
}

const EditContractTariffsInnerForm = ({
  contract,
  setDate,
}: {
  contract: ReadContractQuery['readContract'];
  setDate: (value: string) => void;
}) => {
  const { values } = useFormikContext();
  const sortTariff = useCallback(sortTariffsSuggestions, []);

  useEffect(() => {
    const date = get(values, 'tariffAssignmentValidityStartDate');
    setDate(date ?? '');
  }, [values, setDate]);

  const assignmentValidityStartDate = React.useMemo(
    () =>
      DateTime.fromISO(get(values, 'tariffAssignmentValidityStartDate') ?? '', {
        zone: 'utc',
      }),
    [values],
  );

  return (
    <>
      <TariffChangeWarningMessage />
      <FlexRow>
        <Flex>
          <GraphqlFormField name="tariffAssignmentValidityStartDate" />
        </Flex>
        <Spacer />
        <Flex>
          <Entry title="Ende Gültigkeit">
            {formatEmgEndDates(contract?.endDate)}
          </Entry>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField
            name="tariff.id"
            sort={sortTariff}
            placeholder="Tarif - Preisblatt"
            filter={filterTariffSuggestions(assignmentValidityStartDate)}
          />
        </Flex>
      </FlexRow>
      <Hint>
        Für die korrekte Abgrenzung eines neuen Tarifs muss ein Zählerstand
        hinterlegt werden.
      </Hint>
      <FlexRow>
        <Flex>
          <GraphqlFormField name="meterReading.value" />
        </Flex>
        <Spacer />
        <Flex>
          <Label>Ablesegrund</Label>
          <GraphqlFormInputGroup>
            <GraphqlFormSelect
              name="meterReading.reason"
              data-testid="meterReading.reason"
            >
              <option disabled hidden value="" key="">
                Bitte wählen
              </option>
              <option value="PMR">Turnusablesung</option>
              <option value="COT">Zwischenablesung</option>
            </GraphqlFormSelect>
          </GraphqlFormInputGroup>
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField name="meterReading.valueStatus" />
        </Flex>
        <Spacer />
        <Flex />
      </FlexRow>
    </>
  );
};

export default EditContractTariffsComponent;
